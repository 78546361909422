import call from "../../service/http";
import { SetupConstants as constant } from "./index";

export default {
  namespaced: true,
  state: {
    membershipCategories: [],
    companyInformation: {},
    countries: [],
    cities: [],
    counties: [],
    qualificationCodes: [],
    certificationCodes: [],
    industryGroups: [],
    specializationTypes: [],
    postalcodes: [],
    universities: [],
    divisions: [],
    requirements: [],
    disciplines: [],
    titles: [],
    error: "",
    attachmentTypes: [],
    disclaimers: [],
    firms: [],
    refereeTypes: [],
  },
  mutations: {
    SET_MEMBERSHIP_CATEGORIES: (state, payload) => {
      state.membershipCategories = payload;
    },
    SET_REQUIREMENTS: (state, payload) => {
      state.requirements = payload;
    },
    SET_COMPANY_INFORMATION: (state, payload) => {
      state.companyInformation = payload;
    },
    SET_COUNTRIES: (state, payload) => {
      state.countries = payload;
    },
    SET_CITIES: (state, payload) => {
      state.cities = payload;
    },
    SET_POSTAL_CODES: (state, payload) => {
      state.postalcodes = payload;
    },
    SET_COUNTIES: (state, payload) => {
      state.counties = payload;
    },
    SET_QUALIFICATION_CODES: (state, payload) => {
      state.qualificationCodes = payload;
    },
    SET_CERTIFICATION_CODES: (state, payload) => {
      state.certificationCodes = payload;
    },
    SET_INDUSTRY_GROUP: (state, payload) => {
      state.industryGroups = payload;
    },
    SET_SPECIALIZATION_TYPES: (state, payload) => {
      state.specializationTypes = payload;
    },
    SET_DIVISIONS: (state, payload) => {
      state.divisions = payload;
    },
    SET_TITLES: (state, payload) => {
      state.titles = payload;
    },
    SET_UNIVERSITIES: (state, payload) => {
      state.universities = payload;
    },
    SET_DISCIPLINES: (state, payload) => {
      state.disciplines = payload;
    },
    SET_ERROR: (state, payload) => {
      state.error = payload;
    },
    SET_ATTACHMENT_TYPES: (state, payload) => {
      state.attachmentTypes = payload;
    },
    SET_DISCLAIMERS: (state, payload) => {
      state.disclaimers = payload;
    },
    SET_FIRMS: (state, payload) => {
      state.firms = payload;
    },
    SET_REFEREE_TYPES: (state, payload) => {
      state.refereeTypes = payload;
    },
  },
  getters: {
    membershipCategories: (state) => state.membershipCategories,
    requirements: (state) => state.requirements,
    companyInformation: (state) => state.companyInformation,
    countries: (state) => state.countries,
    cities: (state) => state.cities,
    counties: (state) => state.counties,
    qualificationCodes: (state) => state.qualificationCodes,
    certificationCodes: (state) => state.certificationCodes,
    postalCodes: (state) => state.postalcodes,
    industryGroups: (state) => state.industryGroups,
    specializationTypes: (state) => state.specializationTypes,
    divisions: (state) => state.divisions,
    disciplines: (state) => state.disciplines,
    universities: (state) => state.universities,
    titles: (state) => state.titles,
    error: (state) => state.error,
    attachmentTypes: (state) => state.attachmentTypes,
    disclaimers: (state) => state.disclaimers,
    firms: (state) => state.firms,
    refereeTypes: (state) => state.refereeTypes,
  },
  actions: {
    getMembershipCategories: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.membershipCategories).then((res) => {
        commit("SET_MEMBERSHIP_CATEGORIES", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getMembershipRequirements: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.requirements).then((res) => {
        commit("SET_REQUIREMENTS", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getCompanyInformation: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.companyInformation).then((res) => {
        commit("SET_COMPANY_INFORMATION", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getDivisions: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.division).then((res) => {
        commit("SET_DIVISIONS", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getCountries: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.countries).then((res) => {
        commit("SET_COUNTRIES", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getCities: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.cities).then((res) => {
        commit("SET_CITIES", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getPostalcodes: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.postcodes).then((res) => {
        commit("SET_POSTAL_CODES", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getQualificationCodes: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.qualificationCodes).then((res) => {
        commit("SET_QUALIFICATION_CODES", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getCertificationCodes: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.certificationCode).then((res) => {
        commit("SET_CERTIFICATION_CODES", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getCounties: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.counties).then((res) => {
        commit("SET_COUNTIES", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getIndustryGroups: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.industryGroups).then((res) => {
        commit("SET_INDUSTRY_GROUP", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getDisciplines: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.discipline).then((res) => {
        commit("SET_DISCIPLINES", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getTitles: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.title).then((res) => {
        commit("SET_TITLES", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getUniversities: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.universities).then((res) => {
        commit("SET_UNIVERSITIES", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getSpecializationTypes: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.specializationTypes).then((res) => {
        commit("SET_SPECIALIZATION_TYPES", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getattachmentTypes: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.attachmentTypes).then((res) => {
        commit("SET_ATTACHMENT_TYPES", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getDisclaimers: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.DISCLAIMERS).then((res) => {
        commit("SET_DISCLAIMERS", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getFirms({ commit }) {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.FIRMS).then((res) => {
        commit("SET_FIRMS", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getRefereeTypes({ commit }) {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.REFEREE_TYPES).then((res) => {
        commit("SET_REFEREE_TYPES", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },
  },
};

import DashboardLayout from "../../layouts/dashboard/DashboardLayout";
import LicenseList from "./components/LicenseList";
import LicenseCard from "./components/LicenseCard";
import LicenseDocument from "./components/LicenseDocument";
import LicenseInvoice from "./components/LicenseInvoice";
import License from "./views/License";
import Auth from "../../router/middleware/Auth";

export default [
  {
    path: "/license",
    component: DashboardLayout,
    children: [
      {
        path: "",
        components: { view: License },
        children: [
          {
            path: "",
            name: "licenseList",
            components: { view: LicenseList },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
          {
            path: "card/:code?",
            name: "licenseCard",
            components: { view: LicenseCard },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
          {
            path: "document/:code",
            name: "licenseDocument",
            components: { view: LicenseDocument },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
          {
            path: "license/:code",
            name: "LicenseInvoice",
            components: { view: LicenseInvoice },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
        ],
      },
    ],
  },
];

import call from "../../../../service/http";
import AttachmentConstant from "./AttachmentConstant";

export default {
  state: {
    attachments: [],
  },
  mutations: {
    SET_ATTACHMENTS: (state, payload) => {
      state.attachments = payload;
    },
  },
  getters: {
    attachments: (state) => state.attachments,
  },
  actions: {
    getAttachment: ({ commit }) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", AttachmentConstant.attachment)
        .then((res) => {
          commit("SET_ATTACHMENTS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    addAttachment: ({ commit, dispatch }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", AttachmentConstant.attachment, data)
        .then(() => {
          dispatch("getAttachment");
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    updateAttachment: ({ commit, dispatch }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", AttachmentConstant.update, data)
        .then(() => {
          dispatch("getAttachment");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    removeAttachment: ({ commit, dispatch }, payload) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", AttachmentConstant.remove, payload)
        .then(() => {
          dispatch("getAttachment");
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};

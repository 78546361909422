<template>
  <v-container class="mt-5">
    <alert />
    <v-data-table
      :headers="headers"
      :items="attachments"
      dense
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-h6">My Attachments</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog persistent v-model="dialog" max-width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Additional Attachments
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary">
                <span class="text-h5">
                  {{ edit ? "Edit" : "Add" }} Attachment
                </span>
              </v-card-title>

              <v-card-text class="mt-10">
                <v-container>
                  <v-row dense>
                    <v-col cols="12">
                      <v-autocomplete
                        dense
                        outlined
                        label="Attachment Type"
                        item-value="code"
                        item-text="code"
                        v-model="formData.description"
                        :rules="rules.description"
                        ref="description"
                        :items="attachmentTypes"
                      >
                        <template v-slot:label>
                          <div>
                            Attachment Description
                            <span class="red--text">*</span>
                          </div>
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="12">
                      <v-file-input
                        outlined
                        accept="application/pdf"
                        persistent-hint
                        dense
                        :hint="hint"
                        ref="attachment"
                        type="file"
                        v-model="formData.attachment"
                        :rules="rules.attachment"
                        :append-outer-icon="
                          formData.attached ? 'mdi-download' : ''
                        "
                        @click:append-outer="
                          downloadFile(formData.attached, formData.fileName)
                        "
                      >
                        <template v-slot:label>
                          <div>
                            Attachment
                            <span class="red--text">*</span>
                          </div>
                        </template>
                      </v-file-input>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="formData.fileName"
                        :rules="rules.fileName"
                        ref="fileName"
                      >
                        <template v-slot:label>
                          <div>
                            File Name
                            <span class="red--text">*</span>
                          </div>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="clearForm"> Cancel </v-btn>
                <v-btn color="primary" @click="save"> Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.attachment="{ item }">
        <v-btn
          small
          outlined
          color="secondary"
          @click="downloadFile(item.attachment, item.fileName)"
        >
          <v-icon left color="secondary">mdi-file </v-icon>
          Download
        </v-btn>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" color="blue" @click="update(item)">
          mdi-pencil
        </v-icon>
        <v-icon color="red" @click="remove(item)"> mdi-delete</v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import AuthService from "../../../auth/AuthService";
import { helper } from "../../../../utils";
import Alert from "../../../../components/Alert";
import FileMixin from "../../../../mixins/FileMixin";
import SetupMixin from "../../../setup/SetupMixin";

export default {
  name: "Attachments",
  mixins: [FileMixin, SetupMixin],
  components: { Alert },
  data: () => ({
    dialog: false,
    edit: false,
    current: false,
    options: [
      { value: "Educational", label: "Academinc" },
      { value: "Professional", label: "Others" },
    ],
    formData: {
      attachment: undefined,
      description: "",
      attached: "",
      fileName: "",
      attachmentType: "",
    },
    headers: [
      {
        text: "Description",
        align: "start",
        sortable: false,
        value: "description",
      },
      { text: "Attachment", value: "attachment", sortable: false },
      { text: "File Name", value: "fileName", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  mounted() {
    this.$store.dispatch("Profile/getAttachment");
    this.getSetups(["getattachmentTypes"]);
  },

  computed: {
    rules() {
      return {
        description: [(v) => !!v || "Description is required"],
        fileName: [(v) => !!v || "File Name is required"],
        attachment: [
          (v) => {
            return this.formData.attached === ""
              ? !!v || "Attachment is required"
              : true;
          },
          (v) =>
            this.formData.attached === "" && !!v
              ? v.size < 10000000 || "Attachment should be less than 10MB"
              : true,
          (v) => {
            return this.formData.attached === "" && !!v
              ? v.type.includes("application/pdf") || "Attachment must be a Pdf"
              : true;
          },
        ],
      };
    },
    attachments() {
      return this.$store.getters["Profile/attachments"];
    },
    attachmentTypes() {
      return this.$store.getters["Setup/attachmentTypes"];
    },
    minorSettings() {
      const settings = this.$store.getters["Admin/adminGetters"]("settings");
      return settings.minor_settings
        ? JSON.parse(settings.minor_settings)
        : false;
    },
    hint() {
      const s1 = "pdf documents only.";
      const s2 =
        " For any assistance kindly contact ebk administrator through our email";
      return this.minorSettings &&
        this.minorSettings.organisation_caption === "EBK"
        ? s1 + s2
        : s1;
    },
  },

  methods: {
    clearForm: function () {
      this.formData = {
        attachment: "",
        description: "",
        attached: "",
        attachmentType: "",
      };

      this.dialog = false;
      this.edit = false;
    },

    update: function (payload) {
      let data = { ...payload };
      data.attached = data.attachment;
      data.attachment = {};

      this.formData = data;
      this.edit = true;
      this.dialog = true;
    },

    remove(item) {
      this.$confirm.show({
        title: "Delete this Attachment?",
        text: `Are you sure you want to delete?`,
        onConfirm: () => {
          this.$store.dispatch("Profile/removeAttachment", { ...item });
        },
      });
    },

    save: function () {
      let isValid = true;
      for (const key in this.formData) {
        if (this.$refs[key])
          isValid = isValid ? this.$refs[key].validate(true) : false;
      }

      if (isValid) {
        let data = { ...this.formData };
        data.profileID = AuthService.user.profile.ProfileID;

        if (this.edit) {
          data.lineNo = parseInt(data.lineNo);
        }

        this.$store.dispatch(
          `Profile/${this.edit ? "updateAttachment" : "addAttachment"}`,
          helper.prepareFormData(data)
        );

        this.clearForm();
      }
    },
  },

  watch: {
    "formData.attachment": {
      handler: function () {
        this.formData.fileName = this.formData.attachment.name;
        this.formData.attachmentType = this.formData.description;
      },
    },
  },
};
</script>

<style scoped></style>

import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height d-flex flex-row align-center",staticStyle:{"background-color":"rgba(255, 255, 255, 0.95)"},attrs:{"fluid":""}},[_c(VRow,[(_vm.profile.ProfileID)?_c(VCol,{attrs:{"md":"8","offset-md":"2","sm":"12"}},[_c(VCard,{staticClass:"pa-5",attrs:{"elevation":"0"}},[_c(VRow,[_c(VCol,{attrs:{"md":"4","sm":"12"}},[_c(VCard,{attrs:{"elevation":"0"}},[(_vm.company === 'EBK')?_c(VImg,{staticClass:"rounded",attrs:{"src":'/img/avatar.jpg',"height":"200px","contain":""}}):_c(VImg,{staticClass:"rounded",attrs:{"src":_vm.profile.base64Image
                    ? ("data:image/png;base64," + (_vm.profile.base64Image))
                    : '/img/avatar.jpg',"height":"200px","contain":""}}),_c(VCardSubtitle,{staticClass:"text-uppercase text-center"},[_vm._v(" "+_vm._s(((_vm.getProfileTitle(_vm.profile.profileTitle)) + " " + (_vm.profile.FirstName) + " " + (_vm.profile.SecondName) + " " + (_vm.profile.LastName) + " "))+" ")])],1)],1),_c(VCol,{attrs:{"md":"8","sm":"12"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Membership Details")]),_c(VCardText,[_c(VSimpleTable,{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.profile.memberInformation)?_c('tbody',[(_vm.profile.memberInformation.length > 0)?_c('tr',[_c('td',[_vm._v("Membership Status")]),_c('td',[_c(VChip,{attrs:{"small":"","color":_vm.profile.memberInformation[0].Status === 'Active'
                              ? 'green'
                              : 'deep-orange'}},[_vm._v(" "+_vm._s(_vm.profile.memberInformation[0].Status)+" ")])],1)]):_vm._e(),(_vm.profile.memberInformation.length > 0)?_c('tr',[_c('td',[_vm._v("Member No.")]),_c('td',[_vm._v(_vm._s(_vm.profile.memberInformation[0].No))])]):_vm._e(),_c('tr',[_c('td',[_vm._v("Member Category")]),_c('td',[_vm._v(_vm._s(_vm.profile.MemberShipType))])]),_c('tr',[_c('td',[_vm._v("Discipline")]),_c('td',[_vm._v(_vm._s(_vm.profile.discipline))])]),(
                        _vm.profile.contactsEntity.length > 0 &&
                        _vm.minorSettings.showWorkPlace
                      )?_c('tr',[_c('td',[_vm._v("WorkPlace")]),_c('td',[_vm._v(_vm._s(_vm.getCurrentWorkPlace()))])]):_vm._e(),(_vm.profile.contactsEntity.length > 0)?_c('tr',[_c('td',[_vm._v("Address")]),_c('td',[_vm._v(_vm._s(_vm.profile.contactsEntity[0].Address))])]):_vm._e(),_c('tr',[_c('td',[_vm._v("Gender")]),_c('td',[_vm._v(_vm._s(_vm.profile.Gender))])])]):_vm._e()]},proxy:true}],null,false,4277933172)})],1)],1),_c(VCard,{staticClass:"mt-5"},[_c(VCardTitle,[_vm._v("Specialization")]),_vm._l((_vm.profile.specialization),function(spec,i){return _c(VChip,{key:i,staticClass:"ma-2",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(spec.Description)+" ")])})],2)],1)],1)],1)],1):_c(VCol,{attrs:{"md":"8","offset-md":"2","sm":"12"}},[_c(VSkeletonLoader,{attrs:{"type":"table-heading, list-item-two-line, image, table-tfoot"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <Firmbio :initial="true" />
</template>

<script>
import Firmbio from "../../profile/components/firmbio/Firmbio.vue";
export default {
  name: "InitiateFirm",
  components: { Firmbio },
};
</script>

<style scoped></style>

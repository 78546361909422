import call from "../../../../service/http";
import SpecializationConstants from "./SpecializationConstants";

export default {
  state: {
    specializations: [],
  },
  mutations: {
    SET_SPECIALIZATION: (state, payload) => {
      state.specializations = payload;
    },
  },
  getters: {
    specializations: (state) => state.specializations,
  },
  actions: {
    getSpecializations: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("get", SpecializationConstants.specialization)
        .then((res) => {
          commit("SET_SPECIALIZATION", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    submitSpecializations: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", SpecializationConstants.specialization, data)
        .then(() => {
          dispatch("getSpecializations");
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    patchSpecialization: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", SpecializationConstants.updateSpecialization, data)
        .then(() => {
          dispatch("getSpecializations");
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};

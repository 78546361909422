import call from "../../../../service/http";
import CertificationConstants from "./CertificationConstants";

export default {
  state: {
    certifications: [],
  },
  mutations: {
    SET_CERTIFICATIONS: (state, payload) => {
      state.certifications = payload;
    },
  },
  getters: {
    certifications: (state) => state.certifications,
  },
  actions: {
    getCertifications: ({ commit }) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", CertificationConstants.certification)
        .then((res) => {
          commit("SET_CERTIFICATIONS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    addCertification: ({ commit, dispatch }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", CertificationConstants.certification, data)
        .then(() => {
          /* re-fetch certifications */
          dispatch("getCertifications");
          commit("SET_LOADING", false, { root: true });
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    removeCertification: ({ commit, dispatch }, payload) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", CertificationConstants.remove, payload)
        .then(() => {
          dispatch("getCertifications");
          commit("SET_LOADING", false, { root: true });
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    updateCertification: ({ dispatch, commit }, payload) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", CertificationConstants.update, payload)
        .then(() => {
          dispatch("getCertifications");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};

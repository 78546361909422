import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height d-flex flex-row align-center",staticStyle:{"background-color":"rgba(255, 255, 255, 0.65)"},attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"md":"6","sm":"12","offset-md":"3"}},[_c(VSheet,{staticClass:"pa-10 text-center mt-5",attrs:{"min-height":"40vh","rounded":"lg"}},[(_vm.$store.getters['Auth/alert'].status)?_c(VAlert,{staticClass:"mb-10",attrs:{"outlined":"","dense":"","border":"left","type":_vm.$store.getters['Auth/alert'].status === 'success'
              ? 'success'
              : 'error'}},[_vm._v(" "+_vm._s(_vm.$store.getters["Auth/alert"].message)+" ")]):_vm._e(),_c(VIcon,{staticClass:"text-h1",attrs:{"color":_vm.$store.getters['Auth/verified'] === 'success'
              ? 'secondary'
              : 'warning'}},[_vm._v(" "+_vm._s(_vm.$store.getters["Auth/verified"] === "success" ? "mdi-check-circle" : "mdi-alert-circle")+" ")]),_c('p',{staticClass:"subtitle-1 my-6"},[_vm._v(" "+_vm._s(_vm.$store.getters["Auth/verified"] === "success" ? "Account activated Successfully" : _vm.$store.getters["Auth/verified"] === "pending" ? "Activating you account" : "Failed to activate account. Please contact administrator")+" ")]),_c(VForm,[(_vm.$store.getters['Auth/verified'] === 'success')?_c(VBtn,{attrs:{"color":"secondary","tile":"","large":"","to":{ name: 'login' }}},[_vm._v(" LOGIN "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container class="mt-5">
    <alert />
    <v-data-table
      :headers="headers"
      :items="convictions"
      dense
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-h6">My Convictions</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog persistent v-model="dialog" max-width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Add Conviction
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="orange lighten-5">
                <span class="text-h5">
                  {{ edit ? "Edit" : "Add" }} Convictions
                </span>
              </v-card-title>

              <v-card-text class="mt-10">
                <v-container>
                  <v-row dense>
                    <v-col cols="12">
                      <!-- date picker -->
                      <v-menu
                        ref="menu"
                        v-model="ConvictionDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            outlined
                            type="text"
                            label="Conviction Date"
                            v-model="formData.ConvictionDate"
                            :rules="rules.ConvictionDate"
                            ref="ConvictionDate"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="formData.ConvictionDate"
                          no-title
                          scrollable
                          :max="today"
                          @input="ConvictionDateMenu = false"
                        />
                      </v-menu>
                    </v-col>

                    <v-col cols="12">
                      <v-textarea
                        dense
                        outlined
                        label="Offence"
                        v-model="formData.Offence"
                        :rules="rules.Offence"
                        ref="Offence"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-textarea
                        dense
                        outlined
                        label="Sentence Imposed"
                        v-model="formData.SentenceImposed"
                        :rules="rules.SentenceImposed"
                        ref="SentenceImposed"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="clearForm"> Cancel </v-btn>
                <v-btn color="primary" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" color="blue" @click="update(item)">
          mdi-pencil
        </v-icon>
        <v-icon small color="red" @click="remove(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { AuthService } from "../../../auth";
import { helper } from "../../../../utils";
import Alert from "../../../../components/Alert";

export default {
  name: "Conviction",
  components: { Alert },
  data: () => ({
    dialog: false,
    edit: false,
    ConvictionDateMenu: false,
    today: new Date().toISOString().substr(0, 10),
    formData: {
      ConvictionDate: "",
      Offence: "",
      SentenceImposed: "",
    },
    headers: [
      {
        text: "ConvictionDate",
        align: "start",
        sortable: false,
        value: "ConvictionDate",
      },
      { text: "Offence", value: "Offence" },
      { text: "SentenceImposed", value: "SentenceImposed" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  mounted() {
    this.$store.dispatch("Profile/getConvictions");
  },

  computed: {
    rules() {
      return {
        ConvictionDate: [(v) => !!v || "Conviction Date is required"],
        Offence: [(v) => !!v || "Offence is required"],
        SentenceImposed: [(v) => !!v || "Sentence Imposed No is required"],
      };
    },

    convictions() {
      return this.$store.getters["Profile/convictions"];
    },
  },

  methods: {
    clearForm: function () {
      this.formData = { ConvictionDate: "", Offence: "", SentenceImposed: "" };

      this.dialog = false;
      this.edit = false;
    },

    update: function (payload) {
      this.formData = { ...payload };
      this.edit = true;
      this.dialog = true;
    },

    remove(item) {
      this.$confirm.show({
        title: "Delete this conviction?",
        text: `Are you sure you want to delete?`,
        onConfirm: () => {
          this.$store.dispatch("Profile/removeConviction", { ...item });
        },
      });
    },

    save: function () {
      let isValid = true;
      for (const key in this.formData) {
        if (this.$refs[key])
          isValid = isValid ? this.$refs[key].validate(true) : false;
      }

      if (isValid) {
        let data = { ...this.formData };
        data.ProfileID = AuthService.user.profile.ProfileID;

        if (this.edit) {
          data.lineNo = parseInt(data.lineNo);
          data.EntryNo = this.formData.EntryNo
            ? parseInt(this.formData.EntryNo)
            : parseInt(this.convictions.length + 1);
        }

        this.$store.dispatch(
          `Profile/${this.edit ? "updateConviction" : "addConviction"}`,
          helper.prepareFormData(data)
        );

        this.clearForm();
      }
    },
  },
};
</script>

<style scoped></style>

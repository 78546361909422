<template>
  <v-container class="mt-5">
    <alert />
    <v-data-table
      :headers="headers"
      :items="facilities"
      dense
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-h6">Facilities</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog persistent v-model="dialog" max-width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Add Facility
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="orange lighten-5">
                <span class="text-h5">
                  {{ edit ? "Edit" : "Add" }} Facility
                </span>
              </v-card-title>

              <v-card-text class="mt-10">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                      <v-text-field
                        outlined
                        placeholder="Add Equipment Name"
                        label="Equipment Name"
                        v-model="formData.nameOfEquipment"
                        dense
                        :rules="rules.nameOfEquipment"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                      <v-text-field
                        outlined
                        placeholder="Add Quantity"
                        label="Quantity"
                        v-model="formData.quantity"
                        dense
                        :rules="rules.quantity"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                      <v-file-input
                        outlined
                        dense
                        ref="File"
                        accept="application/pdf"
                        hint="pdf documents only"
                        persistent-hint
                        type="file"
                        v-model="formData.File"
                        :rules="rules.File"
                        :append-outer-icon="
                          formData.ownership ? 'mdi-download' : ''
                        "
                        @click:append-outer="downloadFile(formData.Cert)"
                      >
                        <template v-slot:label>
                          <div>
                            Attach Ownership (Produce Evidence)
                            <span class="red--text">*</span>
                          </div>
                        </template>
                      </v-file-input>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                      <v-textarea
                        outlined
                        rows="3"
                        placeholder="Add remarks"
                        label="remarks"
                        v-model="formData.remarks"
                        dense
                        :rules="rules.remarks"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="clearForm"> Cancel </v-btn>
                <v-btn color="primary" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.ownership="{ item }">
        <v-btn
          small
          color="secondary"
          outlined
          @click="downloadFile(item.ownership, item.nameOfEquipment)"
        >
          <v-icon left>mdi-file</v-icon>
          Download
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" color="blue" @click="update(item)">
          mdi-pencil
        </v-icon>
        <v-icon small color="red" @click="remove(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { AuthService } from "../../../auth";
import { helper } from "../../../../utils";
import Alert from "../../../../components/Alert";
import FileMixin from "../../../../mixins/FileMixin";

export default {
  name: "Facility",
  mixins: [FileMixin],
  components: { Alert },
  data: () => ({
    dialog: false,
    edit: false,
    formData: {
      nameOfEquipment: "",
      quantity: "",
      ownership: "",
      remarks: "",
      File: null,
    },
    headers: [
      {
        text: "Equipment Name",
        align: "start",
        sortable: false,
        value: "nameOfEquipment",
      },
      { text: "Quantity", value: "quantity" },
      { text: "ownership", value: "ownership" },
      { text: "remarks", value: "remarks" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  mounted() {
    this.$store.dispatch("Profile/getFacilities");
  },

  computed: {
    rules() {
      return {
        nameOfEquipment: [(v) => !!v || "nameOfEquipment is required"],
        quantity: [(v) => !!v || "quantity is required"],
        ownership: [(v) => !!v || "Ownership is required"],
        remarks: [(v) => !!v || "Remarks is required"],
        File: [
          (v) => {
            return this.formData.Cert === ""
              ? !!v || "Attachment is required"
              : true;
          },
          (v) => {
            return v
              ? (!!v && v.size < 10000000) ||
                  "Attachment should be less than 10MB"
              : true;
          },
        ],
      };
    },

    facilities() {
      return this.$store.getters["Profile/facilities"];
    },
  },

  methods: {
    clearForm: function () {
      this.formData = {
        nameOfEquipment: "",
        quantity: "",
        ownership: "",
        remarks: "",
      };

      this.dialog = false;
      this.edit = false;
    },

    update: function (payload) {
      this.formData = { ...payload };
      this.edit = true;
      this.dialog = true;
    },

    remove(item) {
      this.$confirm.show({
        title: "Delete this Facility?",
        text: `Are you sure you want to delete?`,
        onConfirm: () => {
          this.$store.dispatch("Profile/removeFacility", { ...item });
        },
      });
    },

    save: function () {
      let isValid = true;
      for (const key in this.formData) {
        if (this.$refs[key])
          isValid = isValid ? this.$refs[key].validate(true) : false;
      }

      if (isValid) {
        let data = { ...this.formData };
        data.profileID = AuthService.user.profile.ProfileID;

        if (this.edit) {
          data.lineNo = parseInt(data.lineNo);
          data.EntryNo = this.formData.EntryNo
            ? parseInt(this.formData.EntryNo)
            : parseInt(this.facilities.length + 1);
        }

        this.$store.dispatch(
          `Profile/${this.edit ? "updateFacility" : "addFacility"}`,
          helper.prepareFormData(data)
        );

        this.clearForm();
      }
    },
  },
};
</script>

<style scoped></style>

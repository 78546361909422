export default {
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Application/getApplications");
    });
  },

  computed: {
    application() {
      return this.$store.getters["Application/applications"];
    },
  },
};

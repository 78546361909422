<template>
  <v-container fluid class="account-settings">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          <v-btn text small @click="$router.back()">
            <v-icon left> mdi-arrow-left </v-icon>
            Back
          </v-btn>
        </v-toolbar-title>

        <v-spacer />
        Account Settings
        <v-spacer />
      </v-toolbar>

      <v-row>
        <v-col cols="12" md="6" offset-md="3">
          <v-card flat>
            <v-card-text>
              <v-alert
                outlined
                v-if="$store.getters['Dashboard/alert'].status"
                dense
                border="left"
                :type="
                  $store.getters['Dashboard/alert'].status === 'success'
                    ? 'success'
                    : 'error'
                "
                class="mb-10"
              >
                {{ $store.getters["Dashboard/alert"].message }}
              </v-alert>
            </v-card-text>

            <v-card-text>
              <v-text-field
                ref="password"
                v-model="formData.password"
                dense
                outlined
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                label="New Password"
                placeholder="Enter a secure password"
                :rules="rules.password"
              />

              <v-text-field
                ref="confirmPassword"
                v-model="formData.confirmPassword"
                dense
                outlined
                label="Confirm Password"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                placeholder="Enter Password again"
                :rules="rules.confirmPassword"
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" @click="reset" class="mx-2">
                <v-icon left>mdi-content-save-move</v-icon>
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { AuthService } from "../../auth";

export default {
  name: "Settings",
  data: function () {
    return {
      tab: null,
      accountSettings: {
        email_reminders: 1,
        email_updates: 1,
        sms_reminders: 1,
        sms_updates: 1,
      },
      showPassword: false,
      formData: {
        password: "",
        confirmPassword: "",
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      for (let key in v.accountSettings) {
        if (key in v.user) v.accountSettings[key] = v.user[key];
      }
    });
  },
  computed: {
    user() {
      return AuthService.user;
    },
    rules() {
      return {
        password: [(v) => !!v || "Password is required"],
        confirmPassword: [
          (v) => !!v || "Password is required",
          (v) => v === this.formData.password || "Password does not match",
        ],
      };
    },
  },

  methods: {
    reset: function () {
      let isValid = true;
      for (const key in this.formData) {
        if (this.$refs[key])
          isValid = isValid ? this.$refs[key].validate(true) : false;
      }

      if (isValid) {
        this.$store.dispatch("Dashboard/resetPassword", { ...this.formData });
      }
    },

    saveSettings: function () {
      this.$store.dispatch("Dashboard/saveSettings", {
        ...this.accountSettings,
      });
    },
  },
};
</script>

<style scoped>
.account-settings {
  font-family: "PT Sans Caption", sans-serif;
  cursor: progress;
  width: 100%;
  margin: 0 auto;
}
</style>

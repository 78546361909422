import DashboardLayout from "../../layouts/dashboard/DashboardLayout";
import Payments from "./views/Payments";
import PaymentHistory from "./components/PaymentHistory";
import Auth from "../../router/middleware/Auth";

export default [
  {
    path: "/payment-history",
    component: DashboardLayout,
    children: [
      {
        path: "",
        components: { view: Payments },
        children: [
          {
            path: "",
            name: "paymentHistory",
            components: { view: PaymentHistory },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
        ],
      },
    ],
  },
];

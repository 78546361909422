<template>
  <bio :initial="true" />
</template>

<script>
import Bio from "../../profile/components/bio/Bio";
export default {
  name: "Initiate",
  components: { Bio },
};
</script>

<style scoped></style>

import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height d-flex flex-row align-center",attrs:{"fluid":""}},[_c(VRow,{staticClass:"mt-n8"},[_c(VCol,{staticClass:"mt-n12",attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c(VSheet,{staticClass:"pa-10",attrs:{"elevation":"2","rounded":"xl"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c(VCardText,[(_vm.$store.getters['Profile/alert'].status)?_c(VAlert,{staticClass:"mb-10",attrs:{"outlined":"","dense":"","border":"left","type":_vm.$store.getters['Profile/alert'].status === 'success'
                    ? 'success'
                    : 'error'}},[_vm._v(" "+_vm._s(_vm.$store.getters["Profile/alert"].message)+" ")]):_vm._e()],1),_c(VCard,{attrs:{"flat":""}},[_c(VCardSubtitle,{staticClass:"text-center font-weight-black mt-n3"},[_vm._v(" Confirm your name below: ")]),_c(VCardTitle,{staticClass:"d-flex flex-row justify-center font-weight-black"},[_vm._v(" "+_vm._s(_vm.user.profile.FirstName)+" "+_vm._s(_vm.user.profile.SecondName)+" "+_vm._s(_vm.user.profile.LastName)+" ")]),(_vm.user.profile.confirmed === false)?_c(VCardActions,{},[_c(VRow,[_c(VCol,{staticClass:"mt-n4",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center overline grey--text"},[_vm._v("OR")])]),_c(VCol,{staticClass:"mt-n10",attrs:{"md":"6"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"info","block":""},on:{"click":_vm.submit}},'v-btn',attrs,false),on),[_vm._v(" Correct ")])]}}],null,false,1359699514)},[_c('span',[_vm._v("Name is correct")])])],1),_c(VCol,{staticClass:"mt-n10",attrs:{"md":"6"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"to":{ name: 'profile' },"color":"secondary","block":""}},'v-btn',attrs,false),on),[_vm._v(" Not Correct ")])]}}],null,false,3628546487)},[_c('span',[_vm._v("Name is incorrect")])])],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
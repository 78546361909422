<template>
  <v-container fluid style="background-color: rgba(255, 255, 255, 0.65)">
    <v-row>
      <v-col
        cols="12"
        :md="initial ? 10 : 12"
        :offset-md="initial ? 1 : 0"
        :pa="initial ? 6 : 0"
      >
        <v-card elevation="0">
          <v-card-title v-if="initial">Initial Firm Profile</v-card-title>

          <v-toolbar dense elevation="0" class="mb-3" v-if="!initial">
            <v-spacer />
            <v-btn small color="primary" @click="edit = !edit">
              <v-icon left>{{
                editable ? "mdi-account-check" : "mdi-account-edit"
              }}</v-icon>
              {{ editable ? "Finish" : "Edit" }}
            </v-btn>
          </v-toolbar>

          <alert />

          <v-row>
            <v-col md="4" sm="12">
              <v-card elevation="0">
                <v-img
                  :src="profilePicture"
                  height="200px"
                  class="rounded"
                  contain
                ></v-img>

                <v-card-subtitle class="text-center" v-if="editable">
                  Attach a passport Size colored photo
                </v-card-subtitle>

                <v-card-actions v-if="editable">
                  <v-file-input
                    outlined
                    dense
                    ref="Image"
                    type="file"
                    v-model="formData.Image"
                    :rules="rules.Image"
                    @change="attachProfilePicture"
                    accept="image/png, image/jpeg"
                    hint="png and jpeg images only"
                    persistent-hint
                  >
                    <template v-slot:label>
                      <div>Attach Photo <span class="red--text">*</span></div>
                    </template>
                  </v-file-input>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-divider vertical />

            <v-col md="8" sm="12">
              <v-form>
                <v-row dense>
                  <v-col md="4" cols="12" class="px-5">
                    <v-text-field
                      v-if="editable"
                      outlined
                      dense
                      @input="
                        (val) =>
                          (formData.FirstName =
                            formData.FirstName.toUpperCase())
                      "
                      v-model="formData.FirstName"
                      :rules="rules.field"
                      ref="FirstName"
                    >
                      <template v-slot:label>
                        <div>Name of Body<span class="red--text">*</span></div>
                      </template>
                    </v-text-field>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light grey--text font-weight-light"
                          >Name of Body
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >{{ formData.FirstName }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="4" cols="12" class="px-5">
                    <v-text-field
                      v-if="editable"
                      outlined
                      dense
                      disabled
                      v-model="formData.Email"
                      @input="
                        (val) => (formData.Email = formData.Email.toUpperCase())
                      "
                      :rules="rules.field"
                      ref="Email"
                    >
                      <template v-slot:label>
                        <div>Email <span class="red--text">*</span></div>
                      </template>
                    </v-text-field>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >Email</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          formData.Email
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="4" cols="12" class="px-5">
                    <v-text-field
                      v-if="editable"
                      outlined
                      dense
                      v-model="formData.PhoneNo"
                      @input="
                        (val) =>
                          (formData.PhoneNo = formData.PhoneNo.toUpperCase())
                      "
                      :rules="rules.field"
                      ref="PhoneNo"
                    >
                      <template v-slot:label>
                        <div>
                          Mobile Number <span class="red--text">*</span>
                        </div>
                      </template>
                    </v-text-field>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >Mobile Number</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          formData.PhoneNo
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="4" cols="12" class="px-5">
                    <v-text-field
                      v-if="editable"
                      outlined
                      dense
                      v-model="formData.PhoneNo2"
                      @input="
                        (val) =>
                          (formData.PhoneNo2 = formData.PhoneNo2.toUpperCase())
                      "
                      ref="PhoneNo2
                      "
                      :rules="rules.field"
                    >
                      <template v-slot:label>
                        <div>
                          Telephone Number <span class="red--text">*</span>
                        </div>
                      </template>
                    </v-text-field>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >Telephone Number</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          formData.PhoneNo2
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="4" cols="12" class="px-5">
                    <v-text-field
                      outlined
                      v-if="editable"
                      dense
                      v-model="formData.Address"
                      @input="
                        (val) =>
                          (formData.Address = formData.Address.toUpperCase())
                      "
                      :rules="rules.field"
                      ref="Address"
                    >
                      <template v-slot:label>
                        <div>Address <span class="red--text">*</span></div>
                      </template>
                    </v-text-field>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >Address
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >{{ formData.Address }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="4" cols="12" class="px-5">
                    <v-text-field
                      v-if="editable"
                      outlined
                      dense
                      v-model="formData.Address2"
                      @input="
                        (val) =>
                          (formData.Address2 = formData.Address2.toUpperCase())
                      "
                      ref="Address2"
                    >
                      <template v-slot:label>
                        <div>Physical Address</div>
                      </template>
                    </v-text-field>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >Physical Address</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          formData.Address2
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="4" cols="12" class="px-5">
                    <v-text-field
                      v-if="editable"
                      outlined
                      dense
                      v-model="formData.faxNo"
                      @input="
                        (val) => (formData.faxNo = formData.faxNo.toUpperCase())
                      "
                      :rules="rules.field"
                      ref="faxNo"
                    >
                      <template v-slot:label>
                        <div>Fax Number <span class="red--text">*</span></div>
                      </template>
                    </v-text-field>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >Fax Number</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          formData.faxNo
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="4" cols="12" class="px-5">
                    <v-text-field
                      v-if="editable"
                      outlined
                      dense
                      disabled
                      v-model="formData.PINRegNo"
                      @input="
                        (val) =>
                          (formData.PINRegNo = formData.PINRegNo.toUpperCase())
                      "
                      :rules="rules.field"
                      ref="PINRegNo"
                    >
                      <template v-slot:label>
                        <div>
                          PIN Reg Number
                          <span class="red--text">*</span>
                        </div>
                      </template>
                    </v-text-field>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >PIN Reg Number</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          formData.PINRegNo
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="4" cols="12" class="px-5">
                    <v-text-field
                      v-if="editable"
                      outlined
                      dense
                      v-model="formData.VATRegNo"
                      @input="
                        (val) =>
                          (formData.VATRegNo = formData.VATRegNo.toUpperCase())
                      "
                      :rules="rules.field"
                      ref="VATRegNo"
                    >
                      <template v-slot:label>
                        <div>
                          VAT Reg Number
                          <span class="red--text">*</span>
                        </div>
                      </template>
                    </v-text-field>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >VAT Reg Number</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          formData.VATRegNo
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="4" cols="12" class="px-5">
                    <v-text-field
                      v-if="editable"
                      outlined
                      dense
                      v-model="formData.houseNo"
                      @input="
                        (val) =>
                          (formData.houseNo = formData.houseNo.toUpperCase())
                      "
                      :rules="rules.field"
                      ref="houseNo"
                    >
                      <template v-slot:label>
                        <div>
                          House Number
                          <span class="red--text">*</span>
                        </div>
                      </template>
                    </v-text-field>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >House Number</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          formData.houseNo
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="4" cols="12" class="px-5">
                    <v-text-field
                      v-if="editable"
                      outlined
                      dense
                      v-model="formData.blockNo"
                      @input="
                        (val) =>
                          (formData.blockNo = formData.blockNo.toUpperCase())
                      "
                      :rules="rules.field"
                      ref="blockNo"
                    >
                      <template v-slot:label>
                        <div>
                          Block Number
                          <span class="red--text">*</span>
                        </div>
                      </template>
                    </v-text-field>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >Block Number</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          formData.blockNo
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="4" cols="12" class="px-5">
                    <v-text-field
                      v-if="editable"
                      outlined
                      dense
                      v-model="formData.lrNo"
                      @input="
                        (val) => (formData.lrNo = formData.lrNo.toUpperCase())
                      "
                      :rules="rules.field"
                      ref="lrNo"
                    >
                      <template v-slot:label>
                        <div>
                          LR Number
                          <span class="red--text">*</span>
                        </div>
                      </template>
                    </v-text-field>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >LR Number</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          formData.lrNo
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col md="6" cols="12" class="px-5">
                    <v-text-field
                      v-if="editable"
                      outlined
                      dense
                      v-model="formData.streetName"
                      @input="
                        (val) =>
                          (formData.streetName =
                            formData.streetName.toUpperCase())
                      "
                      :rules="rules.field"
                      ref="streetName"
                    >
                      <template v-slot:label>
                        <div>
                          Street Name
                          <span class="red--text">*</span>
                        </div>
                      </template>
                    </v-text-field>

                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >Street Name</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          formData.streetName
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col cols="12" md="4" class="px-5">
                    <v-select
                      v-if="editable"
                      dense
                      outlined
                      :items="cities"
                      v-model="formData.City"
                      :rules="rules.City"
                      ref="City"
                      item-value="Code"
                      item-text="City"
                    >
                      <template v-slot:label>
                        <div>City <span class="red--text">*</span></div>
                      </template>
                    </v-select>
                    <v-list-item dense v-else two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline grey--text font-weight-light"
                          >City</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          formData.city
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>

          <v-card-actions class="d-flex flex-row justify-end" v-if="editable">
            <v-btn @click="saveProfile()" color="primary">
              {{ initial ? "Create Profile" : "Save" }}
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import SetupMixin from "../../../setup/SetupMixin";
import { AuthService } from "../../../auth";
import { helper } from "../../../../utils";
import fileType from "file-type";
import Alert from "../../../../components/Alert";
export default {
  name: "FirmBio",
  components: { Alert },
  mixins: [SetupMixin],
  props: { initial: Boolean },
  data: function () {
    return {
      image_url: "",
      edit: false,
      formData: {
        FirstName: "",
        Address: "",
        Address2: "",
        PhoneNo2: "",
        faxNo: "",
        Email: AuthService.user.email,
        PhoneNo: AuthService.user.phone_no,
        PINRegNo: AuthService.user.id_number,
        IdentificationDocumentNo: AuthService.user.id_number,
        VATRegNo: "",
        houseNo: "",
        blockNo: "",
        lrNo: "",
        streetName: "",
        City: "",
        Image: undefined,
      },
    };
  },

  mounted() {
    this.$store.dispatch("Profile/getFirmBio");
    this.getSetups(["getCities"]); //TODO Enable setups
  },

  computed: {
    rules() {
      return {
        field: [(v) => !!v || "Field is required"],
        Image: [
          (v) => {
            return this.editable &&
              AuthService.user.profile &&
              !AuthService.user.profile.picture
              ? !!v || "Profile Picture is required"
              : true;
          },
          (v) => {
            return this.editable && !!v
              ? v.size < 10000000 || "Attachment should be less than 10MB"
              : true;
          },
          (v) => {
            return this.editable && !!v
              ? v.type.includes("image") || "Attachment must be an image"
              : true;
          },
        ],
      };
    },

    profilePicture() {
      let profile = AuthService.user.profile;

      if (this.image_url) return this.image_url;

      return profile
        ? profile.picture
          ? `data:image/png;base64,${profile.picture}`
          : "/img/avatar.jpg"
        : "/img/avatar.jpg";
    },

    bio() {
      return this.$store.getters["Profile/firmBio"];
    },

    editable() {
      return this.initial || this.edit;
    },
  },

  methods: {
    saveProfile: function () {
      let isValid = true;
      for (const key in this.formData) {
        if (this.$refs[key])
          isValid = isValid ? this.$refs[key].validate(true) : false;
      }

      if (!isValid) return;

      let meta = {
        MemberShipType: AuthService.user.category,
        Email: AuthService.user.email,
        Type: "Personal",
        Name: this.formData.FirstName,
      };

      this.$store.dispatch(
        `Profile/${this.initial ? "saveFirmBio" : "updateFirmBio"}`,
        helper.prepareFormData({ ...this.formData, ...meta })
      );
    },

    attachProfilePicture: function () {
      this["image_url"] = URL.createObjectURL(this.formData["Image"]);
    },

    downloadFile: async function (attachment, filename = "ID.pdf") {
      const myBuffer = Buffer.from(attachment, "base64");
      await fileType.fromBuffer(myBuffer).then((res) => {
        const path = `data:${res.mime})};base64,${attachment}`;
        const element = document.createElement("a");
        element.setAttribute("href", path);
        element.setAttribute("download", `${filename}`);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      });
    },
  },

  watch: {
    bio: {
      handler: function () {
        if (this.bio.length > 0) {
          this.formData = { ...this.bio[0].contacts[0] };
          this.formData.FirstName = this.bio[0].FirstName;
        }
      },
    },
  },
};
</script>

<template>
  <v-container>
    <v-sheet>
      <router-view name="view" />
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: "Payments",
};
</script>

<style scoped></style>

<template>
  <v-container fluid class="fill-height d-flex flex-row align-center">
    <v-row class="mt-n8">
      <v-col cols="12" md="8" offset-md="2" class="mt-n12">
        <v-sheet elevation="2" rounded="xl" class="pa-10">
          <v-row>
            <v-col cols="12" md="12">
              <v-card-text>
                <v-alert
                  outlined
                  v-if="$store.getters['Profile/alert'].status"
                  dense
                  border="left"
                  :type="
                    $store.getters['Profile/alert'].status === 'success'
                      ? 'success'
                      : 'error'
                  "
                  class="mb-10"
                >
                  {{ $store.getters["Profile/alert"].message }}
                </v-alert>
              </v-card-text>
              <v-card flat>
                <v-card-subtitle class="text-center font-weight-black mt-n3">
                  Confirm your name below:
                </v-card-subtitle>

                <v-card-title
                  class="d-flex flex-row justify-center font-weight-black"
                >
                  {{ user.profile.FirstName }}
                  {{ user.profile.SecondName }} {{ user.profile.LastName }}
                </v-card-title>

                <v-card-actions
                  class=""
                  v-if="user.profile.confirmed === false"
                >
                  <v-row>
                    <v-col cols="12" class="mt-n4">
                      <p class="text-center overline grey--text">OR</p>
                    </v-col>

                    <v-col md="6" class="mt-n10">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="info"
                            block
                            v-bind="attrs"
                            v-on="on"
                            @click="submit"
                          >
                            Correct
                          </v-btn>
                        </template>
                        <span>Name is correct</span>
                      </v-tooltip>
                    </v-col>

                    <v-col md="6" class="mt-n10">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :to="{ name: 'profile' }"
                            color="secondary"
                            block
                            v-bind="attrs"
                            v-on="on"
                          >
                            Not Correct
                          </v-btn>
                        </template>
                        <span>Name is incorrect</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AuthService } from "../../auth";

export default {
  name: "profileConfirm",

  data: function () {
    return {
      formData: {
        confirmed: true,
        ProfileID: "",
      },
    };
  },

  computed: {
    user() {
      return AuthService.user;
    },
    application() {
      return this.$store.getters["Application/applications"];
    },
  },

  methods: {
    submit: function () {
      this.formData.ProfileID = this.user.profile_id;
      this.$store.dispatch("Profile/updateProfile", { ...this.formData });
    },
  },
};
</script>

<style scoped>
.fonts {
  font-family: "Times New Roman";
}
</style>

<template>
  <v-app>
    <v-app-bar app dark color="primary">
      <v-img
        alt="Logo"
        class="shrink mr-2"
        contain
        :src="`data:image/png;base64,${companyInformation.logo}`"
        transition="scale-transition"
        width="50"
        @click="$router.push('/')"
      />
      <v-spacer />

      <v-toolbar-title class="overline mr-2">{{ title }}</v-toolbar-title>

      <v-spacer />

      <v-btn :to="{ name: 'search' }" text color="white" light>
        <span class="mr-2">Member Checker</span>
        <v-icon>mdi-account-search</v-icon>
      </v-btn>

      <v-btn
        v-if="auth.check()"
        @click="$store.dispatch('Auth/logout')"
        text
        class="ml-2"
        color="white"
        light
      >
        <span class="mr-2">Logout</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main
      :class="
        $route.name === 'forgotPassword' && $vuetify.breakpoint.mdAndBelow
          ? 'mb-16'
          : ''
      "
    >
      <router-view />
    </v-main>

    <spinner />

    <Footer opacity="0.65" />
  </v-app>
</template>

<script>
import Footer from "../../components/Footer";
import Spinner from "../../plugins/loader/views/Spinner";
import { AuthService } from "../../modules/auth";
import SetupMixin from "../../modules/setup/SetupMixin";

export default {
  name: "AuthLayout",
  mixins: [SetupMixin],
  components: { Spinner, Footer },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Admin/getSettings");
      if (AuthService.check() && AuthService.user.profile) {
        v.$router.push({ name: "dashboard" });
      } else if (AuthService.check() && AuthService.user.is_admin) {
        v.$router.push("/admin");
      } else if (
        AuthService.check() &&
        !AuthService.user.profile &&
        AuthService.user.type === "Individual"
      )
        v.$router.push({ name: "initiate" });
      else if (
        AuthService.check() &&
        !AuthService.user.profile &&
        AuthService.user.type === "Corporate"
      )
        v.$router.push({ name: "initiateFirm" });
    });
  },

  computed: {
    auth() {
      return AuthService;
    },
    title() {
      return process.env.VUE_APP_LANDING_PAGE_TITLE;
    },
    settings() {
      return this.$store.getters["Admin/adminGetters"]("settings");
    },
  },

  mounted() {
    Event.$emit("snack-bar", {});
  },

  watch: {
    "$route.name": {
      handler: function () {
        this.$store.commit("Auth/SET_ALERT", null);
      },
    },
    settings: {
      handler: async function (val) {
        if (val) {
          if (val.site_colors) {
            const colors = JSON.parse(val.site_colors);
            colors.forEach((el) => {
              this.$vuetify.theme.themes.light[el.colorName] = el.color;
            });
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>

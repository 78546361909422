<template>
  <v-container class="">
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <v-card class="pa-md-5" elevation="0">
          <v-card-text>
            <v-card-subtitle>
              Complete the requirements checklist below
            </v-card-subtitle>
            <v-data-table
              :headers="headers"
              :items="checkList"
              class="mt-5 elevation-1"
              hide-default-footer
            >
              <template v-slot:header="{ props: { headers } }">
                <thead>
                  <tr>
                    <th :colspan="headers.length">
                      <v-progress-linear
                        rounded
                        :value="completedChecklist"
                        class="white--text font-weight-thin"
                        :color="
                          completedChecklist < 50 ? 'primary' : 'secondary'
                        "
                        height="18"
                      >
                        <strong>{{ Math.round(completedChecklist) }}%</strong>
                      </v-progress-linear>
                    </th>
                  </tr>
                </thead>
              </template>

              <template v-slot:item.checkListType="{ item }">
                <td>
                  <h4 class="heading">{{ item.checkListType }}</h4>
                  <span class="subtitle-2 grey--text">{{
                    item.description
                  }}</span>
                </td>
              </template>

              <template v-slot:item.pass="{ item }">
                <td>
                  <v-icon :color="item.pass ? 'success' : 'warning'">
                    {{ item.pass ? "mdi-check" : "mdi-alert" }}
                  </v-icon>
                </td>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn
                  small
                  :to="{
                    name: 'component',
                    params: { name: item.checkListType },
                  }"
                  color="secondary"
                  :disabled="item.pass"
                >
                  Complete
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CheckList",
  data: function () {
    return {
      headers: [
        {
          text: "Item",
          align: "start",
          sortable: false,
          value: "checkListType",
        },
        { text: "Status", value: "pass" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },

  computed: {
    checkList() {
      return this.$store.getters["Checklist/checklist"];
    },

    completedChecklist: function () {
      const completed = this.checkList.filter((check) => check.pass).length;
      return (completed / this.checkList.length) * 100;
    },
  },
};
</script>

<style scoped></style>

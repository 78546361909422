<template>
  <v-container>
    <v-sheet>
      <!-- <v-toolbar dense elevation="0">
        <v-toolbar-title class="subtitle-2">License Card</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-divider></v-divider> -->
      <v-container>
        <v-tabs
          v-model="tab"
          active-class="orange lighten-5"
          :active-tab.sync="tab"
        >
          <v-tab>
            <v-icon class="mr-1" small>mdi-account-outline</v-icon>
            License details/General
          </v-tab>
          <v-tab :disabled="tab < 1">
            <v-icon class="mr-1" small> mdi-notebook-check-outline </v-icon>
            Checklist
          </v-tab>
          <v-tab :disabled="tab < 2">
            <v-icon class="mr-1" small> mdi-notebook-outline </v-icon>
            Declaration
          </v-tab>

          <v-tab-item>
            <licenseDetails :next="next" :back="back" />
          </v-tab-item>
          <v-tab-item :disabled="tab < 1">
            <checklist :next="next" :back="back" />
          </v-tab-item>
          <v-tab-item :disabled="tab < 2">
            <declaration :next="next" :back="back" />
          </v-tab-item>
        </v-tabs>
      </v-container>
    </v-sheet>
  </v-container>
</template>

<script>
import licenseDetails from "./LicenseDetails";
import checklist from "./Checklist";
import declaration from "./Declaration";
import AuthService from "../../auth/AuthService";
import setupMixin from "../../setup/SetupMixin";

export default {
  name: "LicenseCard",
  mixins: [setupMixin],
  data() {
    return {
      tab: 0,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("License/getChecklists", AuthService.user.category);
      v.$store.dispatch("License/getLicenseProfile");
      v.getSetups([
        "getMembershipCategories",
        "getCounties",
        "getCities",
        "getCountries",
      ]);
    });
  },

  components: {
    licenseDetails,
    checklist,
    declaration,
  },

  methods: {
    next: function () {
      if (this.tab === this.tab.length) return;
      this.tab++;
    },
    back: function () {
      if (this.tab === 0) return;
      this.tab--;
    },
  },
};
</script>

<style scoped></style>

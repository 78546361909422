<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="slide-x-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn rounded v-bind="attrs" v-on="on" icon>
        <v-badge
          :content="notification.messages.length + application.length"
          :value="notification.messages.length + application.length"
          color="error"
          overlap
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card min-width="400">
      <v-tabs grow>
        <v-tab>
          Alerts
          <v-badge
            :content="application.length"
            :value="application.length"
            color="warning"
            overlap
          >
            <v-icon right>mdi-bell</v-icon>
          </v-badge>
        </v-tab>
        <!-- <v-tab>
          Messages
          <v-badge
            :content="notification.messages.length"
            :value="notification.messages.length"
            color="warning"
            overlap
          >
            <v-icon right>mdi-email</v-icon>
          </v-badge>
        </v-tab> -->

        <v-tab-item>
          <v-list dense>
            <v-list-item-group>
              <v-list-item
                two-line
                v-for="(alert, i) in application"
                :key="i"
                :to="{ name: alert.action }"
              >
                <v-list-item-action>
                  <v-icon color="primary">mdi-alert-circle-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Action required for
                    {{
                      alert.Code
                        ? `Application: ${alert.Code}`
                        : `Subscription: ${alert.documentNo}`
                    }}
                    {{
                      alert.Code
                        ? `&thinsp; (${formatDate(
                            alert.membershipComments[
                              alert.membershipComments.length - 1
                            ].commentDate
                          )})`
                        : ""
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ alert.Code ? "" : "Amount: &thinsp;" }}
                    <span v-if="alert.Code">
                      {{
                        alert.membershipComments[
                          alert.membershipComments.length - 1
                        ].comment
                      }}
                    </span>
                    <span v-else>
                      {{ alert.billedAmount | currencyFormat }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-tab-item>

        <v-tab-item>
          <v-list dense>
            <v-list-item-group>
              <v-list-item
                two-line
                v-for="(message, i) in notification.messages"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ message.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    message.message
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="caption">
                  {{ message.time }}
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-tab-item>
      </v-tabs>
      <!-- Application Status is : {{ getstatus }} -->
    </v-card>
  </v-menu>
</template>

<script>
import DateMixin from "../../../mixins/DateMixin";
import SetupMixin from "../../setup/SetupMixin";

export default {
  name: "Notification",
  mixins: [DateMixin, SetupMixin],
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
    statusdata: undefined,
    notification: {
      alerts: [],
      messages: [],
    },
  }),

  mounted() {
    // listen to socket notification channel
    // window.Echo.channel("notifications").listen("DisplayNotifications", (e) => {
    //   this.statusdata = e.status;
    //   console.log(this.statusdata);
    // });
  },
  computed: {
    application() {
      return [
        ...this.$store.getters["Subscriptions/subscriptions"].filter((item) => {
          item.action = "subscriptionList";
          return !item.paid;
        }),
        ...this.$store.getters["Application/applications"].filter((item) => {
          item.action = "ApplicationList";
          return (
            item.membershipComments.length !== 0 &&
            !["New", "Released", "Open"].includes(item.status)
          );
        }),
      ];
    },
    // getstatus() {
    //   const status = this.statusdata;
    //   console.log(this.statusdata);
    //   return status;
    // },
  },
  methods: {
    // getresdata() {
    //   this.data;
    // },
  },
};
</script>

<style scoped></style>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Invoice "),_c(VSpacer),(_vm.proforma)?_c(VBtn,{attrs:{"color":"warning","depressed":""},on:{"click":_vm.download}},[_vm._v(" Download "),_c(VIcon,[_vm._v("mdi-cloud-download")])],1):_vm._e(),(_vm.service && _vm.service.receiptNo === '')?_c(VBtn,{staticClass:"ml-2",attrs:{"depressed":"","color":"primary"},on:{"click":_vm.pay}},[_vm._v(" Pay Now "),_c(VIcon,[_vm._v("mdi-cash")])],1):_vm._e()],1),_c(VCardText,[_c(VRow,[_c('pdf',{staticStyle:{"width":"100%"},attrs:{"src":("data:application/pdf;base64," + _vm.proforma)}})],1)],1),_c('Checkout')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
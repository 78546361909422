import call from "../../../../service/http";
import RefereesConstants from "./RefereesConstants";

export default {
  state: {
    referees: [],
    referee: {},
    report: "",
    loadingReferee: false,
  },
  mutations: {
    SET_REFEREES: (state, payload) => {
      state.referees = payload;
    },
    SET_REFEREE: (state, payload) => {
      state.referee = payload;
    },
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    referees: (state) => state.referees,
    referee: (state) => state.referee,
    loadingReferee: (state) => state.loadingReferee,
  },
  actions: {
    getReferees: ({ commit }) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", RefereesConstants.referees)
        .then((res) => {
          commit("SET_REFEREES", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    searchReferee: ({ commit }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", RefereesConstants.SEARCH_MEMBER, data)
        .then((res) => {
          commit("SET_REFEREE", res.data.data);
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    addReferee: ({ commit, dispatch }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", RefereesConstants.ADD_REFEREES, data)
        .then(() => {
          dispatch("getReferees");
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    removeReferee: ({ commit, dispatch }, payload) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", RefereesConstants.remove, payload)
        .then(() => {
          dispatch("getReferees");
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    updateReferee: ({ dispatch, commit }, payload) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", RefereesConstants.update, payload)
        .then(() => {
          dispatch("getReferees");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    sendEmail({ commit }, data) {
      commit("SET_LOADING", true, { root: true });
      call("post", RefereesConstants.SEND_EMAIL, data)
        .then(() => {
          commit("SET_ALERT", {
            status: "success",
            message: "Email Sent",
          });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    getRefereeForm: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("get", RefereesConstants.GET_REFEREE_FORM(data)).then((res) => {
        commit("MUTATE", { state: "profile", data: res.data.data });
      });
    },

    acceptRefereeRequest: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("MUTATE", { state: "loadingReferee", data: true });
      call("post", RefereesConstants.UPDATE_REFEREE, data)
        .then((res) => {
          Event.$emit("ApiSuccess", "Referee Confirmed Successfully");
          commit("SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "loadingReferee", data: false });
          Event.$emit("routeChange", res.data.data[0]);
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "loadingReferee", data: false });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    rejectRefereeRequest: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("MUTATE", { state: "loadingReferee", data: true });
      call("post", RefereesConstants.UPDATE_REFEREE, data)
        .then(() => {
          Event.$emit("ApiSuccess", "Referee Rejected Successfully");
          commit("SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "loadingReferee", data: false });
          Event.$emit("routeBack");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "loadingReferee", data: false });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    getRefereeFormDoc: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("get", RefereesConstants.REFEREE_FORM_REPORT(data))
        .then((res) => {
          commit("MUTATE", { state: "report", data: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};

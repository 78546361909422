import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":"60%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("Update User Details")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","color":"red"},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VForm,{ref:"userForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VContainer,[_c(VTextField,{attrs:{"rules":[_vm.rules.email, _vm.rules.required],"label":"User Email","dense":"","outlined":"","prepend-inner-icon":"mdi-email","clearable":""},model:{value:(_vm.userFormData.email),callback:function ($$v) {_vm.$set(_vm.userFormData, "email", $$v)},expression:"userFormData.email"}}),_c(VTextField,{attrs:{"rules":[_vm.rules.required],"label":"User Phone Number","dense":"","outlined":"","prepend-inner-icon":"mdi-phone","clearable":""},model:{value:(_vm.userFormData.phone_no),callback:function ($$v) {_vm.$set(_vm.userFormData, "phone_no", $$v)},expression:"userFormData.phone_no"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.$store.getters['loader/loading'],"loading":_vm.$store.getters['loader/loading']},on:{"click":_vm.saveUser}},[_vm._v("Save")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
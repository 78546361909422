import call from "../../../../service/http";
import employeeConstants from "./employeeConstants";

export default {
  state: {
    employees: [],
    employeeSetup: [],
  },
  mutations: {
    SET_EMPLOYEES: (state, payload) => {
      state.employees = payload;
    },
    SET_EMPLOYEE_SETUP: (state, payload) => {
      state.employeeSetup = payload;
    },
  },
  getters: {
    employees: (state) => state.employees,
    employeeSetup: (state) => state.employeeSetup,
  },
  actions: {
    getEmployees: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("get", employeeConstants.employees)
        .then((res) => {
          commit("SET_EMPLOYEES", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    getEmployeeSetup: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", employeeConstants.employeeSetup)
        .then((res) => {
          commit("SET_EMPLOYEE_SETUP", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
        });
    },

    addEmployee: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", employeeConstants.employees, data)
        .then(() => {
          dispatch("getEmployees");
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    removeEmployee: ({ commit, dispatch }, payload) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", employeeConstants.remove, payload)
        .then(() => {
          dispatch("getEmployees");
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    updateEmployee: ({ dispatch, commit }, payload) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", employeeConstants.update, payload)
        .then(() => {
          dispatch("getEmployees");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};

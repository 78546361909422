import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VSheet,[_c(VToolbar,{attrs:{"dense":"","elevation":"0"}},[_c(VToolbarTitle,{staticClass:"subtitle-2 text-capitalize"},[_vm._v(" Events ")]),_c(VSpacer)],1),_c(VDivider),_c('router-view',{attrs:{"name":"view"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import DashboardLayout from "../../layouts/dashboard/DashboardLayout";
import Billing from "./views/Billing";
import BillingList from "./components/BillingList";
import BillingDocument from "./components/BillingDocument";
import Auth from "../../router/middleware/Auth";
import Invoice from "./components/Invoice";
import Receipt from "./components/Receipt";

export default [
  {
    path: "/billing",
    component: DashboardLayout,
    children: [
      {
        path: "",
        components: { view: Billing },
        children: [
          {
            path: "",
            name: "billingList",
            components: { view: BillingList },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
          {
            path: "invoice/:code",
            name: "invoice",
            components: { view: Invoice },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
          {
            path: "receipt/:code",
            name: "receipt",
            components: { view: Receipt },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
          {
            path: "document/:type/:code",
            name: "billingDocument",
            components: { view: BillingDocument },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
        ],
      },
    ],
  },
];

<template>
  <v-container class="mt-5">
    <alert />

    <p class="red--text font-italic caption pa-3">
      <v-icon color="red" small>mdi-alert</v-icon>
      All documents should be scanned certified colour copy. Certification to be
      done by the Commissioner of Oaths whose names and address are fully
      displayed on the Rubber Stamp
    </p>

    <v-data-table
      :headers="headers"
      :items="certifications.filter((c) => c.Type === 'Professional')"
      dense
      class="elevation-1"
      :loading="$store.getters['loading']"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>ACADEMIC QUALIFICATIONS</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog persistent v-model="dialog" max-width="50%">
            <template v-slot:activator="{}">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Add Certification
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      @click="
                        openDialog({ locale: 'Local', type: 'Professional' })
                      "
                    >
                      <v-list-item-title>Local University</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="
                        openDialog({
                          locale: 'Foreign',
                          type: 'Professional',
                        })
                      "
                    >
                      <v-list-item-title>Foreign University</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </template>
            <v-card>
              <v-card-title class="primary">
                <span class="text-h5">
                  {{ edit ? "Edit" : "Add" }} Qualification
                </span>
              </v-card-title>

              <v-card-text class="mt-10">
                <v-container>
                  <v-form ref="engineeringForm">
                    <v-row dense>
                      <v-col
                        v-if="
                          formData.Category === 'Local' &&
                          formData.Type === 'Professional' &&
                          minorSettings.organisation_caption === 'EBK'
                        "
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-autocomplete
                          dense
                          outlined
                          :items="localUniversities"
                          v-model="formData.UniversityCode"
                          :rules="rules.UniversityCode"
                          ref="UniversityCode"
                          item-value="code"
                          item-text="description"
                        >
                          <template v-slot:label>
                            <div>
                              Institution <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <!-- fallback for auto-complete -->

                      <v-col v-else cols="12" sm="6" md="4">
                        <v-text-field
                          dense
                          outlined
                          v-model="formData.Institution"
                          :rules="rules.Institution"
                          @input="
                            (val) =>
                              (formData.Institution =
                                formData.Institution.toUpperCase())
                          "
                          ref="Institution"
                        >
                          <template v-slot:label>
                            <div>
                              Institution <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>

                      <!-- AreaOfSpecialization -->
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        v-if="formData.Type === 'Professional'"
                      >
                        <v-autocomplete
                          v-if="
                            minorSettings.organisation_caption === 'EBK' &&
                            accreditedPrograms &&
                            accreditedPrograms.length !== 0
                          "
                          dense
                          outlined
                          :items="accreditedPrograms"
                          v-model="formData.AreaOfSpecialization"
                          :rules="rules.AreaOfSpecialization"
                          ref="AreaOfSpecialization"
                          item-value="code"
                          item-text="description"
                          placeholder="i.e Civil Engineering"
                          messages="i.e Civil Engineering"
                        >
                          <template v-slot:label>
                            <div>
                              Area of Specialization
                              <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-autocomplete>

                        <v-text-field
                          v-else
                          dense
                          outlined
                          v-model="formData.AreaOfSpecialization"
                          :rules="rules.AreaOfSpecialization"
                          ref="AreaOfSpecialization"
                          :messages="specializationCaption"
                        >
                          <template v-slot:label>
                            <div>
                              {{
                                minorSettings.organisation_caption === "EBK"
                                  ? "Area of Specialization"
                                  : "Academic Qualification"
                              }}
                              <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          dense
                          outlined
                          label="Certificate Awarded"
                          :items="certificationCodes"
                          v-model="formData.CertificateAwarded"
                          :rules="rules.CertificateAwarded"
                          ref="CertificateAwarded"
                          item-value="Code"
                          item-text="Code"
                        >
                          <template v-slot:label>
                            <div>
                              Certificate Awarded
                              <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-select>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <!-- date picker menu -->
                        <v-menu
                          ref="menu"
                          v-model="startDateMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              dense
                              outlined
                              v-model="formData.StartDate"
                              :rules="rules.StartDate"
                              ref="StartDate"
                              label="Start Date"
                              readonly
                              v-on="on"
                            >
                              <template v-slot:label>
                                <div>
                                  Start Date <span class="red--text">*</span>
                                </div>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="formData.StartDate"
                            no-title
                            scrollable
                            :max="today"
                            @input="startDateMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <!-- date picker menu -->
                        <v-menu
                          ref="menu"
                          v-model="endDateMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              dense
                              outlined
                              v-model="formData.EndDate"
                              :rules="rules.EndDate"
                              ref="EndDate"
                              label="End Date"
                              readonly
                              v-on="on"
                            >
                              <template v-slot:label>
                                <div>
                                  End Date <span class="red--text">*</span>
                                </div>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="formData.EndDate"
                            no-title
                            scrollable
                            :max="today"
                            @input="endDateMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        v-if="!formData.Type === 'Educational'"
                      >
                        <!-- date picker menu -->
                        <v-menu
                          ref="menu"
                          v-model="GraduationDateMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              dense
                              outlined
                              v-model="formData.GraduationDate"
                              :rules="rules.GraduationDate"
                              ref="GraduationDate"
                              label="Graduation Date"
                              readonly
                              v-on="on"
                            >
                              <template v-slot:label>
                                <div>
                                  Graduation Date
                                  <span class="red--text">*</span>
                                </div>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="formData.GraduationDate"
                            no-title
                            scrollable
                            :max="today"
                            @input="GraduationDateMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          dense
                          outlined
                          v-model="formData.CertificateNo"
                          :rules="rules.CertificateNo"
                          ref="CertificateNo"
                          @input="
                            (val) =>
                              (formData.CertificateNo =
                                formData.CertificateNo.toUpperCase())
                          "
                        >
                          <template v-slot:label>
                            <div>
                              Certificate No. <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-file-input
                          outlined
                          dense
                          ref="File"
                          accept="application/pdf"
                          hint="pdf documents only"
                          persistent-hint
                          type="file"
                          v-model="formData.File"
                          :rules="rules.File"
                          :append-outer-icon="
                            formData.Cert ? 'mdi-download' : ''
                          "
                          @click:append-outer="downloadFile(formData.Cert)"
                        >
                          <template v-slot:label>
                            <div>
                              Attach Certified Certificate
                              <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-file-input>
                      </v-col>
                    </v-row>

                    <v-row
                      dense
                      class="mt-3"
                      v-if="formData.Category === 'Foreign'"
                    >
                      <v-col cols="12">
                        <h4 class="overline">Transcripts</h4>
                        <v-divider class="mb-3" />
                      </v-col>
                      <v-col cols="5">
                        <v-text-field
                          outlined
                          dense
                          v-model="transcriptForm.description"
                          @input="
                            (val) =>
                              (transcriptForm.description =
                                transcriptForm.description.toUpperCase())
                          "
                        >
                          <template v-slot:label>
                            <div>
                              Transcript Description
                              <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="5">
                        <v-file-input
                          label="Transcript Attachment"
                          accept="application/pdf"
                          hint="pdf documents only"
                          persistent-hint
                          type="file"
                          outlined
                          dense
                          v-model="transcriptForm.attachment"
                        >
                          <template v-slot:label>
                            <div>
                              Transcript Attachment
                              <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-file-input>
                      </v-col>

                      <v-col cols="2">
                        <v-btn @click="addTranscript" color="primary" block>
                          <v-icon left>mdi-file-plus</v-icon>
                          Add
                        </v-btn>
                      </v-col>

                      <v-col cols="12">
                        <v-chip
                          label
                          color="secondary"
                          large
                          class="ma-2"
                          @click="downloadFile(script.attachment)"
                          v-for="(script, i) in formData.transcript"
                          :key="i"
                          close
                          @click:close="removeTranscript(script)"
                        >
                          <v-icon left>mdi-file</v-icon>
                          {{ script.description }}
                        </v-chip>
                      </v-col>
                    </v-row>

                    <v-row
                      dense
                      class="mt-3"
                      v-if="formData.Category === 'Foreign'"
                    >
                      <v-col cols="12">
                        <h4 class="overline">
                          Contact Information from Institution
                        </h4>
                        <v-divider class="mb-3" />
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          dense
                          outlined
                          label="Contact Person"
                          v-model="formData.ContactPerson"
                          :rules="rules.ContactPerson"
                          @input="
                            (val) =>
                              (formData.ContactPerson =
                                formData.ContactPerson.toUpperCase())
                          "
                          ref="ContactPerson"
                        />
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          dense
                          outlined
                          label="Designation"
                          v-model="formData.ContactDesignation"
                          :rules="rules.ContactDesignation"
                          ref="ContactDesignation"
                          @input="
                            (val) =>
                              (formData.ContactDesignation =
                                formData.ContactDesignation.toUpperCase())
                          "
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          dense
                          outlined
                          label="Country"
                          :items="countries"
                          v-model="formData.ContactCountry"
                          :rules="rules.ContactCountry"
                          ref="ContactCountry"
                          item-value="code"
                          item-text="displayName"
                        />
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          dense
                          outlined
                          label="Email"
                          v-model="formData.ContactEmail"
                          :rules="rules.ContactEmail"
                          ref="ContactEmail"
                          @input="
                            (val) =>
                              (formData.ContactEmail =
                                formData.ContactEmail.toUpperCase())
                          "
                        />
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          dense
                          outlined
                          label="Address"
                          v-model="formData.contactPersonAddress"
                          :rules="rules.contactPersonAddress"
                          ref="contactPersonAddress"
                          @input="
                            (val) =>
                              (formData.contactPersonAddress =
                                formData.contactPersonAddress.toUpperCase())
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="clearForm"> Cancel </v-btn>
                <v-btn color="primary" @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.attachment="{ item }">
        <v-btn
          small
          color="secondary"
          outlined
          @click="downloadFile(item.Cert, item.Institution)"
        >
          <v-icon left>mdi-file</v-icon>
          Download
        </v-btn>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" color="blue" @click="update(item)">
          mdi-pencil
        </v-icon>
        <v-icon small color="red" @click="remove(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <v-data-table
      :headers="
        minorSettings.organisation_caption === 'EBK'
          ? otherQualificationHeaders
          : headers
      "
      :items="certifications.filter((c) => c.Type === 'Educational')"
      dense
      class="elevation-1 mt-10"
      :loading="$store.getters['loading']"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title
            >OTHER QUALIFICATIONS (e.g O Level,
            {{
              minorSettings.organisation_caption === "EBK"
                ? "bridging certificate and craft certificate"
                : ""
            }})</v-toolbar-title
          >
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="primary"
            dark
            class="mb-2"
            @click="openDialog({ locale: 'Local', type: 'Educational' })"
          >
            Add Other
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.attachment="{ item }">
        <v-btn
          small
          color="secondary"
          outlined
          @click="downloadFile(item.Cert, item.Institution)"
        >
          <v-icon left>mdi-file</v-icon>
          Download
        </v-btn>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" color="blue" @click="update(item)">
          mdi-pencil
        </v-icon>
        <v-icon small color="red" @click="remove(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { AuthService } from "../../../auth";
import SetupMixin from "../../../setup/SetupMixin";
import { helper } from "../../../../utils";
import Alert from "../../../../components/Alert";
import FileMixin from "../../../../mixins/FileMixin";
import moment from "moment";

export default {
  name: "Certification",
  components: { Alert },
  mixins: [SetupMixin, FileMixin],
  data: () => ({
    dialog: false,
    edit: false,
    today: new Date().toISOString().substr(0, 10),
    startDateMenu: false,
    GraduationDateMenu: false,
    endDateMenu: false,
    options: [
      { value: "Educational", label: "Others" },
      { value: "Professional", label: "Professional" },
    ],
    transcriptForm: {
      description: "",
      attachment: "",
    },
    formData: {
      Institution: "",
      CompletionYear: 2021,
      AreaOfSpecialization: "",
      CertificateAwarded: "",
      QualificationDescription: "",
      Type: "",
      CertificateNo: "",
      CertificationClass: "",
      Category: "",
      StartDate: "",
      EndDate: "",
      GraduationDate: "",
      ContactPerson: "",
      ContactDesignation: "",
      contactPersonAddress: "",
      ContactEmail: "",
      ContactCountry: "",
      UniversityCode: "",
      File: undefined,
      Cert: "",
      transcript: [],
    },
    headers: [
      {
        text: "Institution",
        align: "start",
        sortable: false,
        value: "Institution",
      },
      { text: "Area Of Specialization", value: "AreaOfSpecialization" },
      { text: "Locale", value: "Category" },
      { text: "From", value: "StartDate" },
      { text: "To", value: "EndDate" },
      { text: "Attachment", value: "attachment", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  mounted() {
    this.getSetups([
      "getQualificationCodes",
      "getCertificationCodes",
      "getDivisions",
      "getUniversities",
      "getCountries",
      "getSpecializationTypes",
    ]);
    this.$store.dispatch("Profile/getCertifications");
  },

  computed: {
    rules: function () {
      return {
        Institution: [(v) => !!v || "Institution is required"],
        CompletionYear: [(v) => !!v || "Completion Year is required"],
        AreaOfSpecialization: [
          (v) => !!v || "Area of Specialization is required",
        ],
        CertificateAwarded: [(v) => !!v || "Certificate Awarded is required"],
        Type: [(v) => !!v || "Type is required"],
        CertificateNo: [(v) => !!v || "Certificate No is required"],
        CertificationClass: [(v) => !!v || "Certification Class is required"],
        Category: [(v) => !!v || "Category is required"],
        StartDate: [(v) => !!v || "Start Date is required"],
        EndDate: [
          (v) => !!v || "End Date is required",
          (v) =>
            moment(this.formData.StartDate).isBefore(moment(v)) ||
            "End Date Cannot come before Start Date",
        ],
        GraduationDate: [
          (v) => !!v || "Graduation Date is required",
          (v) =>
            moment(this.formData.StartDate).isBefore(moment(v)) ||
            "Graduation Date Cannot come before Start Date",
        ],
        UniversityCode: [
          (v) =>
            ((this.formData.Category === "Local" ||
              this.formData.Category === "Foreign") &&
              !!v) ||
            "University is required",
        ],
        ContactPerson: [
          (v) =>
            (this.formData.Category === "Foreign" && !!v) ||
            "Contact Person is required",
        ],
        ContactDesignation: [
          (v) =>
            (this.formData.Category === "Foreign" && !!v) ||
            "Designation is required",
        ],
        ContactEmail: [
          (v) =>
            (this.formData.Category === "Foreign" && !!v) ||
            "Email is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        contactPersonAddress: [
          (v) =>
            (this.formData.Category === "Foreign" && !!v) ||
            "Address is required",
        ],
        ContactCountry: [
          (v) =>
            (this.formData.Category === "Foreign" && !!v) ||
            "Country is required",
        ],
        File: [
          (v) => {
            return this.formData.Cert === ""
              ? !!v || "Attachment is required"
              : true;
          },

          (v) => {
            return v
              ? (!!v && v.size < 10000000) ||
                  "Attachment should be less than 10MB"
              : true;
          },

          (v) => {
            return v
              ? (!!v && v.type.includes("application/pdf")) ||
                  "Attachment must be a Pdf"
              : true;
          },
        ],
      };
    },

    otherQualificationHeaders() {
      return this.headers.filter((el) => el.text !== "Locale");
    },

    years() {
      let arr = [];
      for (let i = 1900; i <= new Date().getFullYear(); i++) {
        arr.push(i);
      }
      return arr;
    },

    certifications() {
      return this.$store.getters["Profile/certifications"];
    },

    accreditedPrograms() {
      if (this.formData.UniversityCode !== "") {
        let programs = this.universities
          .filter((u) => u.code === this.formData.UniversityCode)
          .shift();
        return programs ? programs.accreditedPrograms : [];
      } else {
        return this.universities.map((u) => u.accreditedPrograms).flat();
      }
    },

    specializationCaption() {
      if (this.minorSettings) {
        return this.minorSettings.organisation_caption === "EBK"
          ? "i.e Civil Engineering"
          : "e.g Bachelor of Arts";
      } else {
        return "";
      }
    },
    //get local & foreign uni
    localUniversities() {
      return this.universities.filter((el) => el.foreign === false);
    },
    foreignUniversities() {
      return this.universities.filter((el) => el.foreign === true);
    },
  },

  methods: {
    clearForm: function () {
      this.$refs.engineeringForm.reset();
      this.edit = false;
      this.dialog = false;
    },

    update: function (payload) {
      this.formData = { ...payload };
      this.edit = true;
      this.dialog = true;
    },

    remove(item) {
      this.$confirm.show({
        title: "Delete this Certification?",
        text: `Are you sure you want to delete?`,
        onConfirm: () => {
          this.$store.dispatch("Profile/removeCertification", { ...item });
        },
      });
    },

    save: function () {
      let isValid = true;
      for (const key in this.formData) {
        if (this.$refs[key])
          isValid = isValid ? this.$refs[key].validate(true) : false;
      }

      if (isValid) {
        if (this.formData.UniversityCode === "OTHER") {
          delete this.formData.UniversityCode;
        }
        this.$store.dispatch(
          `Profile/${this.edit ? "updateCertification" : "addCertification"}`,
          helper.prepareFormData({
            ...this.formData,
            transcript: JSON.stringify([...this.formData.transcript]),
            ProfileID: AuthService.user.profile.ProfileID,
            EntryNo: this.formData.EntryNo
              ? this.formData.EntryNo
              : this.certifications.length + 1, //TODO Confirm Generation of Entry Nos.
          })
        );

        this.clearForm();
      }
    },

    addTranscript: function () {
      let reader = new FileReader();
      let data = { ...this.transcriptForm };

      reader.onloadend = () => {
        data.attachment = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        this.formData.transcript.push(data);
      };

      reader.readAsDataURL(this.transcriptForm.attachment);

      this.transcriptForm = {
        description: "",
        attachment: "",
      };
    },

    removeTranscript: function (script) {
      let index = this.formData.transcript.indexOf(script);
      this.formData.transcript.splice(index, 1);
    },

    openDialog: function (params) {
      this.formData.Category = params.locale;
      this.formData.Type = params.type;
      if (!this.edit) {
        this.formData.transcript = [];
      }
      this.dialog = true;
    },
  },
};
</script>

<style scoped></style>

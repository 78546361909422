<template>
  <v-container>
    <v-sheet>
      <v-toolbar dense elevation="0">
        <v-toolbar-title class="subtitle-2 text-capitalize">
          My Subscriptions
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-divider />
      <router-view name="view" />
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: "Subscriptions",
};
</script>

<style scoped></style>

import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":false,"transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"rounded":"","icon":""}},'v-btn',attrs,false),on),[_c(VBadge,{attrs:{"content":_vm.notification.messages.length + _vm.application.length,"value":_vm.notification.messages.length + _vm.application.length,"color":"error","overlap":""}},[_c(VIcon,[_vm._v("mdi-bell")])],1)],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,{attrs:{"min-width":"400"}},[_c(VTabs,{attrs:{"grow":""}},[_c(VTab,[_vm._v(" Alerts "),_c(VBadge,{attrs:{"content":_vm.application.length,"value":_vm.application.length,"color":"warning","overlap":""}},[_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-bell")])],1)],1),_c(VTabItem,[_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,_vm._l((_vm.application),function(alert,i){return _c(VListItem,{key:i,attrs:{"two-line":"","to":{ name: alert.action }}},[_c(VListItemAction,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-alert-circle-outline")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Action required for "+_vm._s(alert.Code ? ("Application: " + (alert.Code)) : ("Subscription: " + (alert.documentNo)))+" "+_vm._s(alert.Code ? ("  (" + (_vm.formatDate( alert.membershipComments[ alert.membershipComments.length - 1 ].commentDate )) + ")") : "")+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(alert.Code ? "" : "Amount:  ")+" "),(alert.Code)?_c('span',[_vm._v(" "+_vm._s(alert.membershipComments[ alert.membershipComments.length - 1 ].comment)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(alert.billedAmount))+" ")])])],1)],1)}),1)],1)],1),_c(VTabItem,[_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,_vm._l((_vm.notification.messages),function(message,i){return _c(VListItem,{key:i,attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(message.title))]),_c(VListItemSubtitle,[_vm._v(_vm._s(message.message))])],1),_c(VListItemAction,{staticClass:"caption"},[_vm._v(" "+_vm._s(message.time)+" ")])],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import DashboardLayout from "../../layouts/dashboard/DashboardLayout";
import Application from "./views/Application";
import ApplicationList from "./components/ApplicationList";
import ApplicationDocument from "./components/ApplicationDocument";
import Certificate from "./components/Certificate";
import RejectionLetter from "./components/RejectionLetter";
import AcceptanceLetter from "./components/AcceptanceLetter";
import Auth from "../../router/middleware/Auth";

export default [
  {
    path: "/application",
    component: DashboardLayout,
    children: [
      {
        path: "",
        components: { view: Application },
        children: [
          {
            path: "",
            name: "ApplicationList",
            components: { view: ApplicationList },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
          {
            path: "document/:code",
            name: "applicationDocument",
            components: { view: ApplicationDocument },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
          {
            path: "certificate/:code",
            name: "Certificate",
            components: { view: Certificate },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
          {
            path: "rejectionLetter/:code",
            name: "RejectionLetter",
            components: { view: RejectionLetter },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
          {
            path: "acceptanceLetter/:code",
            name: "AcceptanceLetter",
            components: { view: AcceptanceLetter },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
        ],
      },
    ],
  },
];

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"mt-5"},[_c('alert'),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.bankers,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,{staticClass:"text-h6"},[_vm._v("Bankers")]),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VSpacer),_c(VDialog,{attrs:{"persistent":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mb-2",attrs:{"outlined":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Add Banker ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"orange lighten-5"},[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.edit ? "Edit" : "Add")+" Bankers ")])]),_c(VCardText,{staticClass:"mt-10"},[_c(VContainer,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c(VTextField,{attrs:{"outlined":"","placeholder":"Add Banker Name","label":"Banker Name","dense":"","rules":_vm.rules.name},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c(VTextField,{attrs:{"outlined":"","placeholder":"Add Address","label":"Address","dense":"","rules":_vm.rules.address},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.clearForm}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.update(item)}}},[_vm._v(" mdi-pencil ")]),_c(VIcon,{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.remove(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import call from "../../../../service/http";
import bankerConstants from "./bankersConstants";

export default {
  state: {
    bankers: [],
  },
  mutations: {
    SET_BANKERS: (state, payload) => {
      state.bankers = payload;
    },
  },
  getters: {
    bankers: (state) => state.bankers,
  },
  actions: {
    getBankers: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("get", bankerConstants.bankers)
        .then((res) => {
          commit("SET_BANKERS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    addBanker: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", bankerConstants.bankers, data)
        .then(() => {
          dispatch("getBankers");
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    removeBanker: ({ commit, dispatch }, payload) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", bankerConstants.remove, payload)
        .then(() => {
          dispatch("getBankers");
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    updateBanker: ({ dispatch, commit }, payload) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", bankerConstants.update, payload)
        .then(() => {
          dispatch("getBankers");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};

import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height d-flex flex-row align-center",staticStyle:{"background-color":"rgba(255, 255, 255, 0.65)"},attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"md":"4","sm":"12","offset-md":"4"}},[_c(VCard,{staticClass:"pa-5"},[_c(VImg,{staticClass:"mb-5",attrs:{"src":("data:image/png;base64," + (_vm.$store.getters['Setup/companyInformation'].logo)),"height":"100","contain":""}}),_c(VCardTitle,{staticClass:"text-h4 d-flex flex-row justify-center"},[_vm._v(" SET PASSWORD ")]),_c(VCardSubtitle,{staticClass:"text-center"},[_vm._v(" Set a new password ")]),_c(VForm,{ref:"ResetPassform",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VCardText,{staticClass:"text-justify mt-4 pb-0"},[(_vm.$store.getters['Auth/alert'].status)?_c(VAlert,{staticClass:"mb-10",attrs:{"outlined":"","dense":"","border":"left","type":_vm.$store.getters['Auth/alert'].status === 'success'
                  ? 'success'
                  : 'error'}},[_vm._v(" "+_vm._s(_vm.$store.getters["Auth/alert"].message)+" ")]):_vm._e(),_c(VTextField,{ref:"password",attrs:{"dense":"","outlined":"","type":"password","label":"New Password","placeholder":"Enter a secure password","rules":_vm.rules.password},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c(VTextField,{ref:"confirmPassword",attrs:{"dense":"","outlined":"","type":"password","label":"Confirm Password","placeholder":"Enter Password again","rules":_vm.rules.confirmPassword},model:{value:(_vm.formData.confirmPassword),callback:function ($$v) {_vm.$set(_vm.formData, "confirmPassword", $$v)},expression:"formData.confirmPassword"}})],1),_c(VCardActions,{},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"color":"primary","block":""},on:{"click":_vm.reset}},[_vm._v(" Reset ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-btn text small @click="$router.back()">
          <v-icon left>mdi-arrow-left</v-icon>
          Back
        </v-btn>
        <v-spacer />
        Referee Form Report
        <v-spacer />

        <v-btn
          @click="downloadFile(document, `${$route.params.code}.pdf`)"
          small
          color="secondary"
        >
          <v-icon left>mdi-download</v-icon>
          Download
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text v-for="i in pageCount(document)" :key="i">
        <pdf
          :src="`data:application/pdf;base64,${document}`"
          :page="i"
          style="width: 100%"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import pdf from "vue-pdf";
import FileMixin from "../../../mixins/FileMixin";
export default {
  name: "RefereeFormReport",
  mixins: [FileMixin],
  components: { pdf },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Profile/getRefereeFormDoc", v.$route.params.entryNo);
    });
  },
  computed: {
    document() {
      return this.$store.getters["Profile/profileGetters"]("report");
    },
  },
};
</script>

import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{staticClass:"mx-auto",attrs:{"flat":""}},[_c(VCardTitle,[_c('span',[_vm._v("Admin Settings")])]),_c(VCardText,[_c(VForm,{ref:"settings",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VRow,[_c(VCol,{staticClass:"mt-n3 mb-n3",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"text-body-1 font-weight-bold"},[_c('span',[_vm._v("App Color Settings")])]),_c(VDivider)],1),_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.colors),function(item,i){return _c(VMenu,{key:i,attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({key:i,staticClass:"mx-2 my-2",attrs:{"color":item.color,"label":""}},'v-chip',attrs,false),on),[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(((item.colorName) + " : " + (item.color))))])])]}}],null,true),model:{value:(item.menu),callback:function ($$v) {_vm.$set(item, "menu", $$v)},expression:"item.menu"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Pick a color")]),_c(VCardText,[_c(VColorPicker,{attrs:{"value":item.color,"mode":"hexa","show-swatches":"","swatches-max-height":"300px"},on:{"input":function($event){return _vm.updateColor($event, item)}}})],1)],1)],1)}),1)])],1),_c(VRow,[_c(VCol,{staticClass:"mt-n3",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"text-body-1 font-weight-bold"},[_c('span',[_vm._v("Minor Settings")])]),_c(VDivider,{staticClass:"pb-3"})],1),_c(VCol,{staticClass:"mt-n3",attrs:{"cols":"12","md":"12"}},[_c('span',{staticClass:"text-primary font-italic"},[_vm._v(" Login Section ")]),_c(VDivider)],1),_c(VCol,{staticClass:"mt-n3",attrs:{"cols":"12","md":"4"}},[_c(VTextField,{attrs:{"label":"Login caption","dense":"","outlined":"","clearable":"","hint":"login caption"},model:{value:(_vm.settingsFormData.minor_settings.login_caption),callback:function ($$v) {_vm.$set(_vm.settingsFormData.minor_settings, "login_caption", $$v)},expression:"settingsFormData.minor_settings.login_caption"}})],1),_c(VCol,{staticClass:"mt-n3",attrs:{"cols":"12","md":"4"}},[_c(VTextField,{attrs:{"label":"Organisation caption","dense":"","outlined":"","clearable":"","hint":"organisation caption"},model:{value:(_vm.settingsFormData.minor_settings.organisation_caption),callback:function ($$v) {_vm.$set(_vm.settingsFormData.minor_settings, "organisation_caption", $$v)},expression:"settingsFormData.minor_settings.organisation_caption"}})],1),_c(VCol,{staticClass:"mt-n6",attrs:{"cols":"12","md":"4"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VSwitch,{attrs:{"label":"logout user after inactivity","inset":"","dense":""},model:{value:(
                      _vm.settingsFormData.minor_settings['inactivityLogout']
                    ),callback:function ($$v) {_vm.$set(_vm.settingsFormData.minor_settings, 'inactivityLogout', $$v)},expression:"\n                      settingsFormData.minor_settings['inactivityLogout']\n                    "}})],1)]}}])},[_c('span',[_vm._v("Inactivity Logout")])])],1),_c(VCol,{staticClass:"mt-n6",attrs:{"cols":"12","md":"4"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VSwitch,{attrs:{"label":"Registration Function","inset":"","dense":""},model:{value:(_vm.settingsFormData.minor_settings['registration']),callback:function ($$v) {_vm.$set(_vm.settingsFormData.minor_settings, 'registration', $$v)},expression:"settingsFormData.minor_settings['registration']"}})],1)]}}])},[_c('span',[_vm._v("Add registration button on login page")])])],1),_c(VCol,{staticClass:"mt-n3",attrs:{"cols":"12","md":"12"}},[_c('span',{staticClass:"text-primary font-italic"},[_vm._v(" License Section ")]),_c(VDivider)],1),_c(VCol,{staticClass:"mt-n3",attrs:{"cols":"12","md":"4"}},[_c(VTextField,{attrs:{"label":"License alternative caption","dense":"","outlined":"","clearable":"","hint":"License alternative caption"},model:{value:(
                _vm.settingsFormData.minor_settings.license_alternative_caption
              ),callback:function ($$v) {_vm.$set(_vm.settingsFormData.minor_settings, "license_alternative_caption", $$v)},expression:"\n                settingsFormData.minor_settings.license_alternative_caption\n              "}})],1),_c(VCol,{staticClass:"mt-n3",attrs:{"cols":"12","md":"12"}},[_c('span',{staticClass:"text-primary font-italic"},[_vm._v(" Profile Section ")]),_c(VDivider)],1),_c(VCol,{staticClass:"mt-n6",attrs:{"cols":"12","md":"4"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VSwitch,{attrs:{"label":"Referee Disclaimer","inset":"","dense":""},model:{value:(
                      _vm.settingsFormData.minor_settings['referee_disclaimer']
                    ),callback:function ($$v) {_vm.$set(_vm.settingsFormData.minor_settings, 'referee_disclaimer', $$v)},expression:"\n                      settingsFormData.minor_settings['referee_disclaimer']\n                    "}})],1)]}}])},[_c('span',[_vm._v("Referee Disclaimer")])])],1),_c(VCol,{staticClass:"mt-n3",attrs:{"cols":"12","md":"4"}},[_c(VTextarea,{attrs:{"outlined":"","auto-grow":"","disabled":!_vm.settingsFormData.minor_settings['referee_disclaimer'],"rows":"1","color":"blue-grey lighten-2","multiple":"","label":"Referee disclaimer caption","hint":"Caption will be added as a disclaimer when user is adding referee"},model:{value:(
                _vm.settingsFormData.minor_settings.referee_disclaimer_caption
              ),callback:function ($$v) {_vm.$set(_vm.settingsFormData.minor_settings, "referee_disclaimer_caption", $$v)},expression:"\n                settingsFormData.minor_settings.referee_disclaimer_caption\n              "}})],1),_c(VCol,{staticClass:"mt-n6",attrs:{"cols":"12","md":"3"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VSwitch,{attrs:{"label":" Move County Field to Bio Data Page","inset":"","dense":""},model:{value:(_vm.settingsFormData.minor_settings['countyField']),callback:function ($$v) {_vm.$set(_vm.settingsFormData.minor_settings, 'countyField', $$v)},expression:"settingsFormData.minor_settings['countyField']"}})],1)]}}])},[_c('span',[_vm._v("County Field")])])],1),_c(VCol,{staticClass:"mt-n6",attrs:{"cols":"12","md":"3"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VSwitch,{attrs:{"label":"Practicing Firm field","inset":"","dense":""},model:{value:(
                      _vm.settingsFormData.minor_settings['practicing_firm']
                    ),callback:function ($$v) {_vm.$set(_vm.settingsFormData.minor_settings, 'practicing_firm', $$v)},expression:"\n                      settingsFormData.minor_settings['practicing_firm']\n                    "}})],1)]}}])},[_c('span',[_vm._v("Practicing Firm field")])])],1),_c(VCol,{staticClass:"mt-n6",attrs:{"cols":"12","md":"3"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VSwitch,{attrs:{"label":"show Work Place","inset":"","dense":""},model:{value:(_vm.settingsFormData.minor_settings['showWorkPlace']),callback:function ($$v) {_vm.$set(_vm.settingsFormData.minor_settings, 'showWorkPlace', $$v)},expression:"settingsFormData.minor_settings['showWorkPlace']"}})],1)]}}])},[_c('span',[_vm._v("show Work Place")])])],1),_c(VCol,{staticClass:"mt-n3",attrs:{"cols":"12","md":"12"}},[_c('span',{staticClass:"text-primary font-italic"},[_vm._v(" Navbar section ")]),_c(VDivider)],1),_c(VCol,{staticClass:"mt-n1",attrs:{"cols":"12","md":"12"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VAutocomplete,{attrs:{"items":_vm.items,"outlined":"","chips":"","dense":"","color":"blue-grey lighten-2","label":"Selected Links","item-text":"title","item-value":"title","multiple":"","hint":"Selected Links will be displayed on the portal\n                    side"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c(VChip,_vm._b({attrs:{"input-value":data.selected,"close":"","small":""},on:{"click":data.select,"click:close":function($event){return _vm.remove(data.item)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.title)+" ")])]}}],null,true),model:{value:(_vm.settingsFormData.site_installed_packages),callback:function ($$v) {_vm.$set(_vm.settingsFormData, "site_installed_packages", $$v)},expression:"settingsFormData.site_installed_packages"}})],1)]}}])},[_c('span',[_vm._v("Select Links to Display")])])],1),_c(VCol,{staticClass:"mt-n6",attrs:{"cols":"12","md":"3"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VSwitch,{attrs:{"label":"Certificate of Good Standing","inset":"","dense":""},model:{value:(
                      _vm.settingsFormData.minor_settings[
                        'certificate_of_good_standing'
                      ]
                    ),callback:function ($$v) {_vm.$set(_vm.settingsFormData.minor_settings, 
                        'certificate_of_good_standing'
                      , $$v)},expression:"\n                      settingsFormData.minor_settings[\n                        'certificate_of_good_standing'\n                      ]\n                    "}})],1)]}}])},[_c('span',[_vm._v("Enable/Disable Good Standing Letter")])])],1),_c(VCol,{staticClass:"mt-n3",attrs:{"cols":"12","md":"3"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","disabled":!_vm.settingsFormData.minor_settings[
                  'certificate_of_good_standing'
                ],"rows":"1","label":"Certificate of Good Standing caption"},model:{value:(
                _vm.settingsFormData.minor_settings[
                  'certificate_of_good_standing_caption'
                ]
              ),callback:function ($$v) {_vm.$set(_vm.settingsFormData.minor_settings, 
                  'certificate_of_good_standing_caption'
                , $$v)},expression:"\n                settingsFormData.minor_settings[\n                  'certificate_of_good_standing_caption'\n                ]\n              "}})],1),_c(VCol,{staticClass:"mt-n3",attrs:{"cols":"12","md":"3"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VTextField,{attrs:{"outlined":"","dense":"","rows":"1","label":"Acceptance Letter Caption"},model:{value:(
                      _vm.settingsFormData.minor_settings[
                        'acceptance_letter_caption'
                      ]
                    ),callback:function ($$v) {_vm.$set(_vm.settingsFormData.minor_settings, 
                        'acceptance_letter_caption'
                      , $$v)},expression:"\n                      settingsFormData.minor_settings[\n                        'acceptance_letter_caption'\n                      ]\n                    "}})],1)]}}])},[_c('span',[_vm._v("Acceptance Letter Caption")])])],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.saveSettings}},[_vm._v("Save")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-row>
    <!--Checklist-->
    <v-col sm="12" md="12" class="p-5">
      <v-row>
        <v-col md="12">
          <v-data-table :headers="headers" :items="checklistItems">
            <template v-slot:item.checkListType="{ item }">
              <td>
                <h4 class="heading">{{ item.checkListType }}</h4>
                <span class="subtitle-2 grey--text">{{
                  item.description
                }}</span>
              </td>
            </template>

            <template v-slot:item.pass="{ item }">
              <td>
                <v-icon :color="item.pass ? 'success' : 'warning'">
                  {{ item.pass ? "mdi-check" : "mdi-alert" }}
                </v-icon>
              </td>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                small
                :to="{
                  name: 'component',
                  params: { name: item.checkListType },
                }"
                color="secondary"
              >
                Complete
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6">
          <v-btn @click="back" depressed>
            <v-icon>mdi-arrow-left</v-icon>Back
          </v-btn>
        </v-col>
        <v-col sm="6" class="text-right">
          <v-btn
            :disabled="!checklistPass"
            @click="next"
            :color="!checklistPass ? 'grey' : 'primary'"
            depressed
          >
            Continue
            <v-icon> mdi-arrow-right </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "Checklist",
  props: { next: { type: Function }, back: { type: Function } },
  data() {
    return {};
  },

  mounted() {
    Event.$on("Checklist", (finish) => {
      if (this.checklistPass) finish();
    });
  },

  computed: {
    checklistItems() {
      return this.$store.getters["License/licenseGetters"]("checklist");
    },

    headers() {
      return [
        {
          text: "Item",
          align: "start",
          sortable: false,
          value: "checkListType",
        },
        { text: "Status", value: "pass" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },

    checklistPass() {
      let pass = true;
      this.checklistItems.forEach((check) => {
        if (!check.pass) pass = false;
      });

      return pass;
    },
  },
};
</script>

<template>
  <div>
    <div v-if="isField">
      <v-autocomplete
        :items="specializationTypes"
        :item-text="(item) => item.description"
        :item-value="(item) => item.code"
        label="Specialization"
        placeholder="Select your specialization"
        persistent-placeholder
        outlined
        v-model="singleSpecialization"
        dense
        v-if="editable"
      />
      <v-list-item dense v-else two-line>
        <v-list-item-content>
          <v-list-item-title class="overline grey--text font-weight-light"
            >Specialization</v-list-item-title
          >
          <v-list-item-subtitle>{{
            mySpecializations.length > 0 ? mySpecializations[0].Description : ""
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>
    <div v-else>
      <v-container>
        <v-card class="mx-auto">
          <v-card-title class="text-lg-h6">My specializations</v-card-title>
          <alert />
          <v-card-text>
            <v-chip-group column disabled>
              <v-chip
                v-for="(spec, i) in mySpecializations"
                :key="i"
                ripple
                close
                @click="pickedSpecialization(spec)"
                @click:close="removeSpecialization(spec)"
                color="primary"
                label
                large
                class="mr-2 mb-2"
              >
                <span class="text-truncate">
                  {{ spec.Description }}
                </span>
              </v-chip>
            </v-chip-group>

            <v-divider class="my-4" />

            <v-row no-gutters>
              <v-col cols="12">
                <v-textarea
                  outlined
                  :rules="rules.description"
                  counter
                  ref="addingSpecialization"
                  label="Add Specialization"
                  placeholder="Type to add specialization"
                  v-model="formData.description"
                  v-on:keyup.enter="addSpecialization(formData)"
                  append-outer-icon="mdi-plus-circle-outline"
                  @click:append-outer="addSpecialization(formData)"
                />
              </v-col>

              <v-col cols="12">
                <v-chip
                  v-for="(spec, i) in types"
                  :key="i"
                  ripple
                  labels
                  class="mr-2 mb-2"
                  @click="addSpecialization(spec)"
                >
                  {{ spec.description }}
                </v-chip>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>

        <v-dialog persistent v-model="dialog" width="50%">
          <v-card elevation="0">
            <v-card-title class="primary">
              {{ edit ? "Edit" : "Add" }} Specialization
            </v-card-title>

            <v-card-text class="mt-10">
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.Code"
                    disabled
                    ref="Code"
                    @input="
                      (val) => (formData.Code = formData.Code.toUpperCase())
                    "
                    dense
                    outlined
                  >
                    <template v-slot:label>
                      <div>Code</div>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="profile.Discipline"
                    disabled
                    type="text"
                    ref="Discipline"
                    dense
                    outlined
                  >
                    <template v-slot:label>
                      <div>Discipline</div>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-textarea
                    auto-grow
                    rows="1"
                    counter
                    v-model="formData.Description"
                    ref="Description"
                    type="text"
                    dense
                    outlined
                    :rules="rules.Description"
                  >
                    <template v-slot:label>
                      <div>Description <span class="red--text">*</span></div>
                    </template>
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="clearForm()">Cancel</v-btn>
              <v-btn color="primary" @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </div>
  </div>
</template>

<script>
import SetupMixin from "../../../setup/SetupMixin";
import Alert from "../../../../components/Alert";
import { AuthService } from "../../../auth";

export default {
  name: "Specialization",
  components: { Alert },
  mixins: [SetupMixin],
  props: {
    isField: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      mySpecializations: [],
      edit: true,
      dialog: false,
      formData: {
        Code: "",
        Description: "",
        Discipline: AuthService.user.profile.Discipline,
      },
    };
  },

  mounted() {
    this.getSetups(["getSpecializationTypes"]);
    this.$store.dispatch("Profile/getSpecializations");
  },

  computed: {
    specializations() {
      return this.$store.getters["Profile/specializations"];
    },

    singleSpecialization: {
      get() {
        return this.mySpecializations.length > 0
          ? this.mySpecializations[0].Code
          : "";
      },
      set(val) {
        this.addSpecField(val);
      },
    },

    types() {
      return this.specializationTypes
        .filter((spec) => {
          return (
            this.mySpecializations.find(
              (a) => a.Description === spec.description
            ) === undefined
          );
        })
        .filter((spec) => {
          return spec.discipline === AuthService.user.profile.Discipline;
        });
    },

    rules: function () {
      return {
        description: [(v) => (!!v && v.length < 100) || "Max 100 characters"],
      };
    },

    profile() {
      return AuthService.user.profile;
    },
  },

  methods: {
    addSpecialization: function (spec) {
      if (Object.keys(spec).length !== 0)
        if (
          this.mySpecializations.find(
            ({ Description }) => Description === spec.description
          ) === undefined
        ) {
          this.mySpecializations.push({
            Code: spec.code,
            Description: spec.description,
            Discipline: spec.discipline,
            ProfileID: AuthService.user.profile_id,
          });
        }

      if (this.formData === spec)
        this.formData = {
          Code: "",
          Description: "",
          Discipline: AuthService.user.profile.Discipline,
        };
    },

    addSpecField: function (val) {
      let spec = this.specializationTypes.find((a) => a.code === val);
      spec = {
        Code: spec.code,
        Description: spec.description,
        Discipline: AuthService.user.profile.Discipline,
        ProfileID: AuthService.user.profile_id,
      };
      // replace existing specialization
      if (this.mySpecializations.length > 0) {
        const mySpec = this.specializations[0];
        if (mySpec) {
          spec.lineNo = mySpec.lineNo;
          spec.EntryNo = mySpec.EntryNo;
        }
        this.mySpecializations.splice(0, 1, spec);
      } else {
        this.mySpecializations.push(spec);
      }
    },

    removeSpecialization: function (spec) {
      this.mySpecializations.splice(this.mySpecializations.indexOf(spec), 1);
    },

    pickedSpecialization: function (spec) {
      this.formData = spec;
      this.dialog = true;
    },

    save: function () {
      this.$store.dispatch("Profile/submitSpecializations", [
        ...this.mySpecializations,
      ]);

      this.dialog = false;
    },

    saveSpecField: function () {
      if (this.isField) {
        if (this.specializations.length > 0) {
          this.$store.dispatch("Profile/patchSpecialization", {
            ...this.mySpecializations[0],
          });
        } else {
          this.$store.dispatch("Profile/submitSpecializations", [
            ...this.mySpecializations,
          ]);
        }
      }
    },

    clearForm: function () {
      this.formData = {
        Code: "",
        Description: "",
        Discipline: "",
      };

      this.dialog = false;
    },
  },

  watch: {
    specializations: {
      handler: function () {
        this.mySpecializations = [...this.specializations];
      },
    },
  },
};
</script>

<style scoped></style>

<template>
  <v-dialog v-model="dialog" persistent width="60%">
    <v-card class="mx-auto">
      <v-card-title>
        <span class="headline">Update User Details</span>
        <v-spacer />
        <v-btn icon color="red" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="userForm" v-model="isValid">
          <v-container>
            <v-text-field
              v-model="userFormData.email"
              :rules="[rules.email, rules.required]"
              label="User Email"
              dense
              outlined
              prepend-inner-icon="mdi-email"
              clearable
            />

            <v-text-field
              v-model="userFormData.phone_no"
              :rules="[rules.required]"
              label="User Phone Number"
              dense
              outlined
              prepend-inner-icon="mdi-phone"
              clearable
            />
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          @click="saveUser"
          :disabled="$store.getters['loader/loading']"
          :loading="$store.getters['loader/loading']"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "AdminUserDialog",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    Event.$on("close-dialog", () => {
      this.close();
    });
  },
  data: function () {
    return {
      isValid: false,
      userFormData: {
        phone_no: "",
        email: "",
      },
    };
  },
  computed: {
    rules() {
      return {
        required: (v) => !!v || "Required.",
        email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      };
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    saveUser() {
      if (!this.isValid) {
        this.$refs.userForm.validate();
      } else {
        this.$confirm.show({
          title: "Update User",
          text: "Are you sure you want to update this user?",
          onConfirm: () => {
            this.$store.dispatch("Admin/updateUser", { ...this.userFormData });
          },
        });
      }
    },
  },
  watch: {
    user: {
      handler: function (val) {
        this.userFormData = val;
      },
      deep: true,
    },
  },
};
</script>

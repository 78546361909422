<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    style="background-color: rgba(255, 255, 255, 0.65)"
  >
    <v-row>
      <v-col md="10" offset-md="1" sm="12">
        <v-card class="pa-0" elevation="0">
          <v-card-title class="d-flex flex-row justify-center">
            Select Membership Category
          </v-card-title>

          <v-tabs centered slider-color="primary">
            <v-tab @click="type = 'Individual'"> Individual </v-tab>
            <v-tab @click="type = 'Corporate'"> Firm </v-tab>
          </v-tabs>

          <v-tabs-items v-model="type">
            <v-tab-item :value="type || null">
              <v-container class="mt-10">
                <v-row class="d-flex d-sm-flex flex-row justify-center">
                  <v-col
                    md="4"
                    sm="12"
                    xs="12"
                    class="d-flex"
                    v-for="(category, i) in membershipCategories
                      .filter((c) => c.categoryType === this.type)
                      .filter((c) => c.CanApply)"
                    :key="i"
                  >
                    <v-hover v-slot="{ hover }" open-delay="100">
                      <v-card
                        class="pa-5"
                        width="100%"
                        :elevation="hover ? 20 : 2"
                        :class="{ 'on-hover': hover }"
                      >
                        <v-card-title class="d-flex flex-row justify-center">
                          <p class="wrap">
                            {{ category.Description }}
                          </p>
                        </v-card-title>
                        <v-card-subtitle
                          class="overline text-center py-0 secondary--text"
                        >
                          {{
                            applicationCharge(category.charges)
                              ? applicationCharge(category.charges)
                                  .Currency_Code
                              : ""
                          }}
                          {{
                            applicationCharge(category.charges)
                              ? applicationCharge(category.charges)
                                  .Amount_Including_VAT
                              : 0 | currencyFormat
                          }}
                        </v-card-subtitle>
                        <v-card-text
                          class="d-flex flex-row justify-center font-weight-medium"
                        >
                          <!-- {{ category.Description }} -->
                        </v-card-text>
                        <v-card-actions class="d-flex flex-row justify-center">
                          <v-btn
                            :to="{
                              name: 'signup',
                              params: { type: type, code: category.Code },
                            }"
                            dark
                            color="primary"
                            block
                          >
                            Select
                            <v-icon right>mdi-arrow-right</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SetupMixin from "../../setup/SetupMixin";

export default {
  name: "Categories",
  mixins: [SetupMixin],
  data: function () {
    return {
      type: "Individual",
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.getSetups(["getMembershipCategories"]);
    });
  },

  methods: {
    applicationCharge: function (charges) {
      return charges
        .filter((charge) => {
          return (charge.Charge_Type = "Application");
        })
        .shift();
    },
  },
};
</script>

<style scoped>
.wrap {
  display: inline-block;
  word-break: keep-all;
}
</style>

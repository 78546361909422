import DashboardLayout from "../../layouts/dashboard/DashboardLayout";
import Subscriptions from "./views/Subscriptions";
import SubscriptionList from "./components/SubscriptionList";
import SubscriptionCard from "./components/SubscriptionCard";
import SubscriptionDocument from "./components/SubscriptionDocument";
import SubCertificate from "./components/SubCertificate";
import Auth from "../../router/middleware/Auth";

export default [
  {
    path: "/subscription",
    component: DashboardLayout,
    children: [
      {
        path: "",
        components: { view: Subscriptions },
        children: [
          {
            path: "",
            name: "subscriptionList",
            components: { view: SubscriptionList },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
          {
            path: "card/:code?",
            name: "subscriptionCard",
            components: { view: SubscriptionCard },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
          {
            path: "document/:code",
            name: "subscriptionDocument",
            components: { view: SubscriptionDocument },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
          {
            path: "certificate",
            name: "SubCertificate",
            components: { view: SubCertificate },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
        ],
      },
    ],
  },
];

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height d-flex flex-row align-center",staticStyle:{"background-color":"rgba(255, 255, 255, 0.65)"},attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"md":"6","sm":"12","offset-md":"3"}},[_c(VCard,[_c(VCardText,[_c(VIcon,{staticStyle:{"font-size":"150px"},attrs:{"color":"secondary"}},[_vm._v("mdi-check-circle")]),_c('h1',{staticClass:"text-center mb-3 font-weight-light",staticStyle:{"font-size":"40px"}},[_vm._v(" Success ")]),_c('p',{staticClass:"mt-5 subtitle-1"},[_vm._v(" Your account has been created, "),_c('br'),_vm._v("an email with an activation link has been sent to your inbox. Make sure to check you junk folder also for the email. ")]),_c(VBtn,{staticClass:"mt-5",attrs:{"to":{ name: 'login' },"x-large":"","color":"secondary"}},[_vm._v("Login")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
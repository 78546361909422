import AuthLayout from "../../layouts/auth/AuthLayout";
import Login from "./components/Login";
import Activate from "./components/Activate";
import Signup from "./components/Signup";
import Categories from "./components/Categories";
import Register from "./components/Register";
import Initiate from "./components/Initiate";
import InitiateFirm from "./components/InitiateFirm";
import Success from "./components/Success";
import Verify from "./components/Verify";
import ForgotPassword from "./components/ForgotPassword";
import Password from "./components/Password";
import Auth from "../../router/middleware/Auth";
import otp from "./components/otp";

export default [
  {
    path: "/",
    component: AuthLayout,
    children: [
      {
        path: "",
        name: "login",
        component: Login,
      },
      {
        path: "/forgot-password",
        name: "forgotPassword",
        component: ForgotPassword,
      },
      {
        path: "/password/:code",
        name: "setPassword",
        component: Password,
      },
      {
        path: "/activate/:code?",
        name: "activate",
        component: Activate,
      },
      {
        path: "/signup/:type/:code",
        name: "signup",
        component: Signup,
      },
      {
        path: "categories",
        name: "categories",
        component: Categories,
      },
      {
        path: "register/:type/:code",
        name: "register",
        component: Register,
      },
      {
        path: "success",
        name: "success",
        component: Success,
      },
      {
        path: "verify/:code",
        name: "verify",
        component: Verify,
      },
      {
        path: "otp/:id",
        name: "Otp",
        component: otp,
      },
      {
        path: "initiate-profile",
        name: "initiate",
        component: Initiate,
        meta: { middleware: [Auth], breadcrumb: "Dashboard" },
      },
      {
        path: "initiate-firm",
        name: "initiateFirm",
        component: InitiateFirm,
        meta: { middleware: [Auth], breadcrumb: "Dashboard" },
      },
    ],
  },
];

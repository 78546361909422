import call from "../../service/http";
import CpdConstants from "./CpdConstants";

export default {
  namespaced: true,
  state: {
    cpd: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
  },
  getters: {
    cpdGetters: (state) => (val) => state[val],
  },
  actions: {
    getCPDAWARDS: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("get", CpdConstants.CPD_AWARDS(data))
        .then((res) => {
          commit("MUTATE", { state: "cpd", data: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};

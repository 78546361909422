import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{staticClass:"pa-5",attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"d-flex flex-row justify-center"},[_vm._v(" Available Upgrade Plan ")]),_c(VCardText,{staticClass:"mt-10"},[_c(VRow,_vm._l((_vm.categories),function(category,i){return _c(VCol,{key:i,staticClass:"d-flex",attrs:{"md":"4","cols":"12"}},[_c(VCard,{staticClass:"pa-5",attrs:{"disabled":category.disabled}},[_c(VCardTitle,{staticClass:"d-flex flex-row justify-center"},[_vm._v(_vm._s(category.name))]),_c(VCardSubtitle,{staticClass:"overline text-center py-0 secondary--text"},[_vm._v(" KES "+_vm._s(category.amount)+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(category.description)+" ")]),_c(VCardActions,{staticClass:"d-flex flex-row justify-center"},[_c(VBtn,{attrs:{"to":{
                  name: 'listing',
                  params: { type: category.name },
                },"dark":"","color":"primary","block":""}},[_vm._v(" Select "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
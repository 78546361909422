import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VAppBar,{attrs:{"app":"","dark":"","color":"primary"}},[_c(VImg,{staticClass:"shrink mr-2",attrs:{"alt":"Logo","contain":"","src":("data:image/png;base64," + (_vm.companyInformation.logo)),"transition":"scale-transition","width":"50"},on:{"click":function($event){return _vm.$router.push('/')}}}),_c(VSpacer),_c(VToolbarTitle,{staticClass:"overline mr-2"},[_vm._v(_vm._s(_vm.title))]),_c(VSpacer),_c(VBtn,{attrs:{"to":{ name: 'search' },"text":"","color":"white","light":""}},[_c('span',{staticClass:"mr-2"},[_vm._v("Member Checker")]),_c(VIcon,[_vm._v("mdi-account-search")])],1),(_vm.auth.check())?_c(VBtn,{staticClass:"ml-2",attrs:{"text":"","color":"white","light":""},on:{"click":function($event){return _vm.$store.dispatch('Auth/logout')}}},[_c('span',{staticClass:"mr-2"},[_vm._v("Logout")]),_c(VIcon,[_vm._v("mdi-logout")])],1):_vm._e()],1),_c(VMain,{class:_vm.$route.name === 'forgotPassword' && _vm.$vuetify.breakpoint.mdAndBelow
        ? 'mb-16'
        : ''},[_c('router-view')],1),_c('spinner'),_c('Footer',{attrs:{"opacity":"0.65"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container>
    <v-sheet>
      <v-toolbar dense elevation="0">
        <v-toolbar-title class="subtitle-2">Profile</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-divider />
      <v-container>
        <v-tabs v-model="tab" active-class="orange lighten-5">
          <v-tab v-show="user.type !== 'Corporate'"> Bio </v-tab>

          <v-tab v-show="user.type === 'Corporate'"> Firm Details </v-tab>
          <v-tab v-show="user.type === 'Corporate'"> Directors </v-tab>
          <v-tab v-show="user.type === 'Corporate'"> Shareholders </v-tab>
          <v-tab v-show="user.type === 'Corporate'"> Services </v-tab>
          <v-tab v-show="user.type === 'Corporate'"> Firm Employees </v-tab>
          <v-tab v-show="user.type === 'Corporate'">
            Equipment/Facilities
          </v-tab>
          <v-tab v-show="user.type === 'Corporate'"> Banker Details </v-tab>
          <v-tab v-show="user.type !== 'Corporate'"> Contacts </v-tab>
          <v-tab v-show="user.type !== 'Corporate'">
            Academic Qualifications
          </v-tab>
          <v-tab v-show="user.type !== 'Corporate'">
            Institutional Membership
          </v-tab>
          <v-tab v-show="user.type !== 'Corporate'"> Work Experience </v-tab>
          <v-tab v-show="user.type === 'Individual'"> Referees </v-tab>
          <v-tab>Attachments </v-tab>
          <v-tab
            v-show="
              user.type === 'Individual' &&
              minorSettings.organisation_caption !== 'EBK'
            "
          >
            Specialization
          </v-tab>
          <!--          <v-tab>Conviction</v-tab>-->

          <v-tab-item> <bio /> </v-tab-item>
          <v-tab-item> <firmbio /> </v-tab-item>
          <v-tab-item> <director /> </v-tab-item>
          <v-tab-item> <shareholder /> </v-tab-item>
          <v-tab-item> <services /> </v-tab-item>
          <v-tab-item> <employee /> </v-tab-item>
          <v-tab-item> <facility /> </v-tab-item>
          <v-tab-item> <banker /> </v-tab-item>
          <v-tab-item> <contact /></v-tab-item>
          <v-tab-item> <certification /> </v-tab-item>
          <v-tab-item> <institution /> </v-tab-item>
          <v-tab-item> <experience /> </v-tab-item>
          <v-tab-item v-if="user.type === 'Individual'">
            <referee />
          </v-tab-item>
          <v-tab-item> <attachments /> </v-tab-item>
          <v-tab-item v-if="minorSettings.organisation_caption !== 'EBK'">
            <specialization />
          </v-tab-item>
          <!--          <v-tab-item> <conviction /> </v-tab-item>-->
        </v-tabs>
      </v-container>
    </v-sheet>
  </v-container>
</template>

<script>
import { AuthService } from "../../auth";
import Bio from "../components/bio/Bio";
import Contact from "../components/contact/Contact";
import Certification from "../components/certification/Certification";
import Experience from "../components/experience/Experience";
import Referee from "../components/referee/Referee";
import Institution from "../components/institution/Institution";
// import Conviction from "../components/conviction/Conviction";
import Specialization from "../components/specialization/Specialization";
import Attachments from "../components/attachment/Attachments";
/** firm details */
import Firmbio from "../components/firmbio/Firmbio.vue";
import Shareholder from "../components/shareholders/Shareholder.vue";
import Director from "../components/directors/Director.vue";
import Facility from "../components/facilities/Facility.vue";
import Employee from "../components/employees/Employee.vue";
import Banker from "../components/bankers/Banker.vue";
import Services from "../components/services/Services.vue";
export default {
  name: "Profile",
  components: {
    Attachments,
    Specialization,
    // Conviction,
    Institution,
    Referee,
    Experience,
    Certification,
    Contact,
    Bio,
    /** firm Components */
    Firmbio,
    Shareholder,
    Director,
    Facility,
    Employee,
    Banker,
    Services,
  },
  data: function () {
    return {
      tab: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (v.user.type === "Corporate") {
        v.tab = 1;
      }
      v.$store.dispatch("Admin/getSettings");
    });
  },
  computed: {
    user() {
      return AuthService.user;
    },
    minorSettings() {
      const settings = this.$store.getters["Admin/adminGetters"]("settings");
      return settings.minor_settings
        ? JSON.parse(settings.minor_settings)
        : false;
    },
  },

  watch: {
    tab: {
      handler: function () {
        this.$store.commit("Profile/SET_ALERT", null);
      },
    },
  },
};
</script>

<style scoped></style>

import Vue from "vue";
import VueRouter from "vue-router";
import { AuthRoutes } from "../modules/auth";
import { DashboardRoutes } from "../modules/dashboard";
import { SearchRoutes } from "../modules/search";
import { ProfileRoutes } from "../modules/profile";
import { CheckListRoutes } from "../modules/checklist";
import { ApplicationRoutes } from "../modules/application";
import { CpdRoutes } from "../modules/cpd";
import { UpgradeRoutes } from "../modules/upgrade";
import { SubscriptionRoutes } from "../modules/subscriptions";
import { LicenseRoutes } from "../modules/license";
import { BillingRoutes } from "../modules/billing";
import { EventsRoutes } from "../modules/events";
import { AdminRoutes } from "../modules/admin";
import { PaymentHistoryRoutes } from "../modules/payment-history";
import RefereeResponseRoute from "../modules/response/RefereeResponseRoute";
import Fallback from "../layouts/Fallback";
import nextFactory from "./middleware/MiddlewareFactory";

Vue.use(VueRouter);

const routes = [
  ...AuthRoutes,
  ...DashboardRoutes,
  ...SearchRoutes,
  ...ProfileRoutes,
  ...CheckListRoutes,
  ...ApplicationRoutes,
  ...SubscriptionRoutes,
  ...UpgradeRoutes,
  ...LicenseRoutes,
  ...CpdRoutes,
  ...BillingRoutes,
  ...EventsRoutes,
  ...AdminRoutes,
  ...PaymentHistoryRoutes,
  ...RefereeResponseRoute,
  {
    path: "*",
    component: Fallback,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  }
  return next();
});

export default router;

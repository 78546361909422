import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VSheet,[_c(VContainer,[_c(VTabs,{attrs:{"active-class":"orange lighten-5","active-tab":_vm.tab},on:{"update:activeTab":function($event){_vm.tab=$event},"update:active-tab":function($event){_vm.tab=$event}},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-account-outline")]),_vm._v(" License details/General ")],1),_c(VTab,{attrs:{"disabled":_vm.tab < 1}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-notebook-check-outline ")]),_vm._v(" Checklist ")],1),_c(VTab,{attrs:{"disabled":_vm.tab < 2}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-notebook-outline ")]),_vm._v(" Declaration ")],1),_c(VTabItem,[_c('licenseDetails',{attrs:{"next":_vm.next,"back":_vm.back}})],1),_c(VTabItem,{attrs:{"disabled":_vm.tab < 1}},[_c('checklist',{attrs:{"next":_vm.next,"back":_vm.back}})],1),_c(VTabItem,{attrs:{"disabled":_vm.tab < 2}},[_c('declaration',{attrs:{"next":_vm.next,"back":_vm.back}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
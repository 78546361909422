<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    style="background-color: rgba(255, 255, 255, 0.95)"
  >
    <v-row>
      <v-col v-if="profile.ProfileID" md="8" offset-md="2" sm="12">
        <v-card class="pa-5" elevation="0">
          <v-row>
            <v-col md="4" sm="12">
              <v-card elevation="0">
                <v-img
                  v-if="company === 'EBK'"
                  :src="'/img/avatar.jpg'"
                  height="200px"
                  class="rounded"
                  contain
                ></v-img>

                <v-img
                  v-else
                  :src="
                    profile.base64Image
                      ? `data:image/png;base64,${profile.base64Image}`
                      : '/img/avatar.jpg'
                  "
                  height="200px"
                  class="rounded"
                  contain
                ></v-img>
                <v-card-subtitle class="text-uppercase text-center">
                  {{
                    `${getProfileTitle(profile.profileTitle)} ${
                      profile.FirstName
                    } ${profile.SecondName} ${profile.LastName} `
                  }}
                </v-card-subtitle>
              </v-card>
            </v-col>

            <v-col md="8" sm="12">
              <v-card>
                <v-card-title>Membership Details</v-card-title>
                <v-card-text>
                  <v-simple-table dense v-slot:default>
                    <tbody v-if="profile.memberInformation">
                      <tr v-if="profile.memberInformation.length > 0">
                        <td>Membership Status</td>
                        <td>
                          <v-chip
                            small
                            :color="
                              profile.memberInformation[0].Status === 'Active'
                                ? 'green'
                                : 'deep-orange'
                            "
                          >
                            {{ profile.memberInformation[0].Status }}
                          </v-chip>
                        </td>
                      </tr>

                      <!-- <tr v-if="profile.memberInformation.length > 0">
                        <td>License No.</td>
                        <td>{{ profile.memberInformation[0].No }}</td>
                      </tr> -->

                      <tr v-if="profile.memberInformation.length > 0">
                        <td>Member No.</td>
                        <td>{{ profile.memberInformation[0].No }}</td>
                      </tr>

                      <tr>
                        <td>Member Category</td>
                        <td>{{ profile.MemberShipType }}</td>
                      </tr>

                      <tr>
                        <td>Discipline</td>
                        <td>{{ profile.discipline }}</td>
                      </tr>

                      <tr
                        v-if="
                          profile.contactsEntity.length > 0 &&
                          minorSettings.showWorkPlace
                        "
                      >
                        <td>WorkPlace</td>
                        <td>{{ getCurrentWorkPlace() }}</td>
                      </tr>

                      <tr v-if="profile.contactsEntity.length > 0">
                        <td>Address</td>
                        <td>{{ profile.contactsEntity[0].Address }}</td>
                      </tr>
                      <tr>
                        <td>Gender</td>
                        <td>{{ profile.Gender }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>

              <v-card class="mt-5">
                <v-card-title>Specialization</v-card-title>

                <v-chip
                  color="primary"
                  class="ma-2"
                  v-for="(spec, i) in profile.specialization"
                  :key="i"
                >
                  {{ spec.Description }}
                </v-chip>
              </v-card>

              <!-- <v-card class="mt-5">
                <v-card-title>Practise status & CPD</v-card-title>
                <v-simple-table v-slot:default>
                  <thead>
                    <tr>
                      <th>Year</th>
                      <th>Status</th>
                      <th>CPD Points</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(subs, i) in profile.subscriptions" :key="i">
                      <td>{{ subs.subsPeriod }}</td>
                      <td>{{ subs.status }}</td>
                      <td>{{ subs.cpdAwarded }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card> -->
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col v-else md="8" offset-md="2" sm="12">
        <v-skeleton-loader
          type="table-heading, list-item-two-line, image, table-tfoot"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RouterMixin from "../../../router/RouterMixin";
export default {
  name: "SearchCard",
  mixins: [RouterMixin],
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch(
        "Search/getProfile",
        v.decodeRoute(v.$route.params.code)
      );
      v.$store.dispatch("Admin/getSettings");
    });
  },
  data() {
    return {
      company: process.env.VUE_APP_ORGANIZATION,
    };
  },
  computed: {
    profile() {
      return this.$store.getters["Search/profile"];
    },
    settings() {
      return this.$store.getters["Admin/adminGetters"]("settings");
    },
    minorSettings() {
      return this.settings.minor_settings
        ? JSON.parse(this.settings.minor_settings)
        : "";
    },
  },

  methods: {
    getProfileTitle(profileTitle) {
      return profileTitle
        ? profileTitle.length > 0
          ? profileTitle.map((el) => el.title).join(", ")
          : ""
        : "";
    },
    getCurrentWorkPlace() {
      let currentWorkPlace = this.profile.workexp;
      if (currentWorkPlace !== undefined) {
        currentWorkPlace = currentWorkPlace.find((el) => el.Current);
        return currentWorkPlace !== undefined
          ? currentWorkPlace.NameOfFirm
          : "";
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped></style>

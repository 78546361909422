import call from "../../../../service/http";
import ConvictionConstants from "./ConvictionConstants";

export default {
  state: {
    convictions: [],
  },
  mutations: {
    SET_CONVICTIONS: (state, payload) => {
      state.convictions = payload;
    },
  },
  getters: {
    convictions: (state) => state.convictions,
  },
  actions: {
    getConvictions: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("get", ConvictionConstants.convictions)
        .then((res) => {
          commit("SET_CONVICTIONS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    addConviction: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", ConvictionConstants.convictions, data)
        .then(() => {
          dispatch("getConvictions");
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    removeConviction: ({ commit, dispatch }, payload) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", ConvictionConstants.remove, payload)
        .then(() => {
          dispatch("getConvictions");
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    updateConviction: ({ dispatch, commit }, payload) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", ConvictionConstants.update, payload)
        .then(() => {
          dispatch("getConvictions");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pb-12",attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"text-h5 justify-center member-text"},[_vm._v("Upgrade plans")]),_c('div',{staticClass:"d-flex justify-center flex-wrap mx-2"},_vm._l((_vm.upgrades),function(upgrade,i){return _c(VCard,{key:i,staticClass:"mx-auto mb-3 pa-2",attrs:{"width":"45%","outlined":""}},[_c(VCardTitle,{staticClass:"text-h5 justify-center mt-3 member-text"},[_vm._v("Upgrade to "),_c('div',{staticClass:"ml-2 primary--text"},[_vm._v(" "+_vm._s(upgrade.ToCategoryDescription)+" ")])]),_c(VCardTitle,{staticClass:"text-h5 justify-center mt-n6"},[_c('div',{staticClass:"currency mt-n3"},[_vm._v("KES")]),_c('div',{staticClass:"amount-text"},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(upgrade.Amount))+" ")])]),_c(VCardTitle,{staticClass:"text-h8 justify-center mt-n6 secondary--text"},[_vm._v("Requirements")]),_c('div',{staticClass:"requirement"},[_c('p',[_vm._v(" 1. Complete the checklist with the necessary requirements ( e.g Eduction:Final certified copies of diploma or degree in, Referees etc..) ")]),_c('p',[_vm._v("2. Payment (Make sure payment is fully paid)")])]),_c(VCardActions,{staticClass:"pa-3"},[_c(VBtn,{attrs:{"to":{
            name: 'UpgradeChecklist',
            params: { code: upgrade.ToCategory },
          },"rounded":"","block":"","color":"primary"}},[_vm._v(" Select ")])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
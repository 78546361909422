export default [
  {
    order: 0,
    title: "Admin Settings",
    icon: "settings",
    to: "/admin/settings",
    type: "admin",
  },
  {
    order: 1,
    title: "User Settings",
    icon: "people",
    to: "/admin/user-settings",
    type: "clientadmin",
  },
];

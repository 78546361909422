<template>
  <v-footer
    app
    :inset="true"
    elevation="12"
    color="white"
    class="overline mt-10"
  >
    <span style="color: #000000">
      {{ $store.getters["Setup/companyInformation"].displayName }} @
      {{ new Date().getFullYear() }}
    </span>
    <v-spacer />
    <a
      class="text-decoration-none blue--text"
      href="https://kinetics.co.ke/"
      target="_blank"
    >
      Kinetics Technology LTD
    </a>
  </v-footer>
</template>

<script>
import { appName } from "../environment/environment";

export default {
  name: "Footer",
  props: {
    opacity: {
      type: String,
      default: "1",
    },
  },

  computed: {
    color() {
      return `rgba(255, 255, 255, ${this.opacity})`;
    },

    appName() {
      return appName;
    },
  },
};
</script>

<style scoped></style>

import call from "../../../../service/http";
import InstitutionConstant from "./InstitutionConstant";

export default {
  state: {
    institutions: [],
  },
  mutations: {
    SET_INSTITUTIONS: (state, payload) => {
      state.institutions = payload;
    },
  },
  getters: {
    institutions: (state) => state.institutions,
  },
  actions: {
    getInstitutions: ({ commit }) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", InstitutionConstant.institution)
        .then((res) => {
          commit("SET_INSTITUTIONS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    addInstitutions: ({ commit, dispatch }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", InstitutionConstant.institution, data)
        .then(() => {
          dispatch("getInstitutions");
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    removeInstitutions: ({ commit, dispatch }, payload) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", InstitutionConstant.remove, payload)
        .then(() => {
          dispatch("getInstitutions");
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    updateInstitutions: ({ dispatch, commit }, payload) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", InstitutionConstant.update, payload)
        .then(() => {
          dispatch("getInstitutions");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};

import call from "../../service/http";
import constants from "./SubscriptionConstants";
// import { EventBus } from "../../utils/eventBus";

export default {
  namespaced: true,
  state: {
    alert: {
      status: "",
      message: "",
    },
    subscriptions: [],
    proforma: "",
    receipt: "",
    certificate: "",
  },
  mutations: {
    SET_SUBSCRIPTION: (state, payload) => {
      state.subscriptions = payload;
    },
    SET_CERTIFICATE: (state, payload) => {
      state.certificate = payload;
    },

    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
  },
  getters: {
    subscriptions: (state) => state.subscriptions,
    certificate: (state) => state.certificate,
  },
  actions: {
    getSubs: ({ commit }) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", constants.SUBS)
        .then((res) => {
          commit("SET_SUBSCRIPTION", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    getProforma: ({ commit }, code) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", constants.SUBS_PROFORMA(code))
        .then((res) => {
          commit("MUTATE", { state: "proforma", payload: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    getReceipt: ({ commit }, code) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", constants.SUBS_RECEIPT(code))
        .then((res) => {
          commit("MUTATE", { state: "receipt", payload: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    getCertificate: ({ commit }, code) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", constants.SUBS_CERTIFICATE, code)
        .then((res) => {
          commit("SET_CERTIFICATE", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};

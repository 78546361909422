import DashboardLayout from "../../layouts/dashboard/DashboardLayout";
import Profile from "./views/Profile";
import Auth from "../../router/middleware/Auth";
import profileConfirm from "./components/ProfileConfirm";
import ResponseCard from "./components/ResponseCard.vue";
import RefereeFormReport from "./components/RefereeFormReport.vue";

export default [
  {
    path: "/profile",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "profile",
        components: { view: Profile },
        meta: { middleware: [Auth], breadcrumb: "Dashboard" },
      },
      {
        path: "profileConfirm",
        name: "profileConfirm",
        components: { view: profileConfirm },
        meta: { middleware: [Auth] },
      },
      {
        path: "response/:user?/:code?",
        name: "ResponseCard",
        components: { view: ResponseCard },
        meta: {
          middleware: [Auth],
          breadcrumb: "Dashboard",
        },
      },
      {
        path: "RefereeFormReport/:entryNo",
        name: "RefereeFormReport",
        components: { view: RefereeFormReport },
        meta: {
          middleware: [Auth],
          breadcrumb: "Dashboard",
        },
      },
    ],
  },
];

import BioStore from "./components/bio/BioStore";
import FirmBioStore from "./components/firmbio/firmbioStore";
import ContactsStore from "./components/contact/ContactsStore";
import CertificationStore from "./components/certification/CertificationStore";
import WorkExperienceStore from "./components/experience/WorkExperienceStore";
import RefereesStore from "./components/referee/RefereesStore";
import SpecializationStore from "./components/specialization/SpecializationStore";
import InstitutionStore from "./components/institution/InstitutionStore";
import ConvictionStore from "./components/conviction/ConvictionStore";
import AttachmentStore from "./components/attachment/AttachmentStore";
import call from "../../service/http";
import ProfileConstants from "./ProfileConstants";

/** firm store */
import shareholderStore from "./components/shareholders/shareholderStore";
import directorStore from "./components/directors/directorStore";
import employeeStore from "./components/employees/employeeStore";
import bankerStore from "./components/bankers/bankersStore";
import facilityStore from "./components/facilities/facilityStore";
import serviceStore from "./components/services/serviceStore";

export default {
  namespaced: true,
  modules: {
    BioStore,
    FirmBioStore,
    ContactsStore,
    CertificationStore,
    WorkExperienceStore,
    RefereesStore,
    SpecializationStore,
    ConvictionStore,
    InstitutionStore,
    AttachmentStore,
    /** firm store */
    shareholderStore,
    directorStore,
    employeeStore,
    bankerStore,
    facilityStore,
    serviceStore,
  },
  state: {
    alert: {
      status: "",
      message: "",
    },
    profile: {},
    user: {},
    cpd: {},
  },
  mutations: {
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    alert: (state) => state.alert,

    profileGetters: (state) => (val) => state[val],
  },
  actions: {
    getCPDpoints: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("get", ProfileConstants.cpd(data))
        .then((res) => {
          commit("MUTATE", { state: "cpd", data: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
    updateProfile: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", ProfileConstants.update, data)
        .then(() => {
          commit("SET_ALERT", {
            status: "success",
            message: "Confirmed Successfully",
          });
          commit("SET_LOADING", false, { root: true });
          dispatch("Auth/user", null, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};

<template>
  <v-card>
    <v-card-title>
      Invoice
      <v-spacer />
      <v-btn v-if="proforma" @click="download" color="warning" depressed>
        Download
        <v-icon>mdi-cloud-download</v-icon>
      </v-btn>
      <v-btn
        v-if="service && service.receiptNo === ''"
        @click="pay"
        depressed
        class="ml-2"
        color="primary"
      >
        Pay Now
        <v-icon>mdi-cash</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <pdf
          style="width: 100%"
          :src="`data:application/pdf;base64,${proforma}`"
        />
      </v-row>
    </v-card-text>
    <Checkout />
  </v-card>
</template>

<script>
import pdf from "vue-pdf";
import Checkout from "@kinetics254/paygate/src/views/Checkout.vue";

export default {
  name: "LicenseInvoice",
  components: { pdf, Checkout },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("License/getLicence", v.$route.params.code);
      v.$store.dispatch("License/getProforma", v.$route.params.code);
    });
  },
  computed: {
    proforma() {
      return this.$store.getters["License/licenseGetters"]("proforma");
    },

    service() {
      let service = this.$store.getters["License/licenseGetters"](
        "licenses"
      ).filter((s) => {
        return s.licenseApplicationNo === this.$route.params.code;
      });
      return service.pop();
    },
  },
  methods: {
    download: function () {
      let path = `data:application/pdf;base64,${this.proforma}`;
      Event.$emit("downloadfile", { code: `Proforma`, path });
    },

    pay: function () {
      let payload = {
        payerID: this.service.profileID,
        sourceDocumentNo: this.service.licenseApplicationNo,
        description: "License fees",
        amount: this.service.amount,
        accountNo: "",
        success_url: `${process.env.VUE_APP_URL}/${
          this.$router.resolve({ name: "LicenseList" }).href
        }`,
        fail_url: window.location.href,
      };

      payload = btoa(JSON.stringify(payload));
      // window.location.href = `${process.env.VUE_APP_PAYGATE_CHECKOUT_URL}/?payload=${payload}`;
      this.$checkout.pay(payload);
    },
  },
};
</script>

<style scoped></style>

import call from "../../../../service/http";
import shareholderConstants from "./shareholderConstants";

export default {
  state: {
    shareholders: [],
  },
  mutations: {
    SET_SHAREHOLDERS: (state, payload) => {
      state.shareholders = payload;
    },
  },
  getters: {
    shareholders: (state) => state.shareholders,
  },
  actions: {
    getShareholders: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("get", shareholderConstants.shareholders)
        .then((res) => {
          commit("SET_SHAREHOLDERS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    addShareholder: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", shareholderConstants.shareholders, data)
        .then(() => {
          dispatch("getShareholders");
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    removeShareholder: ({ commit, dispatch }, payload) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", shareholderConstants.remove, payload)
        .then(() => {
          dispatch("getShareholders");
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    updateShareholder: ({ dispatch, commit }, payload) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", shareholderConstants.update, payload)
        .then(() => {
          dispatch("getShareholders");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};

export default {
  referees: `profile/referee`,
  ADD_REFEREES: `profile/referee/add`,
  update: `profile/referee/update`,
  remove: `profile/referee/remove`,
  SEND_EMAIL: "profile/referee/send_email",
  SEARCH_MEMBER: "profile/referee/search",
  GET_REFEREE_FORM: (id) => `questions?memberNo=${id.user}&code=${id.code}`,
  REFEREE_FORM_REPORT: (id) => `questions/refereeForm/${id}`,
  UPDATE_REFEREE: "questions/update",
};

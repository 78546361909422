<template>
  <v-container>
    <v-card>
      <!-- <v-card-title>
      </v-card-title>
      <v-divider /> -->
      <v-card-text>
        <div class="px-16">
          <p v-if="referee">
            Dear
            <strong>{{ referee.FirstName + " " + referee.LastName }}</strong>
            ,
          </p>

          <p v-if="showAll === false">
            {{
              data.ConfirmationStatement
                ? data.ConfirmationStatement.confirmationStatement.substring(
                    0,
                    300
                  ) + " ..."
                : ""
            }}
            <span
              class="blue--text text-caption"
              style="cursor: pointer"
              @click="readMore(true)"
              >Read more</span
            >
          </p>
          <p v-if="showAll === true">
            {{
              data.ConfirmationStatement
                ? data.ConfirmationStatement.confirmationStatement
                : ""
            }}
            <span
              class="blue--text text-caption"
              style="cursor: pointer"
              @click="readMore(false)"
              >Less</span
            >
          </p>
        </div>

        <v-stepper v-model="el" alt-labels flat v-if="questions">
          <v-stepper-header>
            <template v-for="(item, index) in questions">
              <v-stepper-step
                :complete="el > index"
                :step="index + 1"
                :key="index"
              >
                Question {{ item.questionNo }}
              </v-stepper-step>
              <!-- <v-divider
                v-if="index < questions ? questions.length - 1 : -1"
                :key="index"
              /> -->
            </template>
          </v-stepper-header>

          <v-stepper-items>
            <template v-for="(item, index) in questions">
              <v-stepper-content :key="index" :step="index + 1">
                <v-form v-model="isValid">
                  <v-card>
                    <v-card-title>
                      <v-row class="d-flex">
                        <v-col cols="2" class="text-overline">Question:</v-col>
                        <v-col class="text-subtitle-2">
                          <strong>{{ item.question }}</strong>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text class="mt-6">
                      <v-row>
                        <v-col cols="2" class="text-overline font-weight-bold"
                          >Answer:</v-col
                        >
                        <v-col>
                          <v-textarea
                            v-model="item.answer"
                            :rules="rules.required"
                            :ref="`refereeFormField${index + 1}`"
                            outlined
                            label="write your response here"
                            hint="Max 250 characters"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-form>
              </v-stepper-content>
            </template>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-divider />
      <v-card-actions v-if="questions">
        <v-spacer />
        <v-btn
          color="error"
          @click="previous(el)"
          v-if="el > 1"
          class="mr-7"
          small
        >
          Previous
        </v-btn>
        <v-btn
          class="mr-7"
          color="error"
          small
          elevation="0"
          @click="rejectionDialogBox = true"
          v-if="el === 1"
        >
          reject
        </v-btn>
        <v-btn
          v-if="el < questions.length"
          color="green"
          @click="nextEl(el)"
          dark
          small
        >
          Next
        </v-btn>
        <v-btn
          class=""
          color="green"
          small
          dark
          elevation="0"
          @click="accept"
          v-if="el === questions.length"
        >
          accept
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="rejectionDialogBox" persistent max-width="800px">
      <v-card>
        <v-card-title>Rejection Remarks</v-card-title>
        <v-divider />
        <v-card-text class="mt-6 px-16">
          <v-form v-model="isValid" ref="rejectForm">
            <v-row>
              <v-col cols="12">
                <v-textarea
                  dense
                  filled
                  label="Rejection Remark"
                  placeholder="Remark"
                  v-model="rejectFormData.Remarks"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="green" elevation="0" dark @click="reject">submit</v-btn>
          <v-btn
            color="error"
            elevation="0"
            dark
            @click="rejectionDialogBox = false"
            >cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  name: "ResponseCard",
  data() {
    return {
      rejectionDialogBox: false,
      isValid: false,
      el: 1,
      rejectFormData: {
        Remarks: "",
        response: "Reject",
      },
      showAll: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      const data = {
        user: v.decodeRoute(v.$route.query.user),
        code: v.decodeRoute(v.$route.query.code),
      };
      v.$store.dispatch("Profile/getRefereeForm", data);
      v.$store.dispatch("Profile/getReferees");
    });
  },
  mounted() {
    Event.$on("routeBack", () => {
      this.$router.replace({ path: "/" });
    });
  },
  computed: {
    rules() {
      return {
        required: [(v) => !!v || "field is required"],
      };
    },
    data() {
      return this.$store.getters["Profile/profileGetters"]("profile");
    },
    referee() {
      return this.data ? this.data.referee : null;
    },
    applicant() {
      return this.data ? this.data.applicant : null;
    },
    questions() {
      return this.applicationReferee
        ? this.applicationReferee.refereeResponse
        : null;
    },
    applicationReferee() {
      return this.data ? this.data.Questions : null;
    },
  },
  methods: {
    decodeRoute: function (param) {
      return atob(param);
    },
    accept: function () {
      this.applicationReferee.response = "Accept";
      let data = { ...this.applicationReferee };
      this.$store.dispatch("Profile/acceptRefereeRequest", data);
    },
    reject: function () {
      if (!this.isValid) {
        this.$refs.rejectForm.validate();
      } else {
        let data = { ...this.applicationReferee, ...this.rejectFormData };
        this.$store.dispatch("Profile/rejectRefereeRequest", data);
      }
    },
    nextEl(number) {
      if (this.$refs[`refereeFormField${number}`][0].validate()) {
        this.el = number + 1;
      } else {
        // this.$store.dispatch("");
        // Event.$emit("ApiWarning", "Answer must be filled");
      }
    },
    previous(number) {
      this.el = number - 1;
    },
    readMore(value) {
      this.showAll = value;
    },
  },
};
</script>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{staticClass:"my-5",attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(" License Application "),_c(VSpacer),(_vm.document)?_c(VBtn,{attrs:{"small":"","depressed":"","color":"secondary"},on:{"click":_vm.download}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Download ")],1):_vm._e(),(_vm.license && _vm.license.licenseBase64 !== '')?_c(VBtn,{staticClass:"ml-2",attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'LicenseInvoice',
            params: { code: _vm.$route.params.code },
          })}}},[_c('span',[_c(VIcon,[_vm._v("mdi-cash")]),_vm._v(" Invoice ")],1)]):_vm._e()],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c('pdf',{staticStyle:{"width":"100%"},attrs:{"src":("data:application/pdf;base64," + _vm.document)}})],1),(_vm.license && _vm.license.licenseBase64 === '')?_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('h5',[_vm._v(" Download the application to sign/stamp then upload the scanned pdf document ")]),_c('div',{staticClass:"border"},[_c('div',[_c('input',{ref:"file",attrs:{"id":"file","type":"file","accept":"application/pdf"},on:{"change":function($event){return _vm.handleFileUpload()}}})]),_c('span',[_vm._v(" "+_vm._s(_vm.formData.file_name))]),_c(VBtn,{staticClass:"mt-3",attrs:{"disabled":_vm.formData.file === '',"block":"","small":"","color":"primary"},on:{"click":_vm.uploadFile}},[_vm._v(" Upload Application "),_c(VIcon,[_vm._v("mdi-arrow-right")])],1)],1)]):_vm._e()],1)],1)],1),_c('Checkout')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
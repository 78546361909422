<template>
  <v-container>
    <v-card class="pa-5" elevation="0">
      <v-card-title class="d-flex flex-row justify-center">
        Available Upgrade Plan
      </v-card-title>

      <v-card-text class="mt-10">
        <v-row>
          <v-col
            md="4"
            cols="12"
            class="d-flex"
            v-for="(category, i) in categories"
            :key="i"
          >
            <v-card class="pa-5" :disabled="category.disabled">
              <v-card-title class="d-flex flex-row justify-center">{{
                category.name
              }}</v-card-title>
              <v-card-subtitle
                class="overline text-center py-0 secondary--text"
              >
                KES {{ category.amount }}
              </v-card-subtitle>
              <v-card-text> {{ category.description }} </v-card-text>
              <v-card-actions class="d-flex flex-row justify-center">
                <v-btn
                  :to="{
                    name: 'listing',
                    params: { type: category.name },
                  }"
                  dark
                  color="primary"
                  block
                >
                  Select
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "UpgradePlans",
  computed: {
    categories() {
      return [
        {
          code: "PE",
          name: "Professional Engineer",
          amount: "11000",
          type: "individual",
          description: "Professional Engineer",
          disabled: false,
        },
        {
          code: "CE",
          name: "Consulting Engineer",
          amount: "100,000",
          type: "individual",
          description: "Consulting Engineer",
          disabled: true,
        },
        {
          code: "AC",
          name: "Accredited Checker",
          amount: "11000",
          type: "individual",
          description: "Accredited Checker Engineer",
          disabled: true,
        },
      ];
    },
  },
};
</script>

<style scoped></style>

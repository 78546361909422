import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{staticClass:"app-back"},[_c(VAppBar,{attrs:{"app":"","dark":"","color":"primary"}},[_c(VImg,{staticClass:"shrink mr-2",attrs:{"alt":"Logo","contain":"","src":("data:image/png;base64," + (_vm.companyInformation.logo)),"transition":"scale-transition","width":"50"},on:{"click":function($event){return _vm.$router.push('/')}}}),_c(VSpacer),(_vm.$route.name !== 'search')?_c(VBtn,{attrs:{"to":{ name: 'search' },"text":"","color":"white","light":""}},[_c('span',{staticClass:"mr-2"},[_vm._v("Back")]),_c(VIcon,[_vm._v("mdi-account-search")])],1):_vm._e(),_c(VBtn,{staticClass:"ml-2",attrs:{"to":{ name: 'login' },"text":"","color":"white","light":""}},[_c('span',{staticClass:"mr-2"},[_vm._v("My Account")]),_c(VIcon,[_vm._v("mdi-arrow-right")])],1)],1),_c(VMain,[_c('router-view')],1),_c(VBtn,{directives:[{def: Scroll,name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"}],staticStyle:{"margin-bottom":"3%"},attrs:{"fab":"","dark":"","fixed":"","bottom":"","right":"","color":"primary"},on:{"click":_vm.toTop}},[_c(VIcon,[_vm._v("mdi-arrow-up")])],1),_c('spinner'),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
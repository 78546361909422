import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"350"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h6 text-capitalize"},[_vm._v(" "+_vm._s(_vm.params.title)+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.params.text)+" ")]),_c(VCardText,[(_vm.params.input)?_c(VTextarea,{attrs:{"placeholder":_vm.params.label},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}):_vm._e()],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"grey","text":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.params.rejectLabel || "No")+" ")]),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.confirm}},[_vm._v(" "+_vm._s(_vm.params.acceptLabel || "Yes")+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import SearchLayout from "../../layouts/search/SearchLayout";
import SearchList from "./components/SearchList";
import SearchCard from "./components/SearchCard";

export default [
  {
    path: "/search",
    component: SearchLayout,
    children: [
      {
        path: "Search-list",
        name: "search",
        component: SearchList,
      },
      {
        path: "search-card/:code",
        name: "searchCard",
        component: SearchCard,
      },
    ],
  },
];

import DashboardLayout from "../../layouts/dashboard/DashboardLayout.vue";
import Events from "./views/Events";
import EventList from "./components/eventList.vue";
import Auth from "../../router/middleware/Auth";

export default [
  {
    path: "/events",
    component: DashboardLayout,
    children: [
      {
        path: "",
        components: { view: Events },
        children: [
          {
            path: "",
            name: "EventList",
            components: { view: EventList },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
        ],
      },
    ],
  },
];

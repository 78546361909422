<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <v-card class="my-5">
          <v-card-title
            ><v-btn text small @click="$router.back()">
              <v-icon left>mdi-arrow-left</v-icon>
              Back
            </v-btn>
            <v-spacer />
            <v-btn
              small
              depressed
              color="secondary"
              @click="
                downloadFile(document, `ACCEPTANCE_LETTER-${currentDate()}.pdf`)
              "
            >
              <v-icon left>mdi-download</v-icon>
              Download
            </v-btn>
          </v-card-title>

          <v-card-text
            class="elevation-1 mb-1"
            v-for="i in pageCount(document)"
            :key="i"
          >
            <pdf
              style="width: 100%"
              :src="`data:application/pdf;base64,${document}`"
              :page="i"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import pdf from "vue-pdf";
import FileMixin from "../../../mixins/FileMixin";
import DateMixin from "../../../mixins/DateMixin";
export default {
  name: "acceptanceLetter",
  components: { pdf },
  mixins: [FileMixin, DateMixin],
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Upgrade/getAcceptanceLetter", {
        appCode: v.$route.params.code,
      });
    });
  },
  computed: {
    document() {
      return this.$store.getters["Upgrade/upgradeGetters"]("acceptanceLetter");
    },
  },
};
</script>

<style scoped></style>

import paymentHistoryConstants from "./paymentHistoryConstants";
import call from "../../service/http";

export default {
  namespaced: true,
  state: {
    history: [],
  },
  mutations: {
    SET_HISTORY: (state, payload) => {
      state.history = payload;
    },
  },
  getters: {
    history: (state) => state.history,
  },
  actions: {
    getHistory: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", paymentHistoryConstants.history)
        .then((res) => {
          commit("SET_HISTORY", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
  },
};

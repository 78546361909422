import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VOtpInput } from 'vuetify/lib/components/VOtpInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height d-flex flex-row align-center",staticStyle:{"background-color":"rgba(255, 255, 255, 0.65)"},attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"md":"6","sm":"12","offset-md":"3"}},[_c(VCard,{attrs:{"loading":_vm.$store.getters['loading']}},[_c(VCardText,{staticClass:"pa-5"},[_c('h1',{staticClass:"text-center mb-3 font-weight-light",staticStyle:{"font-size":"40px"}},[_vm._v(" Verify OTP code ")]),_c('p',{staticClass:"mt-5 subtitle-1"},[_vm._v(" OTP Code has been sent to your Mobile No. ("+_vm._s(_vm.testPhoneMask( JSON.parse(_vm.decodeRoute(this.$route.params.id)).phone_no ) || "")+") or check your mail for an activation link ")]),(_vm.$store.getters['Auth/alert'].status)?_c(VAlert,{staticClass:"mb-10",attrs:{"outlined":"","dense":"","border":"left","type":_vm.$store.getters['Auth/alert'].status === 'success'
                ? 'success'
                : 'error'}},[_vm._v(" "+_vm._s(_vm.$store.getters["Auth/alert"].message)+" ")]):_vm._e(),_c(VOtpInput,{attrs:{"length":"6"},on:{"finish":_vm.verifyOtp},model:{value:(_vm.otp_code),callback:function ($$v) {_vm.otp_code=$$v},expression:"otp_code"}})],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"loading":_vm.$store.getters['loading']},on:{"click":_vm.sendOtp}},[_vm._v(" Resend OTP ")]),_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.otp_code.length < 6,"color":"primary"},on:{"click":_vm.verifyOtp}},[_vm._v(" Verify OTP ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
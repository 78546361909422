import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c(VCard,{staticClass:"pa-md-5",attrs:{"elevation":"0"}},[_c(VCardText,[_c(VCardSubtitle,[_vm._v(" Complete the requirements checklist below ")]),_c(VDataTable,{staticClass:"mt-5 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.checkList,"hide-default-footer":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":headers.length}},[_c(VProgressLinear,{staticClass:"white--text font-weight-thin",attrs:{"rounded":"","value":_vm.completedChecklist,"color":_vm.completedChecklist < 50 ? 'primary' : 'secondary',"height":"18"}},[_c('strong',[_vm._v(_vm._s(Math.round(_vm.completedChecklist))+"%")])])],1)])])]}},{key:"item.checkListType",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('h4',{staticClass:"heading"},[_vm._v(_vm._s(item.checkListType))]),_c('span',{staticClass:"subtitle-2 grey--text"},[_vm._v(_vm._s(item.description))])])]}},{key:"item.pass",fn:function(ref){
var item = ref.item;
return [_c('td',[_c(VIcon,{attrs:{"color":item.pass ? 'success' : 'warning'}},[_vm._v(" "+_vm._s(item.pass ? "mdi-check" : "mdi-alert")+" ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"small":"","to":{
                  name: 'component',
                  params: { name: item.checkListType },
                },"color":"secondary","disabled":item.pass}},[_vm._v(" Complete "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
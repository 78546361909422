export default {
  membershipCategories: `setup/membership-categories`,
  requirements: "application/requirements",
  companyInformation: `company`,
  countries: "setup/countries",
  counties: "setup/counties",
  cities: "setup/cities",
  qualificationCodes: "setup/qualification-codes",
  certificationCode: "setup/certification-code",
  industryGroups: `setup/industry-group`,
  specializationTypes: `setup/specialization-types`,
  postcodes: `setup/postal-codes`,
  division: `setup/certification-class`,
  discipline: `setup/discipline`,
  title: `setup/title`,
  universities: `setup/universities`,
  attachmentTypes: `setup/attachmentTypes`,
  DISCLAIMERS: `setup/disclaimers`,
  FIRMS: `setup/firms`,
  REFEREE_TYPES: `setup/referee-types`,
};

import call from "../../service/http";
import { SearchConstants } from "./";

export default {
  namespaced: true,
  state: {
    profile: {},
    profiles: [],
    stats: [],
    loading: false,
  },
  mutations: {
    SET_PROFILE: (state, payload) => {
      state.profile = payload;
    },
    SET_PROFILES: (state, payload) => {
      state.profiles = payload;
    },
    SET_STATS: (state, payload) => {
      state.stats = payload;
    },
    SET_LOADING: (state, payload) => {
      state.loading = payload;
    },
  },
  getters: {
    loading: (state) => state.loading,
    profile: (state) => state.profile,
    profiles: (state) => state.profiles,
    stats: (state) => state.stats,
  },
  actions: {
    getStats: (context) => {
      context.commit("SET_LOADING", true, { root: true });
      call("get", SearchConstants.stats)
        .then((res) => {
          context.commit("SET_STATS", res.data.data);
          context.commit("SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          context.commit("SET_LOADING", false, { root: true });
          Event.$emit("toasting", {
            message: error.response.data.message,
            type: "danger",
          });
        });
    },

    search: (context, term) => {
      context.commit("SET_LOADING", true, { root: true });
      call("get", SearchConstants.search(term))
        .then((res) => {
          context.commit("SET_PROFILES", res.data.data);
          context.commit("SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          context.commit("SET_LOADING", false, { root: true });
          Event.$emit("toasting", {
            message: error.response.data.message,
            type: "danger",
          });
        });
    },

    getProfile: (context, profileID) => {
      context.commit("SET_LOADING", true, { root: true });
      call("get", SearchConstants.show(profileID))
        .then((res) => {
          context.commit("SET_PROFILE", res.data.data);
          context.commit("SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          context.commit("SET_LOADING", false, { root: true });
          Event.$emit("toasting", {
            message: error.response.data.message,
            type: "danger",
          });
        });
    },
  },
};

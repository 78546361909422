const SetupMixin = {
  methods: {
    getSetups: function (setups = []) {
      setups.forEach((setup) => {
        this.$store.dispatch(`Setup/${setup}`);
        this.$store.dispatch("Admin/getSettings");
      });
    },
  },

  computed: {
    membershipCategories() {
      return this.$store.getters["Setup/membershipCategories"];
    },
    requirements() {
      return this.$store.getters["Setup/requirements"];
    },
    companyInformation() {
      return this.$store.getters["Setup/companyInformation"];
    },
    countries() {
      return this.$store.getters["Setup/countries"].sort(
        (a, b) => a.Nationality > b.Nationality
      );
    },
    cities() {
      return this.$store.getters["Setup/cities"];
    },
    counties() {
      return this.$store.getters["Setup/counties"];
    },
    qualificationCodes() {
      return this.$store.getters["Setup/qualificationCodes"];
    },
    certificationCodes() {
      return this.$store.getters["Setup/certificationCodes"];
    },
    postalCodes() {
      return this.$store.getters["Setup/postalCodes"];
    },
    industryGroups() {
      return this.$store.getters["Setup/industryGroups"];
    },
    specializationTypes() {
      return this.$store.getters["Setup/specializationTypes"];
    },
    divisions() {
      return this.$store.getters["Setup/divisions"];
    },

    titles() {
      return this.$store.getters["Setup/titles"];
    },

    disciplines() {
      return this.$store.getters["Setup/disciplines"];
    },

    universities() {
      return this.$store.getters["Setup/universities"];
    },
    attachmentTypes() {
      return this.$store.getters["Setup/attachmentTypes"];
    },
    firms() {
      return this.$store.getters["Setup/firms"];
    },
    minorSettings() {
      const settings = this.$store.getters["Admin/adminGetters"]("settings");
      return settings.minor_settings
        ? JSON.parse(settings.minor_settings)
        : false;
    },
  },
};

export default SetupMixin;

<template>
  <v-container class="mt-5">
    <alert />
    <v-data-table
      :headers="headers"
      :items="institutions"
      dense
      class="elevation-1"
      :loading="$store.getters['loading']"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-h6">
            My Institution Membership
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog persistent v-model="dialog" max-width="50%">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Add Institution
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary">
                <span class="text-h5">
                  {{ edit ? "Edit" : "Add" }} Institution
                </span>
              </v-card-title>

              <v-card-text class="mt-10">
                <v-container>
                  <v-form ref="institutionForm">
                    <v-row dense>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          dense
                          outlined
                          label="Institution"
                          v-model="formData.Institution"
                          :rules="rules.Institution"
                          ref="Institution"
                          @input="
                            (val) =>
                              (formData.Institution =
                                formData.Institution.toUpperCase())
                          "
                        >
                          <template v-slot:label>
                            <div>
                              Institution <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          dense
                          outlined
                          v-model="formData.memberNo"
                          :rules="rules.memberNo"
                          ref="memberNo"
                          @input="
                            (val) =>
                              (formData.memberNo =
                                formData.memberNo.toUpperCase())
                          "
                        >
                          <template v-slot:label>
                            <div>
                              Registration No
                              <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          dense
                          outlined
                          v-model="formData.membershipType"
                          :rules="rules.membershipType"
                          ref="membershipType"
                          @input="
                            (val) =>
                              (formData.membershipType =
                                formData.membershipType.toUpperCase())
                          "
                        >
                          <template v-slot:label>
                            <div>
                              Membership Type
                              <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="6">
                        <!-- date picker -->
                        <v-menu
                          ref="menu"
                          v-model="yearOfRegistrationMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              dense
                              outlined
                              v-model="formData.yearOfRegistration"
                              :rules="rules.yearOfRegistration"
                              ref="yearOfRegistration"
                              label="Year of Registration"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            >
                              <template v-slot:label>
                                <div>
                                  Year of Registration
                                  <span class="red--text">*</span>
                                </div>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="formData.yearOfRegistration"
                            no-title
                            scrollable
                            :max="today"
                            @input="yearOfRegistrationMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" sm="6" md="6">
                        <v-file-input
                          outlined
                          accept="application/pdf"
                          hint="png and jpeg images only"
                          persistent-hint
                          dense
                          ref="attachment"
                          type="file"
                          v-model="formData.attachment"
                          :rules="rules.attachment"
                          :append-outer-icon="
                            formData.attached ? 'mdi-download' : ''
                          "
                          @click:append-outer="
                            downloadFile(formData.attached, formData.fileName)
                          "
                        >
                          <template v-slot:label>
                            <div>
                              Attach membership certificate
                              <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-file-input>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="clearForm"> Cancel </v-btn>
                <v-btn color="primary" @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.attachment="{ item }">
        <v-btn
          small
          outlined
          color="secondary"
          :disabled="!item.attachment"
          @click="
            downloadFile(item.attachment, `${item.Institution} Certificate.pdf`)
          "
        >
          <v-icon left color="secondary">mdi-file </v-icon>
          Download
        </v-btn>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" color="blue" @click="update(item)">
          mdi-pencil
        </v-icon>
        <v-icon small color="red" @click="remove(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { AuthService } from "../../../auth";
import { helper } from "../../../../utils";
import Alert from "../../../../components/Alert";
import FileMixin from "../../../../mixins/FileMixin";

export default {
  name: "Institution",
  components: { Alert },
  mixins: [FileMixin],
  data: () => ({
    dialog: false,
    edit: false,
    yearOfRegistrationMenu: false,
    today: new Date().toISOString().substr(0, 10),
    formData: {
      Institution: "",
      memberNo: "",
      membershipType: "",
      profileID: "",
      yearOfRegistration: "",
      attachment: undefined,
      attached: "",
    },
    headers: [
      {
        text: "Institution",
        align: "start",
        sortable: false,
        value: "Institution",
      },
      { text: "Registration No", value: "memberNo" },
      { text: "Membership Type", value: "membershipType" },
      { text: "year of Registration", value: "yearOfRegistration" },
      { text: "Certificate", value: "attachment", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  mounted() {
    this.$store.dispatch("Profile/getInstitutions");
  },

  computed: {
    rules() {
      return {
        Institution: [(v) => !!v || "Institution is required"],
        memberNo: [(v) => !!v || "Registration No required"],
        membershipType: [(v) => !!v || "Membership Type is required"],
        yearOfRegistration: [(v) => !!v || "year of Registration"],
        attachment: [
          (v) => {
            return this.formData.attached === ""
              ? !!v || "Attachment is required"
              : true;
          },
          (v) => {
            return v
              ? v.size < 10000000 || "Attachment should be less than 10MB"
              : true;
          },
          (v) => {
            return v
              ? v.type.includes("application/pdf") || "Attachment must be a Pdf"
              : true;
          },
        ],
      };
    },

    institutions() {
      return this.$store.getters["Profile/institutions"];
    },
  },

  methods: {
    clearForm: function () {
      this.$refs.institutionForm.reset();
      this.edit = false;
      this.dialog = false;
    },

    update: function (payload) {
      let data = { ...payload };
      data.attached = data.attachment;
      data.attachment = undefined;

      this.formData = data;
      this.edit = true;
      this.dialog = true;
    },

    remove(item) {
      this.$confirm.show({
        title: "Delete this Institution?",
        text: `Are you sure you want to delete?`,
        onConfirm: () => {
          this.$store.dispatch("Profile/removeInstitutions", { ...item });
        },
      });
    },

    save: function () {
      let isValid = true;
      for (const key in this.formData) {
        if (this.$refs[key])
          isValid = isValid ? this.$refs[key].validate(true) : false;
      }

      if (isValid) {
        let data = { ...this.formData };
        data.profileID = AuthService.user.profile.ProfileID;

        this.$store.dispatch(
          `Profile/${this.edit ? "updateInstitutions" : "addInstitutions"}`,
          helper.prepareFormData(data)
        );

        this.clearForm();
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <v-row>
    <!--Declaration -->
    <v-col sm="12" md="12" class="p-5">
      <!--General Information-->
      <v-card>
        <div class="form-group">
          <v-card-title> Declaration </v-card-title>
          <v-card-text>
            <p class="text-justify">
              I hereby attach the relevant certified supporting documents.
            </p>
            <p class="text-justify">
              I hereby solemnly declare that the foregoing information is true
              to the best of my knowledge. I acknowledge that any statement
              contained anywhere in this application which is known to me to be
              false shall invalidate this application and any decision reached
              thereon by the Registration Committee. I have read the Supplies
              Practitioners Management Act 2007, and I am aware of the penalties
              stipulated in connection with the provision of misleading
              information.
            </p>
            <p class="text-justify">
              I further commit to fulfill any requirements set by the Supplies
              Practitioners Management Act 2007 and the regulations relating to
              Professional Standards, Continuing Professional Education, Quality
              Review, Professional Indemnity for practicing professionals and
              any other professional pronouncements that are in force or may be
              introduced in the future.
            </p>
          </v-card-text>
        </div>

        <v-form v-model="isValid" ref="submitForm">
          <v-checkbox
            v-model="accepted"
            :true-value="true"
            :false-value="false"
            color="primary"
            class="mx-5"
            :rules="rules.required"
          >
            <template v-slot:label> I agree to the above declaration </template>
          </v-checkbox>
        </v-form>

        <v-card-actions>
          <v-btn @click="back" class="btn-secondary">
            <v-icon> mdi-arrow-left</v-icon>
            Back
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="submit"
            :disabled="!accepted || !application"
            color="primary"
          >
            Submit
            <v-icon> mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "Declaration",
  props: { next: { type: Function }, back: { type: Function } },
  data() {
    return {
      accepted: null,
      isValid: false,
    };
  },

  mounted() {
    Event.$on("License-saved", (license) => {
      this.$router.push(`/license/document/${license[0].licenseApplicationNo}`);
    });
  },

  computed: {
    rules() {
      return {
        required: [(value) => !!value || "Required field"],
      };
    },

    application() {
      return this.$store.getters["License/licenseGetters"]("application");
    },
  },

  methods: {
    submit: function () {
      this.$store.dispatch("License/submitApplication", {
        ...this.application,
      });
    },
  },
};
</script>

<template>
  <v-container>
    <v-card class="my-5" flat>
      <v-card-title>
        License Application
        <v-spacer />
        <v-btn
          small
          depressed
          color="secondary"
          v-if="document"
          @click="download"
        >
          <v-icon left>mdi-download</v-icon>
          Download
        </v-btn>

        <v-btn
          v-if="license && license.licenseBase64 !== ''"
          small
          depressed
          color="primary"
          class="ml-2"
          @click="
            $router.push({
              name: 'LicenseInvoice',
              params: { code: $route.params.code },
            })
          "
        >
          <span>
            <v-icon>mdi-cash</v-icon>
            Invoice
          </span>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="8">
            <pdf
              style="width: 100%"
              :src="`data:application/pdf;base64,${document}`"
            />
          </v-col>
          <v-col
            cols="12"
            v-if="license && license.licenseBase64 === ''"
            md="4"
          >
            <h5>
              Download the application to sign/stamp then upload the scanned pdf
              document
            </h5>
            <div class="border">
              <div>
                <input
                  id="file"
                  ref="file"
                  type="file"
                  accept="application/pdf"
                  @change="handleFileUpload()"
                />
              </div>

              <span> {{ formData.file_name }}</span>

              <v-btn
                :disabled="formData.file === ''"
                block
                small
                class="mt-3"
                color="primary"
                @click="uploadFile"
              >
                Upload Application
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <Checkout />
  </v-container>
</template>

<script>
import pdf from "vue-pdf";
import Checkout from "@kinetics254/paygate/src/views/Checkout.vue";
export default {
  name: "LicenseDocument",
  components: { pdf, Checkout },
  data: function () {
    return {
      formData: {
        file: "",
        file_name: "Attach Signed/Stamped File",
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("License/getApplication", v.$route.params.code);
      v.$store.dispatch("License/getLicence", v.$route.params.code);
    });
  },
  computed: {
    document() {
      return this.$store.getters["License/licenseGetters"](
        "licenseApplication"
      );
    },

    license() {
      let service = this.$store.getters["License/licenseGetters"](
        "licenses"
      ).filter((s) => {
        return s.licenseApplicationNo === this.$route.params.code;
      });
      return service.pop();
    },
  },
  methods: {
    download: function () {
      let path = `data:application/pdf;base64,${this.document}`;
      Event.$emit("downloadfile", { code: `Application`, path });
    },

    pay: function () {
      let payload = {
        payerID: this.service.profileID,
        sourceDocumentNo: this.service.licenseApplicationNo,
        description: "License fees",
        amount: this.service.amount,
        accountNo: "",
        success_url: `${process.env.VUE_APP_URL}/${
          this.$router.resolve({ name: "LicenseList" }).href
        }`,
        fail_url: window.location.href,
      };

      payload = btoa(JSON.stringify(payload));
      // window.location.href = `${process.env.VUE_APP_PAYGATE_CHECKOUT_URL}/?payload=${payload}`;
      this.$checkout.pay(payload);
    },

    handleFileUpload: function () {
      let maxAllowedSize = 3 * 1024 * 1024;
      this.formData["file"] = this.$refs.file.files[0];

      if (this.formData["file"].size > maxAllowedSize) {
        Event.$emit("toasting", {
          message: "File too large. max allowed size is 2MB",
          type: "danger",
        });

        this.formData["file"] = "";
      }

      this.formData["file_name"] = this.$refs.file.files[0].name;
    },

    uploadFile: function () {
      let license = {
        licenseApplicationNo: this.$route.params.code,
        licenseBase64: this.formData.file,
      };

      this.$store.dispatch("License/updateLicence", license);
    },
  },
};
</script>

<style scoped>
.border {
  padding: 10px 20px;
  border: 1px solid #81a333;
}
</style>

import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"account-settings",attrs:{"fluid":""}},[_c(VCard,[_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_c(VBtn,{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$router.back()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" Back ")],1)],1),_c(VSpacer),_vm._v(" Account Settings "),_c(VSpacer)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6","offset-md":"3"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[(_vm.$store.getters['Dashboard/alert'].status)?_c(VAlert,{staticClass:"mb-10",attrs:{"outlined":"","dense":"","border":"left","type":_vm.$store.getters['Dashboard/alert'].status === 'success'
                  ? 'success'
                  : 'error'}},[_vm._v(" "+_vm._s(_vm.$store.getters["Dashboard/alert"].message)+" ")]):_vm._e()],1),_c(VCardText,[_c(VTextField,{ref:"password",attrs:{"dense":"","outlined":"","type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',"label":"New Password","placeholder":"Enter a secure password","rules":_vm.rules.password},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c(VTextField,{ref:"confirmPassword",attrs:{"dense":"","outlined":"","label":"Confirm Password","append-icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',"type":_vm.showPassword ? 'text' : 'password',"placeholder":"Enter Password again","rules":_vm.rules.confirmPassword},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.formData.confirmPassword),callback:function ($$v) {_vm.$set(_vm.formData, "confirmPassword", $$v)},expression:"formData.confirmPassword"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"mx-2",attrs:{"color":"primary"},on:{"click":_vm.reset}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-content-save-move")]),_vm._v(" Save ")],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.payments,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,{staticClass:"text-h6"},[_vm._v("My Payment History")]),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VSpacer),_c(VBtn,{staticClass:"text--capitalize primary",attrs:{"disabled":_vm.payments.length === 0},on:{"click":_vm.exportToExcel}},[_vm._v(" Export to Excel "),_c(VIcon,{staticClass:"mx-2"},[_vm._v(" mdi-file-excel ")])],1)],1),_c(VDivider)]},proxy:true},{key:"item.paymentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.paymentDate))+" ")]}},{key:"item.paymentFrom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.paymentFrom))+" ")]}},{key:"item.paymentTo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.paymentTo))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amount))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
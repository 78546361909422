<template>
  <v-container class="mx-auto">
    <v-row>
      <v-col sm="12" class="px-5">
        <v-form ref="licenseDetails" v-model="isValid">
          <v-row>
            <v-col md="4" sm="12">
              <div>
                <label>Membership Category</label>
                <h6>{{ profile.MemberShipType }}</h6>
              </div>
            </v-col>

            <v-col md="4" sm="12">
              <div>
                <label>Member No.</label>
                <h6>{{ profile.MemberNo || "--" }}</h6>
              </div>
            </v-col>

            <v-col md="4" sm="12">
              <div v-if="profile">
                <label>Registration Date</label>
                <h6 v-for="(memb, i) in profile.memberInformation" :key="i">
                  <span>
                    {{ memb.Registration_Date }}
                  </span>
                </h6>
              </div>
            </v-col>

            <v-col md="4" sm="12">
              <div>
                <label>Licence Amount</label>
                <h6 class="primary--text">
                  {{ categories ? categories.Currency_Code : "" }}
                  {{
                    categories
                      ? categories.Amount_Including_VAT
                      : 0 | currencyFormat
                  }}
                </h6>
              </div>
            </v-col>

            <v-col md="4" sm="12">
              <div>
                <!-- add tooltip -->
                <v-text-field
                  v-model="membershipLicenseApplication.startDate"
                  type="date"
                  label="Practice Start Date"
                  placeholder="Enter Date"
                  :rules="rules.required"
                  dense
                  outlined
                  clearable
                />
              </div>
            </v-col>
          </v-row>

          <!--Practitioner Details Member details if individual otherwise cop/firm detail -->
          <v-row>
            <v-col col="12" md="12">
              <span class="text-primary font-italic">
                <!-- {{ user.type === "Individual" ? "" : "Managing Supplies" }} -->
                Practitioner details
              </span>
              <br />
              <v-divider />
            </v-col>

            <v-col md="4" sm="12">
              <v-text-field
                v-model="membershipLicenseApplication.practitionerSurname"
                dense
                outlined
                clearable
                label="Surname"
                placeholder="Enter Surname"
                :rules="rules.required"
              />
            </v-col>

            <v-col md="8" sm="12">
              <v-text-field
                v-model="membershipLicenseApplication.practitionerOtherNames"
                dense
                outlined
                clearable
                label="Other Names"
                placeholder="Enter other names"
                :rules="rules.required"
              />
            </v-col>

            <v-col md="4" sm="12">
              <v-text-field
                v-model="membershipLicenseApplication.practitionerPhone"
                dense
                outlined
                clearable
                label="Phone Number"
                placeholder="Enter Phone Number"
                :rules="rules.phoneNo"
              />
            </v-col>

            <v-col md="4" sm="12">
              <v-text-field
                v-model="membershipLicenseApplication.practitionerEmail"
                dense
                outlined
                clearable
                label="Email Address"
                placeholder="Enter Email Address"
                :rules="rules.email"
              />
            </v-col>

            <v-col md="4" sm="12">
              <v-text-field
                v-model="membershipLicenseApplication.practitionerAddress"
                dense
                outlined
                clearable
                label="Address"
                placeholder="Address"
                :rules="rules.required"
              />
            </v-col>
          </v-row>

          <!--Practice Details-->
          <v-row>
            <v-col col="12" md="12">
              <span class="text-primary font-italic">Practice Details</span>
              <br />
              <v-divider></v-divider>
            </v-col>

            <!--For Cop/Firm Only-->
            <v-col col="12">
              <v-text-field
                v-model="membershipLicenseApplication.practiceName"
                dense
                outlined
                clearable
                label="Practice Name"
                placeholder="Enter Practice Name"
                :rules="rules.required"
              />
            </v-col>

            <v-col md="4" sm="12">
              <v-text-field
                v-model="membershipLicenseApplication.email"
                dense
                outlined
                clearable
                label="Email"
                placeholder="Enter Email Address"
                :rules="rules.email"
              />
            </v-col>

            <v-col md="4" sm="12">
              <v-text-field
                v-model="membershipLicenseApplication.phoneNo"
                dense
                outlined
                clearable
                label="Phone"
                placeholder="Enter Phone Number"
                :rules="rules.phoneNo"
              />
            </v-col>

            <v-col md="4" sm="12">
              <v-text-field
                v-model="membershipLicenseApplication.postalCode"
                dense
                outlined
                clearable
                label="Postal Address"
                placeholder="Enter Postal Address"
                :rules="rules.required"
              />
            </v-col>

            <v-col md="4" sm="12">
              <v-text-field
                v-model="membershipLicenseApplication.physicalLocation"
                dense
                outlined
                clearable
                label="Physical Address"
                placeholder="Enter Physical Address"
                :rules="rules.required"
              />
            </v-col>

            <!-- countries -->
            <v-col md="4" sm="12">
              <v-autocomplete
                v-model="membershipLicenseApplication.countryCode"
                dense
                outlined
                clearable
                :items="countries"
                :rules="rules.required"
                item-text="displayName"
                item-value="code"
                label="Country"
                class="form-control"
              />
            </v-col>

            <!-- cities -->
            <v-col md="4" sm="12">
              <v-autocomplete
                v-model="membershipLicenseApplication.city"
                dense
                outlined
                clearable
                :items="cities"
                :rules="rules.required"
                label="City"
                item-text="City"
                item-value="Code"
                class="form-control"
              />
            </v-col>

            <!-- counties -->
            <v-col md="4" sm="12">
              <v-autocomplete
                v-model="membershipLicenseApplication.county"
                dense
                outlined
                clearable
                :items="counties"
                :rules="rules.required"
                label="County"
                item-text="countyName"
                item-value="CountyNo"
                class="form-control"
              />
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <v-col col="12" class="text-right">
            <v-btn depressed class="primary" @click="saveApplication()">
              Continue
              <v-icon ml-1>mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import setupMixin from "../../setup/SetupMixin";
import AuthService from "../../auth/AuthService";

export default {
  name: "LicenseDetails",
  mixins: [setupMixin],
  props: { next: { type: Function }, back: { type: Function } },
  data() {
    return {
      membershipLicenseApplication: {
        description: "",
        profileID: "",
        applicationDate: "",
        amount: 0,
        memberCategory: "",
        startDate: "",
        countryCode: "",
        county: "",
        city: "",
        postalCode: "",
        physicalLocation: "",
        phoneNo: "",
        email: "",
        practiceName: "",
        practitionerSurname: "",
        practitionerOtherNames: "",
        practitionerPhone: "",
        practitionerEmail: "",
        practitionerAddress: "",
      },
      isValid: false,
    };
  },

  computed: {
    profile() {
      return this.$store.getters["License/licenseGetters"]("profile");
    },

    categories() {
      let cat = this.membershipCategories
        .filter((c) => {
          return c.Code === this.profile.MemberShipType;
        })
        .shift();

      return cat
        ? cat.charges
            .filter((c) => {
              return c.Charge_Item === "LICENSE";
            })
            .shift()
        : null;
    },

    user() {
      return AuthService.user;
    },

    rules() {
      return {
        phoneNo: [
          (v) => !!v || "Field is required",
          (v) =>
            !v ||
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) ||
            "Invalid phone number format",
        ],
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        required: [(value) => !!value || "Required field"],
      };
    },
  },

  methods: {
    saveApplication: function () {
      this.$refs.licenseDetails.validate();
      if (!this.isValid) {
        return 0;
      } else {
        this.membershipLicenseApplication.amount = this.categories
          ? this.categories.Amount_Including_VAT
          : 0;
        this.membershipLicenseApplication.applicationDate = new Date();
        this.membershipLicenseApplication.profileID = this.user.profile_id;
        this.$store.commit("License/MUTATE", {
          state: "application",
          data: { ...this.membershipLicenseApplication },
        });
        this.next();
      }
    },
  },

  watch: {
    profile: {
      handler: function (profile) {
        if (!profile) return;

        let personal = profile.contacts
          .filter((c) => {
            return c.Type === "Personal";
          })
          .shift();

        let work = profile.contacts
          .filter((c) => {
            return c.Type === "Work";
          })
          .shift();

        let corpManager = profile.corporateManager
          ? profile.corporateManager.shift()
          : null;
        let contact = work || personal;

        this.membershipLicenseApplication = {
          practiceName: `${profile.FirstName}  ${profile.SecondName}  ${profile.LastName}`,
          practitionerSurname:
            this.user.type === "Individual"
              ? profile
                ? profile.LastName
                : ""
              : corpManager
              ? corpManager.surname
              : "",
          practitionerOtherNames:
            this.user.type === "Individual"
              ? profile
                ? profile.FirstName + " " + profile.SecondName
                : ""
              : corpManager
              ? corpManager.OtherNames
              : "",
          practitionerPhone:
            this.user.type === "Individual"
              ? personal
                ? personal.PhoneNo
                : ""
              : corpManager
              ? corpManager.phoneNo
              : "",
          practitionerEmail:
            this.user.type === "Individual"
              ? profile
                ? profile.Email
                : ""
              : corpManager
              ? corpManager.email
              : "",
          practitionerAddress:
            this.user.type === "Individual"
              ? personal
                ? personal.Address
                : ""
              : corpManager
              ? corpManager.address
              : "",
          description: "License Application",
          profileID: profile.ProfileID,
          memberCategory: profile.MemberShipType,
          startDate: "",
          countryCode: contact ? contact.Country : "",
          county: contact ? contact.County : "",
          city: contact ? contact.City : "",
          postalCode: contact ? contact.PostCode : "",
          physicalLocation: contact ? contact.Address : "",
          phoneNo: contact ? contact.PhoneNo : "",
          email: profile ? profile.Email : "",
          applicationDate: "",
          amount: 0,
        };
      },
    },
  },
};
</script>

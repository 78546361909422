import call from "../../service/http";
import EventsConstants from "./EventsConstants";

export default {
  namespaced: true,
  state: {
    eventBookings: [],
    events: [],
    alert: {
      status: "",
      message: "",
    },
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
  },
  getters: {
    eventsGetters: (state) => (val) => state[val],
    alert: (state) => state.alert,
  },
  actions: {
    getBookings: ({ commit }) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", EventsConstants.BOOKED_EVENTS)
        .then((res) => {
          commit("MUTATE", { state: "eventBookings", data: res.data.data });
        })
        .catch((error) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: error.response.data.message,
          });
        });
    },
    getEvents: ({ commit }) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", EventsConstants.ALL_EVENTS)
        .then((res) => {
          commit("MUTATE", { state: "events", data: res.data.data });
        })
        .catch((error) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: error.response.data.message,
          });
        });
    },
  },
};

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import vuetify from "./plugins/vuetify";
import confirmation from "./plugins/confirmtion";
import { filters } from "./utils";
import listener from "@/mixins/listener";
import RouteMixin from "./mixins/RouteMixin";
import Toast from "vue-toastification";
import { setupTimers } from "./utils/auto-logout";
import { navigationType } from "./utils/check-page-reload";
import "vue-toastification/dist/index.css";
// import Feedback from "./modules/Odyssey";
// import Feedback from "@kinetics254/summit-odyssey";

/** import paygate package */
import paygate from "@kinetics254/paygate";

Vue.config.productionTip = false;

// eslint-disable-next-line no-unused-vars,no-global-assign
Event = new Vue();

// const options = { router, store };
Vue.use(confirmation);
// Vue.use(Feedback, options);
Vue.use(Toast);
Vue.use(setupTimers);
Vue.use(navigationType);
Vue.use(paygate.paygateCheckout, { store });
Vue.use(paygate.appCheckoutAlert);

/* Register Filters */
filters.forEach((f) => {
  Vue.filter(f.name, f.execute);
});

new Vue({
  router,
  store,
  vuetify,
  mixins: [listener, RouteMixin],
  render: (h) => h(App),
}).$mount("#app");

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,[_c(VCardTitle,[_c(VBtn,{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$router.back()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" Back ")],1),_c(VSpacer),_vm._v(" Referee Form Report "),_c(VSpacer),_c(VBtn,{attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.downloadFile(_vm.document, ((_vm.$route.params.code) + ".pdf"))}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Download ")],1)],1),_c(VDivider),_vm._l((_vm.pageCount(_vm.document)),function(i){return _c(VCardText,{key:i},[_c('pdf',{staticStyle:{"width":"100%"},attrs:{"src":("data:application/pdf;base64," + _vm.document),"page":i}})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
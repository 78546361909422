export default {
  application: "application",
  document: (code) => `application/document/${code}`,
  certificate: (code) => `application/certificate/${code}`,
  rejectionLetter: (code) => `application/rejectionLetter/${code}`,
  acceptanceLetter: (code) => `application/acceptanceLetter/${code}`,
  submit: "application/submit",
  checklist: (category) => `application/checklist/${category}`,
  verifyOtp: `auth/verify-otp`,
  sendOtp: (id) => `auth/send-otp?user_id=${id}`,
};

<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    style="background-color: rgba(255, 255, 255, 0.65)"
  >
    <v-row>
      <v-col class="text-center" md="6" sm="12" offset-md="3">
        <v-card :loading="$store.getters['loading']">
          <v-card-text class="pa-5">
            <h1
              class="text-center mb-3 font-weight-light"
              style="font-size: 40px"
            >
              Verify OTP code
            </h1>

            <p class="mt-5 subtitle-1">
              OTP Code has been sent to your Mobile No. ({{
                testPhoneMask(
                  JSON.parse(decodeRoute(this.$route.params.id)).phone_no
                ) || ""
              }}) or check your mail for an activation link
            </p>

            <v-alert
              outlined
              v-if="$store.getters['Auth/alert'].status"
              dense
              border="left"
              :type="
                $store.getters['Auth/alert'].status === 'success'
                  ? 'success'
                  : 'error'
              "
              class="mb-10"
            >
              {{ $store.getters["Auth/alert"].message }}
            </v-alert>

            <v-otp-input length="6" v-model="otp_code" @finish="verifyOtp" />
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-btn :loading="$store.getters['loading']" @click="sendOtp">
              Resend OTP
            </v-btn>

            <v-spacer />

            <v-btn
              :disabled="otp_code.length < 6"
              @click="verifyOtp"
              color="primary"
            >
              Verify OTP
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RouterMixin from "../../../router/RouterMixin";
import MaskMixin from "../../../mixins/MaskMixin";
import { EventBus } from "../../../utils/eventBus";
// import { AuthService } from "../../auth/AuthService";

export default {
  name: "otp",
  mixins: [RouterMixin, MaskMixin],
  data: function () {
    return {
      otp_code: "",
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (to.query.verify) v.otp_code = atob(to.query.verify);
    });
  },

  methods: {
    verifyOtp: function () {
      this.$store.dispatch("Auth/verifyOtp", { otp_code: this.otp_code });
    },

    sendOtp: function () {
      this.$store.dispatch(
        "Auth/sendOtp",
        JSON.parse(atob(this.$route.params.id)).id
      );
    },
  },
  mounted() {
    EventBus.$on("redirectToLoginPage", () => {
      this.$router.push({
        name: "login",
      });
    });
  },
};
</script>

<style scoped></style>

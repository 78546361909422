<template>
  <v-container fluid>
    <v-card flat class="mx-auto">
      <v-card-title>
        <span>Admin Settings</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="settings" v-model="isValid">
          <v-row>
            <v-col cols="12" md="12" class="mt-n3 mb-n3">
              <div class="text-body-1 font-weight-bold">
                <span>App Color Settings</span>
              </div>
              <v-divider />
            </v-col>

            <v-col cols="12" md="12">
              <div class="d-flex flex-wrap">
                <v-menu
                  :close-on-content-click="false"
                  v-for="(item, i) in colors"
                  v-model="item.menu"
                  :key="i"
                  transition="scale-transition"
                  offset-y
                  min-width="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      :key="i"
                      :color="item.color"
                      class="mx-2 my-2"
                      v-on="on"
                      v-bind="attrs"
                      label
                    >
                      <span class="white--text">{{
                        `${item.colorName} : ${item.color}`
                      }}</span>
                    </v-chip>
                  </template>
                  <v-card>
                    <v-card-title class="headline">Pick a color</v-card-title>
                    <v-card-text>
                      <v-color-picker
                        @input="updateColor($event, item)"
                        :value="item.color"
                        mode="hexa"
                        show-swatches
                        swatches-max-height="300px"
                      ></v-color-picker>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" class="mt-n3">
              <div class="text-body-1 font-weight-bold">
                <span>Minor Settings</span>
              </div>
              <v-divider class="pb-3" />
            </v-col>

            <v-col cols="12" md="12" class="mt-n3">
              <span class="text-primary font-italic"> Login Section </span>
              <v-divider />
            </v-col>
            <!-- login caption engineer/member -->
            <v-col cols="12" md="4" class="mt-n3">
              <v-text-field
                v-model="settingsFormData.minor_settings.login_caption"
                label="Login caption"
                dense
                outlined
                clearable
                hint="login caption"
              />
            </v-col>
            <!-- add organisation caption -->
            <v-col cols="12" md="4" class="mt-n3">
              <v-text-field
                v-model="settingsFormData.minor_settings.organisation_caption"
                label="Organisation caption"
                dense
                outlined
                clearable
                hint="organisation caption"
              />
            </v-col>
            <!-- logout after inactivity -->
            <v-col cols="12" md="4" class="mt-n6">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-switch
                      v-model="
                        settingsFormData.minor_settings['inactivityLogout']
                      "
                      label="logout user after inactivity"
                      inset
                      dense
                    />
                  </div>
                </template>
                <span>Inactivity Logout</span>
              </v-tooltip>
            </v-col>
            <!-- registration functionality -->
            <v-col cols="12" md="4" class="mt-n6">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-switch
                      v-model="settingsFormData.minor_settings['registration']"
                      label="Registration Function"
                      inset
                      dense
                    />
                  </div>
                </template>
                <span>Add registration button on login page</span>
              </v-tooltip>
            </v-col>

            <v-col cols="12" md="12" class="mt-n3">
              <span class="text-primary font-italic"> License Section </span>
              <v-divider />
            </v-col>
            <!-- add license caption -->
            <v-col cols="12" md="4" class="mt-n3">
              <v-text-field
                v-model="
                  settingsFormData.minor_settings.license_alternative_caption
                "
                label="License alternative caption"
                dense
                outlined
                clearable
                hint="License alternative caption"
              />
            </v-col>

            <v-col cols="12" md="12" class="mt-n3">
              <span class="text-primary font-italic"> Profile Section </span>
              <v-divider />
            </v-col>
            <!-- add referee caption -->
            <v-col cols="12" md="4" class="mt-n6">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-switch
                      v-model="
                        settingsFormData.minor_settings['referee_disclaimer']
                      "
                      label="Referee Disclaimer"
                      inset
                      dense
                    />
                  </div>
                </template>
                <span>Referee Disclaimer</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" md="4" class="mt-n3">
              <v-textarea
                v-model="
                  settingsFormData.minor_settings.referee_disclaimer_caption
                "
                outlined
                auto-grow
                :disabled="
                  !settingsFormData.minor_settings['referee_disclaimer']
                "
                rows="1"
                color="blue-grey lighten-2"
                multiple
                label="Referee disclaimer caption"
                hint="Caption will be added as a disclaimer when user is adding referee"
              />
            </v-col>
            <!--change to county field to bio data  -->
            <v-col cols="12" md="3" class="mt-n6">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-switch
                      v-model="settingsFormData.minor_settings['countyField']"
                      label=" Move County Field to Bio Data Page"
                      inset
                      dense
                    />
                  </div>
                </template>
                <span>County Field</span>
              </v-tooltip>
            </v-col>
            <!-- add practicing firm field -->
            <v-col cols="12" md="3" class="mt-n6">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-switch
                      v-model="
                        settingsFormData.minor_settings['practicing_firm']
                      "
                      label="Practicing Firm field"
                      inset
                      dense
                    />
                  </div>
                </template>
                <span>Practicing Firm field</span>
              </v-tooltip>
            </v-col>
            <!-- show workplace -->
            <v-col cols="12" md="3" class="mt-n6">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-switch
                      v-model="settingsFormData.minor_settings['showWorkPlace']"
                      label="show Work Place"
                      inset
                      dense
                    />
                  </div>
                </template>
                <span>show Work Place</span>
              </v-tooltip>
            </v-col>

            <v-col cols="12" md="12" class="mt-n3">
              <span class="text-primary font-italic"> Navbar section </span>
              <v-divider />
            </v-col>
            <!-- packages to be installed -->
            <v-col cols="12" md="12" class="mt-n1">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-autocomplete
                      v-model="settingsFormData.site_installed_packages"
                      :items="items"
                      outlined
                      chips
                      dense
                      color="blue-grey lighten-2"
                      label="Selected Links"
                      item-text="title"
                      item-value="title"
                      multiple
                      hint="Selected Links will be displayed on the portal
                      side"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          small
                          @click="data.select"
                          @click:close="remove(data.item)"
                        >
                          {{ data.item.title }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </div>
                </template>
                <span>Select Links to Display</span>
              </v-tooltip>
            </v-col>
            <!-- Certificate of good standing -->
            <v-col cols="12" md="3" class="mt-n6">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-switch
                      v-model="
                        settingsFormData.minor_settings[
                          'certificate_of_good_standing'
                        ]
                      "
                      label="Certificate of Good Standing"
                      inset
                      dense
                    />
                  </div>
                </template>
                <span>Enable/Disable Good Standing Letter</span>
              </v-tooltip>
            </v-col>
            <!-- good standing caption -->
            <v-col cols="12" md="3" class="mt-n3">
              <v-text-field
                v-model="
                  settingsFormData.minor_settings[
                    'certificate_of_good_standing_caption'
                  ]
                "
                outlined
                dense
                :disabled="
                  !settingsFormData.minor_settings[
                    'certificate_of_good_standing'
                  ]
                "
                rows="1"
                label="Certificate of Good Standing caption"
              />
            </v-col>
            <!-- acceptance letter caption -->
            <v-col cols="12" md="3" class="mt-n3">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-text-field
                      v-model="
                        settingsFormData.minor_settings[
                          'acceptance_letter_caption'
                        ]
                      "
                      outlined
                      dense
                      rows="1"
                      label="Acceptance Letter Caption"
                    />
                  </div>
                </template>
                <span>Acceptance Letter Caption</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="saveSettings">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import { helper } from "@/utils";
export default {
  name: "AdminSettings",
  data() {
    return {
      isValid: false,
      items: [
        {
          title: "Dashboard",
        },
        {
          title: "Profile",
        },
        {
          title: "CPD",
        },
        {
          title: "Application",
        },
        {
          title: "Subscription",
        },
        {
          title: "Upgrade",
        },
        {
          title: "License",
        },
        {
          title: "Billing",
        },
        {
          title: "Certificate",
        },
        {
          title: "MyProjects",
        },
        // {
        //   title: "Payment History",
        // },
        {
          title: "Events",
        },
      ],
      settingsFormData: {
        minor_settings: {
          login_caption: "",
          organisation_caption: "",
          referee_disclaimer: false,
          referee_disclaimer_caption: "",
          registration: false,
          license_alternative_caption: "",
          countyField: false,
          practicing_firm: false,
          inactivityLogout: false,
          certificate_of_good_standing: false,
          certificate_of_good_standing_caption: "",
          acceptance_letter_caption: "",
          showWorkPlace: true,
        },
        site_colors: [],
        site_installed_packages: [],
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Admin/getSettings");
      v.$store.dispatch("Admin/getUsers");
    });
  },
  computed: {
    rules() {
      return {};
    },
    users() {
      return this.$store.getters["Admin/adminGetters"]("users");
    },
    settings() {
      return this.$store.getters["Admin/adminGetters"]("settings");
    },
    colors() {
      return this.settings !== null
        ? this.settingsFormData.site_colors
        : this.objectToArray(this.$vuetify.theme.themes.light);
    },
  },
  methods: {
    updateColor(color, item) {
      const colors = [...this.colors];
      colors.forEach((colorItem) => {
        if (colorItem.colorName === item.colorName) {
          colorItem.color = color;
          colorItem.menu = false;
        }
      });
      this.settingsFormData.minor_settings.site_colors = colors;
    },
    async saveSettings() {
      const data = {
        site_colors: JSON.stringify(
          this.settingsFormData.site_colors.length > 0
            ? this.settingsFormData.site_colors
            : this.colors
        ),
        site_installed_packages: JSON.stringify(
          this.settingsFormData.site_installed_packages.length > 0
            ? this.settingsFormData.site_installed_packages
            : []
        ),
        minor_settings: JSON.stringify(this.settingsFormData.minor_settings),
        id: this.settings ? this.settings.id : null,
      };
      if (this.settings.id) {
        console.log("passed");
        this.$store.dispatch(
          "Admin/updateSettings",
          helper.prepareFormData(data)
        );
      } else {
        this.$store.dispatch("Admin/addSettings", helper.prepareFormData(data));
      }
    },
    objectToArray(obj) {
      return Object.keys(obj).map((key) => {
        return { colorName: key, color: obj[key], menu: false };
      });
    },
  },
  watch: {
    settings: {
      handler: async function (val) {
        if (val) {
          this.settingsFormData.minor_settings =
            val && val.minor_settings
              ? JSON.parse(val.minor_settings)
              : this.settingsFormData.minor_settings;
          this.settingsFormData.site_colors =
            val && val.site_colors
              ? JSON.parse(val.site_colors)
              : this.objectToArray(this.$vuetify.theme.themes.light);
          this.settingsFormData.site_installed_packages =
            val && val.site_installed_packages
              ? JSON.parse(val.site_installed_packages)
              : [];

          this.settingsFormData.minor_settings.license_alternative_caption ===
          ""
            ? (this.settingsFormData.minor_settings.license_alternative_caption =
                "License")
            : this.settingsFormData.minor_settings.license_alternative_caption;
        }
      },
      deep: true,
    },
  },
};
</script>

import DashboardLayout from "../../layouts/dashboard/DashboardLayout";
import CpdList from "./components/CpdList";
import Cpd from "./views/Cpd";
import Auth from "../../router/middleware/Auth";

export default [
  {
    path: "/cpd",
    component: DashboardLayout,
    children: [
      {
        path: "",
        components: { view: Cpd },
        children: [
          {
            path: "",
            components: { view: CpdList },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
        ],
      },
    ],
  },
];

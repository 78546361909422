<template>
  <v-container class="mt-5">
    <alert />
    <v-data-table
      :headers="headers"
      :items="shareholders"
      dense
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-h6">Shareholders</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog persistent v-model="dialog" max-width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Add Shareholder
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="orange lighten-5">
                <span class="text-h5">
                  {{ edit ? "Edit" : "Add" }} Shareholders
                </span>
              </v-card-title>

              <v-card-text class="mt-10">
                <v-container>
                  <v-form ref="shareHoldersForm" v-model="isValid">
                    <v-row dense>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          outlined
                          placeholder="Add Shareholder Name"
                          label="Shareholder Name"
                          v-model="formData.name"
                          dense
                          :rules="rules.name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          outlined
                          placeholder="Add Identification Number"
                          label="Identification Number"
                          v-model="formData.identificationDocumentNo"
                          dense
                          :rules="rules.identificationDocumentNo"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          outlined
                          placeholder="Add Discipline"
                          label="Discipline"
                          v-model="formData.discipline"
                          dense
                          :rules="rules.discipline"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          outlined
                          placeholder="Shareholder Type"
                          label="Shareholder Type"
                          v-model="formData.type"
                          :items="shareholderTypes"
                          dense
                          :rules="rules.type"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-text-field
                          outlined
                          placeholder="Add Professional Registration Number"
                          label="Professional Registration Number"
                          v-model="formData.regNo"
                          dense
                          :rules="isMember ? rules.regNo : ''"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-text-field
                          outlined
                          placeholder="Add Number Of Shares Held"
                          label="Number Of Shares Held"
                          v-model="formData.sharesHeld"
                          dense
                          type="number"
                          min="0"
                          :rules="rules.sharesHeld"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="clearForm"> Cancel </v-btn>
                <v-btn color="primary" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" color="blue" @click="update(item)">
          mdi-pencil
        </v-icon>
        <v-icon small color="red" @click="remove(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { AuthService } from "../../../auth";
import { helper } from "../../../../utils";
import Alert from "../../../../components/Alert";

export default {
  name: "Shareholder",
  components: { Alert },
  data: () => ({
    dialog: false,
    edit: false,
    shareholderTypes: ["Member", "Non Member"],
    formData: {
      name: "",
      discipline: "",
      regNo: "",
      sharesHeld: "",
      identificationDocumentNo: "",
      type: "",
    },
    headers: [
      {
        text: "Shareholder Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "ID Number", value: "identificationDocumentNo" },
      { text: "Discipline", value: "discipline" },
      { text: "Type", value: "type" },
      { text: "Professional Registration Number", value: "regNo" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  mounted() {
    this.$store.dispatch("Profile/getShareholders");
  },

  computed: {
    rules() {
      return {
        name: [(v) => !!v || "name is required"],
        Discipline: [(v) => !!v || "Discipline is required"],
        regNo: [(v) => !!v || "Professional Registration Number is required"],
        sharesHeld: [(v) => !!v || "Shares Held is required"],
        type: [(v) => !!v || "type is required"],
        identificationDocumentNo: [
          (v) => !!v || "identificationDocumentNo is required",
        ],
      };
    },

    isMember() {
      return this.formData.type === "Member";
    },

    shareholders() {
      return this.$store.getters["Profile/shareholders"];
    },
  },

  methods: {
    clearForm: function () {
      this.formData = {
        name: "",
        discipline: "",
        regNo: "",
        sharesHeld: "",
        identificationDocumentNo: "",
        type: "",
      };

      this.dialog = false;
      this.edit = false;
    },

    update: function (payload) {
      this.formData = { ...payload };
      this.edit = true;
      this.dialog = true;
    },

    remove(item) {
      this.$confirm.show({
        title: "Delete this Shareholder?",
        text: `Are you sure you want to delete?`,
        onConfirm: () => {
          this.$store.dispatch("Profile/removeShareholder", { ...item });
        },
      });
    },

    save: function () {
      if (!this.isValid) {
        this.$refs.shareHoldersForm.validate();
      } else {
        let data = { ...this.formData };
        data.profileID = AuthService.user.profile.ProfileID;

        if (this.edit) {
          data.entryNo = this.formData.entryNo
            ? parseInt(this.formData.entryNo)
            : parseInt(this.shareholders.length + 1);
        }

        data.sharesHeld = parseFloat(data.sharesHeld);

        this.$store.dispatch(
          `Profile/${this.edit ? "updateShareholder" : "addShareholder"}`,
          helper.prepareFormData(data)
        );

        this.clearForm();
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <v-app class="app-back">
    <v-app-bar app dark color="primary">
      <v-img
        alt="Logo"
        class="shrink mr-2"
        contain
        :src="`data:image/png;base64,${companyInformation.logo}`"
        transition="scale-transition"
        width="50"
        @click="$router.push('/')"
      />
      <v-spacer />

      <v-btn
        v-if="$route.name !== 'search'"
        :to="{ name: 'search' }"
        text
        color="white"
        light
      >
        <span class="mr-2">Back</span>
        <v-icon>mdi-account-search</v-icon>
      </v-btn>

      <v-btn :to="{ name: 'login' }" text class="ml-2" color="white" light>
        <span class="mr-2">My Account</span>
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      style="margin-bottom: 3%"
      color="primary"
      @click="toTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>

    <spinner />

    <Footer />
  </v-app>
</template>

<script>
import Footer from "../../components/Footer";
import Spinner from "../../plugins/loader/views/Spinner";
import SetupMixin from "../../modules/setup/SetupMixin";
export default {
  name: "SearchLayout",
  mixins: [SetupMixin],
  data: function () {
    return {
      fab: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Admin/getSettings");
    });
  },
  components: { Spinner, Footer },
  computed: {
    settings() {
      return this.$store.getters["Admin/adminGetters"]("settings");
    },
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
  watch: {
    settings: {
      handler: async function (val) {
        if (val) {
          if (val.site_colors) {
            const colors = JSON.parse(val.site_colors);
            colors.forEach((el) => {
              this.$vuetify.theme.themes.light[el.colorName] = el.color;
            });
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{staticClass:"p-5",attrs:{"sm":"12","md":"12"}},[_c(VRow,[_c(VCol,{attrs:{"md":"12"}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.checklistItems},scopedSlots:_vm._u([{key:"item.checkListType",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('h4',{staticClass:"heading"},[_vm._v(_vm._s(item.checkListType))]),_c('span',{staticClass:"subtitle-2 grey--text"},[_vm._v(_vm._s(item.description))])])]}},{key:"item.pass",fn:function(ref){
var item = ref.item;
return [_c('td',[_c(VIcon,{attrs:{"color":item.pass ? 'success' : 'warning'}},[_vm._v(" "+_vm._s(item.pass ? "mdi-check" : "mdi-alert")+" ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"small":"","to":{
                name: 'component',
                params: { name: item.checkListType },
              },"color":"secondary"}},[_vm._v(" Complete "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1)]}}])})],1)],1),_c(VRow,[_c(VCol,{attrs:{"sm":"6"}},[_c(VBtn,{attrs:{"depressed":""},on:{"click":_vm.back}},[_c(VIcon,[_vm._v("mdi-arrow-left")]),_vm._v("Back ")],1)],1),_c(VCol,{staticClass:"text-right",attrs:{"sm":"6"}},[_c(VBtn,{attrs:{"disabled":!_vm.checklistPass,"color":!_vm.checklistPass ? 'grey' : 'primary',"depressed":""},on:{"click":_vm.next}},[_vm._v(" Continue "),_c(VIcon,[_vm._v(" mdi-arrow-right ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
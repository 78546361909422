import DashboardLayout from "../../layouts/dashboard/DashboardLayout";
import Dashboard from "./components/Dashboard";
import Settings from "./components/Settings";
import Auth from "../../router/middleware/Auth";
import Certificate from "./components/Certificate";

export default [
  {
    path: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "dashboard",
        components: { view: Dashboard },
        meta: { middleware: [Auth], breadcrumb: "Dashboard" },
      },
      {
        path: "Settings",
        name: "Settings",
        components: { view: Settings },
        meta: { middleware: [Auth], breadcrumb: "Settings" },
      },
      {
        path: "goodStanding/:code",
        name: "Document",
        components: { view: Certificate },
        meta: { middleware: [Auth], breadcrumb: "Dashboard" },
      },
    ],
  },
];

<template>
  <v-container>
    <v-overlay
      z-index="10"
      color="#000000"
      opacity=".6"
      v-if="$store.getters['Profile/loadingReferee']"
    >
      <v-progress-circular
        size="100"
        width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
export default {
  name: "ResponseCard",
  data() {
    return {
      //
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      const data = {
        user: v.decodeRoute(v.$route.query.user),
        code: v.decodeRoute(v.$route.query.code),
        action: v.decodeRoute(v.$route.query.action),
      };
      v.$store.dispatch("Profile/getRefereeForm", data);
    });
  },
  mounted() {
    Event.$on("routeBack", () => {
      this.$router.replace({ path: "/" });
    });
    Event.$on("routeChange", () => {
      this.$router.replace({ path: "/" });
    });
  },
  computed: {
    rules() {
      return {
        required: [(v) => !!v || "field is required"],
      };
    },
    data() {
      return this.$store.getters["Profile/profileGetters"]("profile");
    },
    referee() {
      return this.data ? this.data.referee : null;
    },
    applicant() {
      return this.data ? this.data.applicant : null;
    },
    applicationReferee() {
      return this.data ? this.data.Questions : null;
    },
  },
  methods: {
    decodeRoute: function (param) {
      return atob(param);
    },
    accept: function () {
      this.applicationReferee.response = "Accept";
      let data = { ...this.applicationReferee };
      this.$store.dispatch("Profile/acceptRefereeRequest", data);
    },
    reject: function () {
      this.applicationReferee.response = "Reject";
      let data = { ...this.applicationReferee };
      this.$store.dispatch("Profile/rejectRefereeRequest", data);
    },
  },
  watch: {
    data: function (val) {
      if (val) {
        if (val.referee) {
          if (this.decodeRoute(this.$route.query.action) === "accept") {
            this.accept();
          } else if (this.decodeRoute(this.$route.query.action) === "reject") {
            this.reject();
          }
        }
      }
    },
  },
};
</script>

<template>
  <v-card
    flat
    :class="`mb-10 pb-10 ${
      $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'mx-1' : ''
    }`"
  >
    <div class="d-flex justify-start flex-wrap">
      <v-card
        v-for="event in events"
        :key="event.no"
        :class="`${
          $vuetify.breakpoint.mdAndUp ? 'mx-2' : ''
        } my-2 card--margins`"
        :width="$vuetify.breakpoint.mdAndUp ? '265' : '100%'"
      >
        <div>
          <a :href="`${eventLink}${encodeRoute(event.no)}`" target="_blank">
            <v-img
              :src="url + event.base64Logo"
              :lazy-src="require(`@/assets/picture4.jpg`)"
              height="175"
              :width="$vuetify.breakpoint.mdAndUp ? '265' : '100%'"
              class="card--margins"
            />
          </a>

          <span class="cpd--chip">
            <!-- <v-chip
              label
              color="orange accent-4 font-weight-bold text-lg-h5"
              dark
            >
              <v-icon class="mx-2">stars</v-icon> {{ event.cpdHours }} CPD Hours
            </v-chip> -->
            <v-chip color="red darken-1" dark v-if="isWebinar(event)">
              webinar
            </v-chip>
            <div class="d-flex justify-end">
              <div style="width: 45%" class="mt-n2 mr-n5">
                <CPDbadge :cpd-points="event.cpdHours" />
              </div>
            </div>
          </span>
        </div>

        <div
          :href="`${eventLink}${encodeRoute(event.no)}`"
          class="text-capitalize mt-2 mx-2 text-body-2 text-truncate font-weight-bold"
        >
          <span class="pointer">{{ event.description }}</span>
        </div>

        <div class="text-wrap mx-2 text-truncate">
          <span>
            {{ days[new Date(event.startDate).getDay()] }},
            {{ months[new Date(event.startDate).getMonth()] }}
            {{ new Date(event.startDate).getDate() }},
            {{ new Date(event.startDate).getFullYear() }}
          </span>
          <v-icon small>mdi-map-marker</v-icon>
          {{ event.location }}
        </div>

        <v-btn
          block
          class="text-capitalize mt-1 book--btn text-body-2"
          :href="`${eventLink}delegate-card/${encodeRoute(
            event.no
          )}/?member=${encodeRoute(user)}`"
          target="_blank"
          color="primary"
        >
          Book Now
          <v-icon class="mx-2">mdi-bookmark-plus</v-icon>
        </v-btn>
      </v-card>
    </div>
  </v-card>
</template>
<script>
import eventMixin from "../eventMixin";
import CPDbadge from "./CPDbadge.vue";
import RouterMixin from "../../../router/RouterMixin.js";
import { AuthService } from "../../auth";
export default {
  name: "EventCards",
  data() {
    return {
      url: process.env.VUE_APP_TEMP_URL,
    };
  },
  components: {
    CPDbadge,
  },
  mixins: [eventMixin, RouterMixin],
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    eventLink: String,
  },
  computed: {
    cpd_caption() {
      return process.env.VUE_APP_CPD_CAPTION;
    },
    user() {
      return AuthService.user.profile.MemberNo;
    },
  },
  methods: {
    isWebinar(event) {
      return event.isWebinar;
    },
  },
};
</script>
<style>
.card--margins {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
}

.book--btn.v-btn {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
</style>

<template>
  <v-container class="mt-5">
    <alert />
    <v-data-table
      :headers="headers"
      :items="employees"
      dense
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-h6">employees</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog persistent v-model="dialog" max-width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Add employee Numbers
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="orange lighten-5">
                <span class="text-h5">
                  {{ edit ? "Edit" : "Add" }} employees
                </span>
              </v-card-title>

              <v-card-text class="mt-10">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                      <v-autocomplete
                        outlined
                        placeholder="Select Employee Type"
                        label="employee Type"
                        v-model="formData.code"
                        :items="employeeSetup"
                        item-text="description"
                        item-value="code"
                        dense
                        :rules="rules.employeeType"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                      <v-text-field
                        outlined
                        :placeholder="`Number of ${formData.code}`"
                        :label="`Number of ${formData.code}`"
                        v-model="formData.count"
                        type="number"
                        min="0"
                        dense
                        :rules="rules.count"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="clearForm"> Cancel </v-btn>
                <v-btn color="primary" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" color="blue" @click="update(item)">
          mdi-pencil
        </v-icon>
        <v-icon small color="red" @click="remove(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { AuthService } from "../../../auth";
import { helper } from "../../../../utils";
import Alert from "../../../../components/Alert";

export default {
  name: "employee",
  components: { Alert },
  data: () => ({
    dialog: false,
    edit: false,
    formData: {
      code: "",
      count: "",
    },
    headers: [
      {
        text: "employee Type",
        align: "start",
        sortable: false,
        value: "code",
      },
      { text: "Number Of Employees", value: "count" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  mounted() {
    this.$store.dispatch("Profile/getEmployees");
    this.$store.dispatch("Profile/getEmployeeSetup");
  },

  computed: {
    rules() {
      return {
        employeeType: [(v) => !!v || "employeeType is required"],
        count: [(v) => !!v || "count is required"],
      };
    },

    employees() {
      return this.$store.getters["Profile/employees"];
    },

    employeeSetup() {
      return this.$store.getters["Profile/employeeSetup"];
    },
  },

  methods: {
    clearForm: function () {
      this.formData = { code: "", count: "" };

      this.dialog = false;
      this.edit = false;
    },

    update: function (payload) {
      this.formData = { ...payload };
      this.edit = true;
      this.dialog = true;
    },

    remove(item) {
      this.$confirm.show({
        title: "Delete this employee?",
        text: `Are you sure you want to delete?`,
        onConfirm: () => {
          this.$store.dispatch("Profile/removeEmployee", { ...item });
        },
      });
    },

    save: function () {
      let isValid = true;
      for (const key in this.formData) {
        if (this.$refs[key])
          isValid = isValid ? this.$refs[key].validate(true) : false;
      }

      if (isValid) {
        let data = { ...this.formData };
        data.profileID = AuthService.user.profile.ProfileID;

        this.$store.dispatch(
          `Profile/${this.edit ? "updatEemployee" : "addEmployee"}`,
          helper.prepareFormData(data)
        );

        this.clearForm();
      }
    },
  },
};
</script>

<style scoped></style>

import DashboardLayout from "../../layouts/dashboard/DashboardLayout";
import CheckList from "./components/CheckList";
import ComponentCard from "./components/ComponentCard";
import CheckListView from "./views/CheckListView";
import Auth from "../../router/middleware/Auth";

export default [
  {
    path: "/checklist",
    component: DashboardLayout,
    children: [
      {
        path: "",
        components: { view: CheckListView },
        children: [
          {
            path: "type/:type",
            name: "listing",
            components: { view: CheckList },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
          {
            path: "component/:name",
            name: "component",
            components: { view: ComponentCard },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
        ],
      },
    ],
  },
];

import call from "../../../../service/http";
import directorConstants from "./directorConstants";

export default {
  state: {
    directors: [],
  },
  mutations: {
    SET_Directors: (state, payload) => {
      state.directors = payload;
    },
  },
  getters: {
    directors: (state) => state.directors,
  },
  actions: {
    getDirectors: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("get", directorConstants.directors)
        .then((res) => {
          commit("SET_Directors", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    addDirector: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", directorConstants.directors, data)
        .then(() => {
          dispatch("getDirectors");
          Event.$emit("application-checklist");
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: "Professional Registration Number not found",
          });
        });
    },

    removeDirector: ({ commit, dispatch }, payload) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", directorConstants.remove, payload)
        .then(() => {
          dispatch("getDirectors");
          Event.$emit("application-checklist");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    updateDirector: ({ dispatch, commit }, payload) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", directorConstants.update, payload)
        .then(() => {
          dispatch("getDirectors");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};

<template>
  <v-container>
    <v-sheet>
      <v-toolbar dense elevation="0">
        <v-toolbar-title class="subtitle-2 text-capitalize">
          {{ minorSettings.license_alternative_caption }}
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-divider />
      <router-view name="view" />
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: "License",
  computed: {
    settings() {
      return this.$store.getters["Admin/adminGetters"]("settings");
    },
    minorSettings() {
      return this.settings.minor_settings
        ? JSON.parse(this.settings.minor_settings)
        : [];
    },
  },
};
</script>

<style scoped></style>
